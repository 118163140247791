import { useTranslation } from "react-i18next";
import { NavBar } from "../../components";
import { ColorEnum } from "../../types";

export default function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <div>
      <NavBar />
      <div className="container mt-3 d-flex align-items-center justify-content-center">
        <h1 style={{ color: ColorEnum.Orange }}>{t("nothing_found_here")}</h1>
      </div>
    </div>
  );
}
