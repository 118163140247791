import { DefaultButton, TableComponent } from "../common";
import { PackageModel, PageRouteEnum } from "../../types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function DeliveryPackagesTableComponent({
  items,
  onItemClick,
  isHome,
}: {
  items: PackageModel[];
  onItemClick?: (model: PackageModel) => void;
  isHome?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3">
            {t("origin")}
          </th>
          <th scope="col" className="col-3">
            {t("destination")}
          </th>
          <th scope="col" className="col-1">
            {t("size")}
          </th>
          <th scope="col" className="col-1">
            {t("weight")}
          </th>
          <th scope="col" className="col-1">
            {t("budget")}
          </th>
          <th scope="col" className="col-3">
            {t("actions")}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.id}>
            <td>{item.origin}</td>
            <td>{item.destination}</td>
            <td>{item.size}</td>
            <td>{(item.weight || 0).toFixed(2)}kg</td>
            <td>{(item.targetPrice || 0).toFixed(2)}€</td>
            <td>
              <div className="d-flex gap-1">
                {!isHome && (
                  <>
                    {/* <DefaultButton
                      width={120}
                      onClick={() => {
                        navigate(
                          PageRouteEnum.PackageAssignDriver.replace(
                            ":id",
                            item.id || ""
                          )
                        );
                      }}
                      disabled={true}
                    >
                      Assign Driver
                    </DefaultButton> */}
                    <DefaultButton
                      width={120}
                      onClick={() => {
                        if (onItemClick) {
                          onItemClick(item);
                        }
                      }}
                    >
                      {t("offer")}
                    </DefaultButton>
                  </>
                )}
                <DefaultButton
                  width={80}
                  onClick={() => {
                    navigate(
                      PageRouteEnum.PackageDetails.replace(":id", item.id || "")
                    );
                  }}
                >
                  {t("details")}
                </DefaultButton>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
}
