export class CompanyStatisticsModel {
  id?: string;
  name?: string;
  employeesCount?: number;

  constructor({ id, name, employeesCount }: CompanyStatisticsModel) {
    this.id = id;
    this.name = name;
    this.employeesCount = employeesCount;
  }

  static fromJson(data: any): CompanyStatisticsModel {
    return new CompanyStatisticsModel({
      id: data["id"],
      name: data["name"],
      employeesCount: data["employeesCount"],
    });
  }
}
