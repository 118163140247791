export class DelivererStatisticsModel {
  averagePrice?: number;
  createdRoutesCount?: number;
  finishedRoutesCount?: number;
  packagesDeliveredCount?: number;
  routesInProgressCount?: number;
  employeesCount?: number;
  averageRating?: number;

  constructor({
    averagePrice,
    createdRoutesCount,
    finishedRoutesCount,
    packagesDeliveredCount,
    routesInProgressCount,
    employeesCount,
    averageRating,
  }: DelivererStatisticsModel) {
    this.averagePrice = averagePrice;
    this.createdRoutesCount = createdRoutesCount;
    this.finishedRoutesCount = finishedRoutesCount;
    this.packagesDeliveredCount = packagesDeliveredCount;
    this.routesInProgressCount = routesInProgressCount;
    this.employeesCount = employeesCount;
    this.averageRating = averageRating;
  }

  static fromJson(data: any): DelivererStatisticsModel {
    return new DelivererStatisticsModel({
      averagePrice: data["averagePrice"],
      createdRoutesCount: data["createdRoutesCount"],
      finishedRoutesCount: data["finishedRoutesCount"],
      packagesDeliveredCount: data["packagesDeliveredCount"],
      routesInProgressCount: data["routesInProgressCount"],
      employeesCount: data["employeesCount"],
      averageRating: data["averageRating"],
    });
  }
}
