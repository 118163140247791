import { useTranslation } from "react-i18next";
import { ColorEnum, SenderPackageOfferModel } from "../../types";
import { DateTimeUtil } from "../../utils";
import { DefaultButton, TableComponent } from "../common";

export default function SenderPackageOffersTableComponent({
  items,
  isSelectingDeliverer: isWaitingDeliverer,
  onAccept,
}: {
  items: SenderPackageOfferModel[];
  isSelectingDeliverer?: boolean;
  onAccept?: (offer: SenderPackageOfferModel) => void;
}) {
  const { t } = useTranslation();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3">
            {t("deliverer")}
          </th>
          <th scope="col" className="col-2">
            {t("delivery_date")}
          </th>
          <th scope="col" className="col-1">
            {t("price")}
          </th>
          <th scope="col" className="col-1">
            {t("rating")}
          </th>
          <th scope="col" className="col-1">
            {t("reviews")}
          </th>
          <th scope="col" className="col-3">
            {t("actions")}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.id}>
            <td>{item.delivererInfo?.name}</td>
            <td>{DateTimeUtil.dateFormat(item.plannedDeliveryDate)}</td>
            <td>{item.price?.toFixed(2)}€</td>
            <td>{item.delivererInfo?.rating}</td>
            <td>{item.delivererInfo?.numberOfReviews}</td>

            <td>
              {!isWaitingDeliverer && item.isSelected && (
                <div className="btn-group gap-1">
                  <DefaultButton
                    width={95}
                    disabled={true}
                    color={ColorEnum.Green}
                    onClick={() => {
                      if (onAccept) {
                        onAccept(item);
                      }
                    }}
                  >
                    {t("accepted")}
                  </DefaultButton>
                </div>
              )}
              {isWaitingDeliverer && (
                <div className="btn-group gap-1">
                  <DefaultButton
                    width={95}
                    color={ColorEnum.Green}
                    onClick={() => {
                      if (onAccept) {
                        onAccept(item);
                      }
                    }}
                  >
                    {t("accept")}
                  </DefaultButton>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
}
