import { useState } from "react";
import {
  DefaultNavBar,
  Spacing,
  SendResetPasswordComponent,
  VerifyResetPasswordComponent,
  ResetPasswordComponent,
} from "../../components";
import {
  PageRouteEnum,
  ResetPasswordEnum,
  ResetPasswordModel,
  SpacingEnum,
} from "../../types";
import { AuthService } from "../../services";
import { LanguageUtil, ToastUtil } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const authService = new AuthService();

export default function ResetPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [resetPasswordStatus, setResetPasswordStatus] = useState(
    ResetPasswordEnum.SendCode
  );
  const [resetPasswordModel, setResetPasswordModel] =
    useState<ResetPasswordModel>({});

  const handleSendCode = async (email: string) => {
    const result = await authService.sendResetPasswordCodeAsync(email);

    if (!result.isSuccess) {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
      return;
    }

    ToastUtil.showSuccessMessage(t("reset_password_code_sent"));

    setResetPasswordModel({ email });
    setResetPasswordStatus(ResetPasswordEnum.VerifyCode);
  };

  const handleVerifyCode = async (code: string) => {
    const result = await authService.verifyResetPasswordCodeAsync(
      resetPasswordModel.email!,
      code
    );

    if (!result.isSuccess) {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
      return;
    }

    setResetPasswordModel({ ...resetPasswordModel, code });
    setResetPasswordStatus(ResetPasswordEnum.ResetPassword);
  };

  const handleResetPassword = async (
    password: string,
    confirmPassword: string
  ) => {
    resetPasswordModel.password = password;
    resetPasswordModel.confirmPassword = confirmPassword;

    const result = await authService.resetPasswordAsync(resetPasswordModel);

    if (!result.isSuccess) {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
      return;
    }

    ToastUtil.showSuccessMessage(t("your_password_has_been_reseted"));
    navigate(PageRouteEnum.Login);
  };

  return (
    <div>
      <DefaultNavBar />
      <div>
        {resetPasswordStatus === ResetPasswordEnum.SendCode && (
          <SendResetPasswordComponent sendCodeAsync={handleSendCode} />
        )}
        {resetPasswordStatus === ResetPasswordEnum.VerifyCode && (
          <VerifyResetPasswordComponent verifyCodeAsync={handleVerifyCode} />
        )}
        {resetPasswordStatus === ResetPasswordEnum.ResetPassword && (
          <ResetPasswordComponent resetPasswordAsync={handleResetPassword} />
        )}
      </div>
      <Spacing size={SpacingEnum.Giant} />
    </div>
  );
}
