export class UserStatisticsModel {
  id?: string;
  name?: string;
  email?: string;
  rating?: number;

  constructor({ id, name, email, rating }: UserStatisticsModel) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.rating = rating;
  }

  static fromJson(data: any): UserStatisticsModel {
    return new UserStatisticsModel({
      id: data["id"],
      name: data["name"],
      email: data["email"],
      rating: data["rating"],
    });
  }
}
