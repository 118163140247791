export class PackageOfferAutoAcceptPolicyModel {
  id?: string;
  price?: number;
  finalDeliveryDate?: Date;
  minimalRating?: number;
  minimalNumberOfReviews?: number;

  constructor({
    id,
    price,
    finalDeliveryDate,
    minimalRating,
    minimalNumberOfReviews,
  }: PackageOfferAutoAcceptPolicyModel = {}) {
    this.id = id;
    this.price = price;
    this.finalDeliveryDate = finalDeliveryDate;
    this.minimalRating = minimalRating;
    this.minimalNumberOfReviews = minimalNumberOfReviews;
  }

  static fromJson(data: any): PackageOfferAutoAcceptPolicyModel {
    return new PackageOfferAutoAcceptPolicyModel({
      id: data["id"],
      price: data["price"],
      finalDeliveryDate:
        data["finalDeliveryDate"] && new Date(data["finalDeliveryDate"]),
      minimalRating: data["minimalRating"],
      minimalNumberOfReviews: data["minimalNumberOfReviews"],
    });
  }
}
