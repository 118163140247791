import { useRef } from "react";
import { ColorEnum } from "../../types";

export default function SidePanelComponent({
  show,
  showChange,
  children,
  width = "300px",
}: {
  show: boolean;
  showChange: (show: boolean) => void;
  children: React.ReactNode;
  width?: string;
}) {
  const panelRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: React.MouseEvent) => {
    if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
      showChange(false);
    }
  };

  return (
    <div>
      {show && (
        <div
          className="modal"
          tabIndex={-1}
          style={{ display: "block" }}
          onClick={handleClickOutside}
        >
          <div
            ref={panelRef}
            className="vh-100 "
            style={{
              backgroundColor: ColorEnum.DarkBlue,
              position: "absolute",
              top: "0%",
              right: "0%",
              width: width,
              padding: "20px",
              boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.5)",
            }}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
}
