import { useCallback, useState } from "react";
import { ColorEnum, PackageOfferFilter } from "../../types";
import { DateInput, DefaultButton, InputForm, TextInput } from "../common";
import { useTranslation } from "react-i18next";

export default function SenderOfferFilterComponent({
  filter,
  onChange,
}: {
  filter: PackageOfferFilter;
  onChange: (filter: PackageOfferFilter) => void;
}) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<PackageOfferFilter>(filter);
  const [formValidations, setFormValidations] = useState({
    minimumPrice: true,
    maximumPrice: true,
    minimumRating: true,
    minimumNumberOfReviews: true,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(
      new PackageOfferFilter({
        ...formData,
        [event.target.name]: event.target.value,
      })
    );
  };

  const handleValidationChange = useCallback(
    (name: string, isValid: boolean) => {
      setFormValidations((prevValidations) => ({
        ...prevValidations,
        [name]: isValid,
      }));
    },
    []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = Object.values(formValidations).every(
      (isValid) => isValid
    );

    if (!isFormValid) {
      return;
    }

    onChange(formData);
  };

  return (
    <InputForm onSubmit={handleSubmit}>
      <div className="d-flex flex-wrap gap-3">
        <TextInput
          name="minimumPrice"
          title={t("minimum_price")}
          type="number"
          step="01"
          width={170}
          value={formData.minimumPrice}
          onChange={handleInputChange}
          onValidationChange={handleValidationChange}
        />
        <TextInput
          name="maximumPrice"
          title={t("maximum_price")}
          type="number"
          step="01"
          width={170}
          value={formData.maximumPrice}
          onChange={handleInputChange}
          onValidationChange={handleValidationChange}
        />
        <TextInput
          name="minimumRating"
          title={t("minimum_rating")}
          type="number"
          step="01"
          width={170}
          value={formData.minimumRating}
          onChange={handleInputChange}
          onValidationChange={handleValidationChange}
        />
        <TextInput
          name="minimumNumberOfReviews"
          title={t("minimum_reviews")}
          type="number"
          width={170}
          value={formData.minimumNumberOfReviews}
          onChange={handleInputChange}
          onValidationChange={handleValidationChange}
        />
        <DateInput
          name="deliveryDate"
          title={t("delivery_date")}
          width={170}
          value={formData.deliveryDate}
          onChange={handleInputChange}
        />
      </div>
      <div
        className="d-flex btn-group gap-3 align-items-end"
        style={{ marginBottom: "18px" }}
      >
        <DefaultButton type="submit" width={80} color={ColorEnum.Blue}>
          {t("filter")}
        </DefaultButton>
        {(filter.minimumPrice ||
          filter.maximumPrice ||
          filter.minimumRating ||
          filter.minimumNumberOfReviews ||
          filter.deliveryDate) && (
          <DefaultButton
            type="button"
            width={120}
            color={ColorEnum.Grey}
            onClick={() => {
              if (onChange) {
                onChange({
                  ...formData,
                  minimumPrice: undefined,
                  maximumPrice: undefined,
                  minimumRating: undefined,
                  minimumNumberOfReviews: undefined,
                  deliveryDate: undefined,
                });
              }
            }}
          >
            {t("clear_filter")}
          </DefaultButton>
        )}
      </div>
    </InputForm>
  );
}
