export class CoordinatesModel {
  lat: number;
  lng: number;

  constructor({ lat, lng }: { lat: number; lng: number }) {
    this.lat = lat;
    this.lng = lng;
  }

  static fromJson(data: any): CoordinatesModel {
    return new CoordinatesModel({
      lat: data["latitude"],
      lng: data["longitude"],
    });
  }
}
