import { CoordinatesModel } from "./CoordinatesModel";
import { PackageOwnerModel } from "./PackageOwnerModel";

export class PackageMatchmakingModel {
  id?: string;
  owner?: PackageOwnerModel;
  pickUpDate?: Date;
  size?: string;
  weight?: number;
  type?: string;
  images?: string[];
  pickUpName?: string;
  pickUpEmail?: string;
  pickUpPhoneNumber?: string;
  pickUpComments?: string;
  dropOffName?: string;
  dropOffEmail?: string;
  dropOffPhoneNumber?: string;
  dropOffComments?: string;
  coordinatesOrigin?: CoordinatesModel;
  origin?: string;
  coordinatesDestination?: CoordinatesModel;
  destination?: string;
  isDirect?: boolean;
  targetPrice?: number;

  constructor({
    id,
    owner,
    pickUpDate,
    size,
    weight,
    type,
    images,
    pickUpName,
    pickUpEmail,
    pickUpPhoneNumber,
    pickUpComments,
    dropOffName,
    dropOffEmail,
    dropOffPhoneNumber,
    dropOffComments,
    coordinatesOrigin,
    origin,
    coordinatesDestination,
    destination,
    isDirect,
    targetPrice,
  }: PackageMatchmakingModel) {
    this.id = id;
    this.owner = owner;
    this.pickUpDate = pickUpDate;
    this.size = size;
    this.weight = weight;
    this.type = type;
    this.images = images;
    this.pickUpName = pickUpName;
    this.pickUpEmail = pickUpEmail;
    this.pickUpPhoneNumber = pickUpPhoneNumber;
    this.pickUpComments = pickUpComments;
    this.dropOffName = dropOffName;
    this.dropOffEmail = dropOffEmail;
    this.dropOffPhoneNumber = dropOffPhoneNumber;
    this.dropOffComments = dropOffComments;
    this.coordinatesOrigin = coordinatesOrigin;
    this.origin = origin;
    this.coordinatesDestination = coordinatesDestination;
    this.destination = destination;
    this.isDirect = isDirect;
    this.targetPrice = targetPrice;
  }

  static fromJson(data: any): PackageMatchmakingModel {
    return new PackageMatchmakingModel({
      id: data["id"],
      owner: PackageOwnerModel.fromJson(data["owner"]),
      pickUpDate: data["pickUpDate"] && new Date(data["pickUpDate"]),
      size: data["size"],
      weight: data["weight"],
      type: data["type"],
      images: data["images"],
      pickUpName: data["pickUpName"],
      pickUpEmail: data["pickUpEmail"],
      pickUpPhoneNumber: data["pickUpPhoneNumber"],
      pickUpComments: data["pickUpComments"],
      dropOffName: data["dropOffName"],
      dropOffEmail: data["dropOffEmail"],
      dropOffPhoneNumber: data["dropOffPhoneNumber"],
      dropOffComments: data["dropOffComments"],
      coordinatesOrigin: CoordinatesModel.fromJson(data["coordinatesOrigin"]),
      origin: data["origin"],
      coordinatesDestination: CoordinatesModel.fromJson(
        data["coordinatesDestination"]
      ),
      destination: data["destination"],
      isDirect: data["isDirect"],
      targetPrice: data["targetPrice"],
    });
  }
}
