import axios from "axios";
import {
  HttpHeaders,
  ResponseModel,
  UpdateUserModel,
  UserModel,
} from "../types";
import { DOTNET_API_URL } from "../config";

const URL_PREFIX = DOTNET_API_URL + "/user";

export class UserService {
  async findByIdAsync(id: string): Promise<ResponseModel<UserModel>> {
    try {
      const response = await axios.get(URL_PREFIX + `/${id}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = UserModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async updateAsync(
    id: string,
    model: UpdateUserModel
  ): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.put(URL_PREFIX + `/${id}`, model, {
        headers: HttpHeaders.get(),
      });

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }
}
