import { useCallback, useState } from "react";
import {
  AddressInput,
  AsyncDropdownInput,
  CheckBoxInput,
  DateTimeInput,
  DefaultButton,
  DropdownInput,
  InputForm,
  NavBar,
  Spacing,
  TextInput,
} from "../../components";
import {
  ColorEnum,
  Constants,
  DropdownItemModel,
  RouteModel,
  SpacingEnum,
  VehicleFilter,
  VehicleModel,
} from "../../types";
import { useNavigate } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import { RouteService, UserDataService, VehicleService } from "../../services";
import { LanguageUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const vehicleService = new VehicleService();
const routeService = new RouteService();

export default function RouteCreatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<RouteModel>(
    new RouteModel({ companyId: UserDataService.getCompanyId() || undefined })
  );
  const [formValidations, setFormValidations] = useState({
    vehicleId: false,
    origin: false,
    destination: false,
    driveStarting: false,
    driveEnding: false,
    price: false,
    detourRadius: false,
    packageSize: false,
    recurrenceFrequency: false,
    recurrentRouteName: false,
  });
  const [selectedVehicle, setSelectedVehicle] =
    useState<DropdownItemModel | null>(null);
  const [vehicleFilter, setVehicleFilter] = useState<VehicleFilter>({
    companyId: UserDataService.getCompanyId() || undefined,
    pageNumber: 1,
    pageSize: 15,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleValidationChange = useCallback(
    (name: string, isValid: boolean) => {
      setFormValidations((prevValidations) => ({
        ...prevValidations,
        [name]: isValid,
      }));
    },
    []
  );

  const checkIfFormValid = () => {
    const isFormValid = Object.entries(formValidations).every(
      ([key, isValid]) => {
        if (
          ["recurrenceFrequency", "recurrentRouteName"].includes(key) &&
          !formData.isRecurrent
        ) {
          return true;
        }
        return isValid;
      }
    );
    return isFormValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = checkIfFormValid();

    if (!isFormValid) {
      return;
    }
    formData.userId = selectedVehicle?.data.userId;

    const result = await routeService.createAsync(formData);

    if (result.isSuccess) {
      ToastUtil.showSuccessMessage(t("route_created"));
      navigate(-1);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
    }
  };

  const getVehiclesAsync = useCallback(
    async (inputValue: string): Promise<DropdownItemModel[]> => {
      const newFilter = new VehicleFilter(vehicleFilter);
      newFilter.query = inputValue;

      if (vehicleFilter.query !== inputValue) {
        newFilter.pageNumber = 1;
      } else {
        newFilter.pageNumber = (newFilter.pageNumber || 0) + 1;
      }

      setVehicleFilter(newFilter);
      const response = await vehicleService.getPagedAsync(newFilter);

      if (response.isSuccess && response.result && response.result.items) {
        const options = response.result.items.map(
          (item) =>
            new DropdownItemModel(item.id!, VehicleModel.dropdownValue(item), {
              userId: item.userId,
            })
        );
        return options;
      }
      return [];
    },
    [vehicleFilter]
  );

  return (
    <div>
      <NavBar />
      <div className="container mt-3" style={{ maxWidth: Constants.maxWidth }}>
        <h2>{t("new_route")}</h2>
        <InputForm onSubmit={handleSubmit}>
          <AsyncDropdownInput
            name="vehicleId"
            title={t("vehicle")}
            loadOptions={getVehiclesAsync}
            defaultOptions={selectedVehicle ? [selectedVehicle] : []}
            onChange={(result) => {
              setSelectedVehicle(result);
              setFormData({
                ...formData,
                vehicleId: result
                  ? ((result as SingleValue<DropdownItemModel>)!
                      .value as string)!
                  : undefined,
              });
            }}
            defaultErrorMessage={t("please_select_the_vehicle")}
            onValidationChange={handleValidationChange}
          />
          <AddressInput
            name="origin"
            placeholder={t("origin")}
            title={t("origin")}
            initialValue={formData.origin}
            onChange={(value: string) => {
              setFormData({ ...formData, origin: value });
            }}
            required={true}
            defaultErrorMessage={t("please_enter_the_origin")}
            onValidationChange={handleValidationChange}
          />
          <AddressInput
            name="destination"
            title={t("destination")}
            initialValue={formData.destination}
            onChange={(value: string) => {
              setFormData({ ...formData, destination: value });
            }}
            required={true}
            defaultErrorMessage={t("please_enter_the_destination")}
            onValidationChange={handleValidationChange}
          />
          <DateTimeInput
            name="driveStarting"
            title={t("drive_start")}
            value={formData.driveStarting}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_drive_start");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <DateTimeInput
            name="driveEnding"
            title={t("drive_end")}
            value={formData.driveEnding}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_drive_end");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="price"
            title={t("price")}
            type="number"
            step=".01"
            value={formData.price}
            unit="€"
            minValue={0}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_price");
              } else if (+value < 0) {
                return t("the_value_cant_be_negative");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="detourRadius"
            title={t("detour_radius")}
            type="number"
            value={formData.detourRadius}
            unit="km"
            minValue={0}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_detour_radius");
              } else if (+value < 0) {
                return t("the_value_cant_be_negative");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <DropdownInput
            name="packageSize"
            title={t("package_size")}
            options={Constants.packageSizes.map(
              (size) => new DropdownItemModel(size, size)
            )}
            multiple={true}
            onChange={(
              result:
                | MultiValue<DropdownItemModel>
                | SingleValue<DropdownItemModel>
            ) => {
              setFormData({
                ...formData,
                allowedPackages: result
                  ? (result as MultiValue<DropdownItemModel>).map(
                      ({ value }) => value as string
                    )
                  : [],
              });
            }}
            defaultErrorMessage={t("please_select_the_package_size")}
            onValidationChange={handleValidationChange}
          />
          <CheckBoxInput
            name="avoidTolls"
            title={t("avoid_tolls")}
            value={formData.avoidTolls}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              let value: boolean = event.target.checked;
              setFormData({
                ...formData,
                avoidTolls: value,
              });
            }}
          />
          <CheckBoxInput
            title={t("is_reccuring_route")}
            value={formData.isRecurrent}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              let value: boolean = event.target.checked;
              if (value) {
                setFormValidations({
                  ...formValidations,
                  recurrentRouteName: false,
                  recurrenceFrequency: false,
                });
              }
              setFormData({
                ...formData,
                isRecurrent: value,
                recurrenceFrequency: value
                  ? formData.recurrenceFrequency
                  : undefined,
                recurrentRouteName: value
                  ? formData.recurrentRouteName
                  : undefined,
              });
            }}
          />
          {formData.isRecurrent === true && (
            <>
              <DropdownInput
                name="recurrenceFrequency"
                title={t("recurrence_frequency")}
                options={Constants.reccurenceFrequencies.map(
                  (item) => new DropdownItemModel(item, t(item))
                )}
                onChange={(result) => {
                  setFormData({
                    ...formData,
                    recurrenceFrequency: result
                      ? ((result as SingleValue<DropdownItemModel>)!
                          .value as string)
                      : undefined,
                  });
                }}
                defaultErrorMessage={t(
                  "please_select_the_reccurence_frequency"
                )}
                onValidationChange={handleValidationChange}
              />
              <TextInput
                name="recurrentRouteName"
                title={t("route_name")}
                value={formData.recurrentRouteName}
                onChange={handleInputChange}
                onValidate={(value: string) => {
                  if (!value) {
                    return t("please_enter_the_route_name");
                  }
                  return undefined;
                }}
                onValidationChange={handleValidationChange}
              />
            </>
          )}
          <Spacing size={SpacingEnum.XXLarge} />
          <DefaultButton
            type="submit"
            disabled={!checkIfFormValid()}
            color={ColorEnum.Orange}
          >
            {t("create")}
          </DefaultButton>
        </InputForm>
        <Spacing size={SpacingEnum.Giant} />
      </div>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
