import { CoordinatesModel } from "./CoordinatesModel";

export class MarkerModel {
  id: string;
  position: CoordinatesModel;

  constructor({ id, position }: { id: string; position: CoordinatesModel }) {
    this.id = id;
    this.position = position;
  }
}
