import axios from "axios";
import { DOTNET_API_URL } from "../config";
import {
  HttpHeaders,
  PagedList,
  ResponseModel,
  RouteFilter,
  RouteModel,
} from "../types";

const URL_PREFIX = DOTNET_API_URL + `/route`;

export class RouteService {
  async getPagedAsync(
    filter: RouteFilter
  ): Promise<ResponseModel<PagedList<RouteModel>>> {
    const params = new URLSearchParams();

    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        params.append(key, value.toString());
      }
    });

    try {
      const response = await axios.get(URL_PREFIX + `?${params}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PagedList.fromJson<RouteModel>(response.data, RouteModel);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async findByIdAsync(id: string): Promise<ResponseModel<RouteModel>> {
    try {
      const response = await axios.get(URL_PREFIX + `/${id}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = RouteModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async createAsync(model: RouteModel): Promise<ResponseModel<RouteModel>> {
    try {
      const response = await axios.post(URL_PREFIX, model, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = RouteModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async updateAsync(model: RouteModel): Promise<ResponseModel<RouteModel>> {
    try {
      const response = await axios.put(URL_PREFIX + `/${model.id}`, model, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = RouteModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async deleteAsync(id: string): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.delete(URL_PREFIX + `/${id}`, {
        headers: HttpHeaders.get(),
      });

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch {
      return ResponseModel.failure();
    }
  }
}
