import { useCallback, useState } from "react";
import { ColorEnum, Constants, SpacingEnum } from "../../types";
import { DefaultButton, InputForm, Spacing, TextInput } from "../common";
import { ValidationUtil } from "../../utils";
import { useTranslation } from "react-i18next";

export default function EmployeesInviteComponent({
  generateInviteAsync,
  sendInviteAsync,
}: {
  generateInviteAsync: () => Promise<void>;
  sendInviteAsync: (emails: string[]) => Promise<void>;
}) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  const [formValidations, setFormValidations] = useState({ email: false });

  const handleGenerateInvite = async () => {
    await generateInviteAsync();
  };

  const handleSendInvite = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (emails.length === 0) {
      return;
    }

    await sendInviteAsync(emails);
  };

  const handleValidationChange = useCallback(
    (name: string, isValid: boolean) => {
      setFormValidations((prevValidations) => ({
        ...prevValidations,
        [name]: isValid,
      }));
    },
    []
  );

  const addEmail = () => {
    if (email && !emails.includes(email)) {
      setEmails([...emails, email]);
      setEmail("");
      setFormValidations({ ...formValidations, email: false });
    }
  };

  return (
    <div className="container mt-3">
      <h2>{t("invite_employees")}</h2>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ maxWidth: Constants.maxWidth }}
          >
            <div className="text-center">
              <img
                alt={t("invite")}
                src="/images/invite.png"
                className="mb-4 col-8"
              />
            </div>
            <Spacing size={SpacingEnum.Large} />
            <DefaultButton
              type="button"
              onClick={handleGenerateInvite}
              color={ColorEnum.Orange}
            >
              {t("generate_invite_code")}
            </DefaultButton>
            <Spacing size={SpacingEnum.Large} />
            <div className="text-center">
              <h6>{t("or")}</h6>
            </div>
            <Spacing size={SpacingEnum.Large} />
            <h5>{t("invite_employees_by_email")}</h5>
            <Spacing size={SpacingEnum.Large} />
            <InputForm onSubmit={handleSendInvite}>
              <div className="row">
                <div className="col-9">
                  <TextInput
                    name="email"
                    title={t("email")}
                    type="email"
                    value={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(event.target.value);
                    }}
                    onValidate={(value: string) => {
                      if (!value) {
                        return t("please_enter_an_email_address");
                      } else if (!ValidationUtil.validateEmail(value)) {
                        return t("please_enter_a_valid_email");
                      }
                      return "";
                    }}
                    onValidationChange={handleValidationChange}
                  />
                </div>
                <div
                  className="col-3 align-items-end"
                  style={{ marginTop: "52px" }}
                >
                  <DefaultButton
                    type="button"
                    onClick={addEmail}
                    disabled={!formValidations.email}
                    color={ColorEnum.Blue}
                  >
                    {t("add")}
                  </DefaultButton>
                </div>
              </div>
              <DefaultButton
                type="submit"
                disabled={emails.length === 0}
                color={ColorEnum.Orange}
              >
                {t("invite")}
              </DefaultButton>
            </InputForm>
            <ul className="list-group list-group-flush">
              {emails.map((email) => (
                <li
                  key={email}
                  className="list-group-item d-flex align-items-center"
                >
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      setEmails((emails) => emails.filter((e) => e !== email));
                    }}
                  >
                    X
                  </button>
                  <span>{email}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
