export enum PackageStatusEnum {
  Unknown = 0,
  New = 1,
  SelectingDeliverer = 2,
  WaitingPickup = 3,
  InDelivery = 4,
  Delivered = 5,
  Failed = 6,
  Cancelled = 7,
}

export namespace PackageStatusEnum {
  export function getStatusText(status?: PackageStatusEnum): string {
    switch (status) {
      case PackageStatusEnum.New:
        return "new";
      case PackageStatusEnum.SelectingDeliverer:
        return "selecting_deliverer";
      case PackageStatusEnum.WaitingPickup:
        return "waiting_pickup";
      case PackageStatusEnum.InDelivery:
        return "in_delivery";
      case PackageStatusEnum.Delivered:
        return "delivered";
      case PackageStatusEnum.Failed:
        return "failed";
      case PackageStatusEnum.Cancelled:
        return "cancelled";
      default:
        return "unknown";
    }
  }
}
