import { UserDataService } from "../../services";
import { RoleEnum } from "../../types";
import DeliveryPackagesPage from "./DeliveryPackagesPage";
import SenderPackagesPage from "./SenderPackagesPage";

export default function PackagesPage() {
  if (UserDataService.isInRole(RoleEnum.DeliveryBusinessAdmin)) {
    return <DeliveryPackagesPage />;
  }

  return <SenderPackagesPage />;
}
