import { TableComponent } from "../common";
import { CompanyModel } from "../../types";
import { useTranslation } from "react-i18next";

export default function CompanyDetailsTableComponent({
  model,
}: {
  model: CompanyModel;
}) {
  const { t } = useTranslation();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3"></th>
          <th scope="col" className="col-9"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("name")}</td>
          <td>{model.name}</td>
        </tr>
        <tr>
          <td>{t("email")}</td>
          <td>{model.email}</td>
        </tr>
        <tr>
          <td>{t("address")}</td>
          <td>{model.address}</td>
        </tr>
        <tr>
          <td>{t("city")}</td>
          <td>{model.city}</td>
        </tr>
        <tr>
          <td>{t("postal_code")}</td>
          <td>{model.postalCode}</td>
        </tr>
        <tr>
          <td>{t("pid")}</td>
          <td>{model.personalIdentificationNumber}</td>
        </tr>
        <tr>
          <td>{t("phone_number")}</td>
          <td>{model.phoneNumber}</td>
        </tr>
        <tr>
          <td>{t("employees_count")}</td>
          <td>{model.employeesCount}</td>
        </tr>
      </tbody>
    </TableComponent>
  );
}
