export class SenderHomePackageModel {
  id?: string;
  origin?: string;
  destination?: string;
  offersCount?: number;

  constructor({
    id,
    origin,
    destination,
    offersCount,
  }: SenderHomePackageModel = {}) {
    this.id = id;
    this.origin = origin;
    this.destination = destination;
    this.offersCount = offersCount;
  }

  static fromJson(data: any): SenderHomePackageModel {
    return new SenderHomePackageModel({
      id: data["id"],
      origin: data["origin"],
      destination: data["destination"],
      offersCount: data["offersCount"],
    });
  }
}
