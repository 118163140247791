import i18n, { TFunction } from "i18next";
import { DropdownItemModel, ErrorDescriberModel } from "../types";

const languages: Record<string, string> = { en: "English", hr: "Hrvatski" };

export class LanguageUtil {
  static changeLanguage(language: string) {
    i18n.changeLanguage(language);
  }

  static getSelectedLanguageDropdown(language: string) {
    const entry = languages[language];

    if (entry) {
      return new DropdownItemModel(language, entry);
    }

    return new DropdownItemModel("en", languages["en"]);
  }

  static getLanguagesDropdown(): DropdownItemModel[] {
    const result = Object.entries(languages).map(
      ([key, value]) => new DropdownItemModel(key, value)
    );

    return result;
  }

  static translateErrorMessage(
    t: TFunction,
    errorMessage?: ErrorDescriberModel
  ): string | undefined {
    let message = errorMessage?.errorMessage;

    if (errorMessage?.errorCode) {
      const translation = t(errorMessage.errorCode);
      if (translation !== errorMessage.errorCode) {
        message = translation;
      }
    }

    return message;
  }
}
