export class PackageSpecialRequirementModel {
  specialRequirementId?: number;
  key?: string;
  displayName?: string;

  constructor({
    specialRequirementId,
    key,
    displayName,
  }: PackageSpecialRequirementModel = {}) {
    this.specialRequirementId = specialRequirementId;
    this.key = key;
    this.displayName = displayName;
  }

  static fromJson(data: any): PackageSpecialRequirementModel {
    if (!data) {
      return new PackageSpecialRequirementModel();
    }

    return new PackageSpecialRequirementModel({
      specialRequirementId: data["specialRequirementId"],
      key: data["key"],
      displayName: data["displayName"],
    });
  }
}
