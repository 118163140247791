import { useNavigate, useParams } from "react-router-dom";
import {
  DefaultButton,
  Loader,
  ModalComponent,
  NavBar,
  RouteDetailsTableComponent,
  Spacing,
} from "../../components";
import {
  ColorEnum,
  PageRouteEnum,
  RoleEnum,
  RouteModel,
  SpacingEnum,
} from "../../types";
import { RouteService, UserDataService } from "../../services";
import { useEffect, useState } from "react";
import { LanguageUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const routeService = new RouteService();

export default function RouteDetailsPage() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [model, setModel] = useState(new RouteModel());
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const deleteRouteAsync = async () => {
    const response = await routeService.deleteAsync(params.id ?? "");

    if (response.isSuccess) {
      ToastUtil.showSuccessMessage(t("route_deleted"));
      navigate(-1);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, response.errorMessage)
      );
    }
  };

  useEffect(() => {
    const getRouteAsync = async () => {
      const response = await routeService.findByIdAsync(params.id ?? "");

      if (response.isSuccess) {
        setModel(response.result!);
      }

      setIsLoading(false);
    };

    getRouteAsync();
  }, [params.id]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <div className="d-flex justify-content-between align-items-center ">
            <h2>{t("route_details")}</h2>
            <div className="btn-group gap-1">
              {((UserDataService.isInRole(RoleEnum.DeliveryBusinessAdmin) &&
                UserDataService.getCompanyId() === model.companyId) ||
                UserDataService.isInRole(RoleEnum.SuperAdmin)) && (
                <>
                  <DefaultButton
                    width={80}
                    onClick={() => {
                      navigate(
                        PageRouteEnum.RouteEdit.replace(":id", params.id || "")
                      );
                    }}
                  >
                    {t("edit")}
                  </DefaultButton>
                  <DefaultButton
                    width={80}
                    color={ColorEnum.Red}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {t("delete")}
                  </DefaultButton>
                </>
              )}
            </div>
          </div>
          <RouteDetailsTableComponent model={model} />
        </div>
      </Loader>
      <ModalComponent
        title={t("confirm_delete")}
        show={showModal}
        onShowChange={setShowModal}
        onConfirm={deleteRouteAsync}
      >
        <p>{t("route_confirm_delete")}</p>
      </ModalComponent>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
