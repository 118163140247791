import { useEffect, useState } from "react";
import {
  DefaultButton,
  Loader,
  MapComponent,
  NavBar,
  NoItemsComponent,
  PackageOfferSidePanelComponent,
  Spacing,
  TableComponent,
} from "../../components";
import {
  ColorEnum,
  MarkerModel,
  PackageDelivererMatchmakingFilter,
  PackageDelivererMatchmakingModel,
  PagedList,
  SpacingEnum,
} from "../../types";
import { useTranslation } from "react-i18next";
import { MatchmakingService } from "../../services";

const matchmakingService = new MatchmakingService();

export default function DeliveryHomePage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [packageId, setPackageId] = useState<string>();
  const [packageMapFilter, setPackageMapFilter] =
    useState<PackageDelivererMatchmakingFilter>({
      pageNumber: 1,
      pageSize: 15,
    });
  const [showPanel, setShowPanel] = useState(false);
  const [pagedPackages, setPagedPackages] =
    useState<PagedList<PackageDelivererMatchmakingModel>>();

  useEffect(() => {
    setIsLoading(true);

    const getPackagesAsync = async () => {
      const response =
        await matchmakingService.getPackagesMatchedWithDelivererAsync(
          packageMapFilter
        );

      if (response.isSuccess) {
        setPagedPackages(response.result!);
      }

      setIsLoading(false);
    };

    getPackagesAsync();
  }, [packageMapFilter]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <div className="row">
            <div className="col-12 col-md-6">
              <MapComponent
                markers={
                  pagedPackages?.items?.map(
                    (item) =>
                      new MarkerModel({
                        id: item.id!,
                        position: item.coordinatesOrigin!,
                      })
                  ) || []
                }
                onClick={(marker: MarkerModel) => {
                  setPackageId(marker.id);
                  setShowPanel(true);
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <h5 style={{ color: ColorEnum.Orange }}>
                {t("latest_packages")}
              </h5>
              {/* <PackageMapFilterComponent
                filter={packageMapFilter}
                onChange={(filter) => setPackageMapFilter({ ...filter })}
              /> */}
              <TableComponent>
                <thead>
                  <tr>
                    <th scope="col" className="col-3">
                      {t("sender")}
                    </th>
                    <th scope="col" className="col-4">
                      {t("origin")}
                    </th>
                    <th scope="col" className="col-4">
                      {t("destination")}
                    </th>
                    <th scope="col" className="col-1">
                      {t("actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(pagedPackages?.items || []).map((item) => (
                    <tr key={item.id}>
                      <td>{item.sender}</td>
                      <td>{item.origin}</td>
                      <td>{item.destination}</td>
                      <td>
                        <div className="btn-group gap-1">
                          <DefaultButton
                            onClick={() => {
                              setPackageId(item.id);
                              setShowPanel(true);
                            }}
                          >
                            {t("offer")}
                          </DefaultButton>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableComponent>
              {(pagedPackages?.items || []).length === 0 && (
                <NoItemsComponent />
              )}
            </div>
          </div>
          <Spacing size={SpacingEnum.Large} />
        </div>
      </Loader>
      {showPanel && (
        <PackageOfferSidePanelComponent
          packageId={packageId!}
          showPanel={showPanel}
          onShowChange={setShowPanel}
        />
      )}
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
