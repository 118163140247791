import { PackageModel } from "../../types";
import { TableComponent } from "../common";
import { DateTimeUtil } from "../../utils";
import { useTranslation } from "react-i18next";

export default function PackageDetailsTableComponent({
  model,
  showFullInfo = true,
}: {
  model: PackageModel;
  showFullInfo?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3"></th>
          <th scope="col" className="col-9"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("origin")}</td>
          <td>{model.origin}</td>
        </tr>
        <tr>
          <td>{t("destination")}</td>
          <td>{model.destination}</td>
        </tr>
        <tr>
          <td>{t("pick_up_date")}</td>
          <td>{DateTimeUtil.dateFormat(model.pickUpDate)}</td>
        </tr>
        <tr>
          <td>{t("size")}</td>
          <td>{model.size}</td>
        </tr>
        <tr>
          <td>{t("weight")}</td>
          <td>{(model.weight || 0).toFixed(2)}kg</td>
        </tr>
        <tr>
          <td>{t("type")}</td>
          <td>{model.type}</td>
        </tr>
        <tr>
          <td>{t("additional_info")}</td>
          <td>{model.additionalInfo}</td>
        </tr>
        <tr>
          <td>{t("special_requirements")}</td>
          <td>
            {(model.specialRequirements || [])
              .map((req) => req.displayName)
              .join(", ")}
          </td>
        </tr>
        <tr>
          <td>{t("price")}</td>
          <td>{model.targetPrice?.toFixed(2)}€</td>
        </tr>
        {showFullInfo && (
          <>
            <tr>
              <td>{t("pick_up_name")}</td>
              <td>{model.pickUpName}</td>
            </tr>
            <tr>
              <td>{t("pick_up_email")}</td>
              <td>{model.pickUpEmail}</td>
            </tr>
            <tr>
              <td>{t("pick_up_phone_number")}</td>
              <td>{model.pickUpPhoneNumber}</td>
            </tr>
            <tr>
              <td>{t("pick_up_comments")}</td>
              <td>{model.pickUpComments}</td>
            </tr>
            <tr>
              <td>{t("drop_off_name")}</td>
              <td>{model.dropOffName}</td>
            </tr>
            <tr>
              <td>{t("drop_off_email")}</td>
              <td>{model.dropOffEmail}</td>
            </tr>
            <tr>
              <td>{t("drop_off_phone_number")}</td>
              <td>{model.dropOffPhoneNumber}</td>
            </tr>
            <tr>
              <td>{t("drop_off_comments")}</td>
              <td>{model.dropOffComments}</td>
            </tr>
          </>
        )}
      </tbody>
    </TableComponent>
  );
}
