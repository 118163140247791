import { PackageStatusEnum } from "../enums";
import { PackageOfferAutoAcceptPolicyModel } from "./PackageOfferAutoAcceptPolicyModel";
import { PackageOfferModel } from "./PackageOfferModel";
import { PackageSpecialRequirementModel } from "./PackageSpecialRequirementModel";

export class PackageModel {
  id?: string;
  companyId?: string;
  userId?: string;
  origin?: string;
  countryOrigin?: string;
  townOrigin?: string;
  postalCodeOrigin?: string;
  addressOrigin?: string;
  destination?: string;
  countryDestination?: string;
  townDestination?: string;
  postalCodeDestination?: string;
  addressDestination?: string;
  pickUpDate?: Date;
  size?: string;
  weight?: number;
  type?: string;
  images?: string[];
  additionalInfo?: string;
  pickUpName?: string;
  pickUpEmail?: string;
  pickUpPhoneNumber?: string;
  pickUpComments?: string;
  dropOffName?: string;
  dropOffEmail?: string;
  dropOffPhoneNumber?: string;
  dropOffComments?: string;
  targetPrice?: number;
  specialRequirements?: PackageSpecialRequirementModel[];
  packageOffers?: PackageOfferModel[];
  status?: PackageStatusEnum;
  isAutoAcceptPolicyEnabled?: boolean;
  packageOfferAutoAcceptPolicy?: PackageOfferAutoAcceptPolicyModel;

  constructor({
    id,
    companyId,
    userId,
    origin,
    addressOrigin,
    countryOrigin,
    postalCodeOrigin,
    townOrigin,
    destination,
    addressDestination,
    countryDestination,
    postalCodeDestination,
    townDestination,
    pickUpDate,
    size,
    weight,
    type,
    images,
    additionalInfo,
    pickUpName,
    pickUpEmail,
    pickUpPhoneNumber,
    pickUpComments,
    dropOffName,
    dropOffEmail,
    dropOffPhoneNumber,
    dropOffComments,
    targetPrice,
    specialRequirements,
    status,
    packageOffers,
    isAutoAcceptPolicyEnabled,
    packageOfferAutoAcceptPolicy,
  }: PackageModel = {}) {
    this.id = id;
    this.companyId = companyId;
    this.userId = userId;
    this.origin = origin;
    this.addressOrigin = addressOrigin;
    this.countryOrigin = countryOrigin;
    this.postalCodeOrigin = postalCodeOrigin;
    this.townOrigin = townOrigin;
    this.destination = destination;
    this.addressDestination = addressDestination;
    this.countryDestination = countryDestination;
    this.postalCodeDestination = postalCodeDestination;
    this.townDestination = townDestination;
    this.pickUpDate = pickUpDate;
    this.size = size;
    this.weight = weight;
    this.type = type;
    this.images = images;
    this.additionalInfo = additionalInfo;
    this.pickUpName = pickUpName;
    this.pickUpEmail = pickUpEmail;
    this.pickUpPhoneNumber = pickUpPhoneNumber;
    this.pickUpComments = pickUpComments;
    this.dropOffName = dropOffName;
    this.dropOffEmail = dropOffEmail;
    this.dropOffPhoneNumber = dropOffPhoneNumber;
    this.dropOffComments = dropOffComments;
    this.targetPrice = targetPrice;
    this.specialRequirements = specialRequirements;
    this.status = status;
    this.packageOffers = packageOffers;
    this.isAutoAcceptPolicyEnabled = isAutoAcceptPolicyEnabled;
    this.packageOfferAutoAcceptPolicy = packageOfferAutoAcceptPolicy;
  }

  static fromJson(data: any): PackageModel {
    return new PackageModel({
      id: data["id"],
      companyId: data["companyId"],
      userId: data["userId"],
      origin: data["origin"],
      addressOrigin: data["addressOrigin"],
      countryOrigin: data["countryOrigin"],
      postalCodeOrigin: data["postalCodeOrigin"],
      townOrigin: data["townOrigin"],
      destination: data["destination"],
      addressDestination: data["addressDestination"],
      countryDestination: data["countryDestination"],
      postalCodeDestination: data["postalCodeDestination"],
      townDestination: data["townDestination"],
      pickUpDate: data["pickUpDate"] && new Date(data["pickUpDate"]),
      size: data["size"],
      weight: data["weight"],
      type: data["type"],
      images: data["images"],
      additionalInfo: data["additionalInfo"],
      pickUpName: data["pickUpName"],
      pickUpEmail: data["pickUpEmail"],
      pickUpPhoneNumber: data["pickUpPhoneNumber"],
      pickUpComments: data["pickUpComments"],
      dropOffName: data["dropOffName"],
      dropOffEmail: data["dropOffEmail"],
      dropOffPhoneNumber: data["dropOffPhoneNumber"],
      dropOffComments: data["dropOffComments"],
      targetPrice: data["targetPrice"],
      specialRequirements:
        data["packageSpecialRequirements"] &&
        data["packageSpecialRequirements"].map((req: any) =>
          PackageSpecialRequirementModel.fromJson(req)
        ),
      status: data["status"] && (data["status"] as PackageStatusEnum),
      packageOffers:
        data["packageOffers"] &&
        data["packageOffers"].map((offer: any) =>
          PackageOfferModel.fromJson(offer)
        ),
      isAutoAcceptPolicyEnabled: data["packageOfferAutoAcceptPolicy"] && true,
      packageOfferAutoAcceptPolicy:
        data["packageOfferAutoAcceptPolicy"] &&
        PackageOfferAutoAcceptPolicyModel.fromJson(
          data["packageOfferAutoAcceptPolicy"]
        ),
    });
  }
}
