import { useEffect, useState } from "react";
import {
  BaseFilterComponent,
  DefaultButton,
  Loader,
  NavBar,
  NoItemsComponent,
  PaginationComponent,
  Spacing,
  VehiclesTableComponent,
} from "../../components";
import {
  ColorEnum,
  PageRouteEnum,
  PagedList,
  SpacingEnum,
  VehicleFilter,
  VehicleModel,
} from "../../types";
import { useNavigate } from "react-router-dom";
import { UserDataService, VehicleService } from "../../services";
import { useTranslation } from "react-i18next";

const vehicleService = new VehicleService();

export default function VehiclesPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [pagedVehicles, setPagedVehicles] = useState<PagedList<VehicleModel>>();
  const [vehicleFilter, setVehicleFilter] = useState<VehicleFilter>({
    companyId: UserDataService.getCompanyId() || undefined,
    pageNumber: 1,
    pageSize: 10,
  });
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const getVehiclesAsync = async () => {
      const response = await vehicleService.getPagedAsync(vehicleFilter);

      if (response.isSuccess) {
        setPagedVehicles(response.result!);
      }

      setIsLoading(false);
    };

    getVehiclesAsync();
  }, [vehicleFilter]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <div className="d-flex justify-content-between ">
            <h2>{t("vehicles")}</h2>
            <div className="col d-flex justify-content-end ">
              <DefaultButton
                width={200}
                color={ColorEnum.Orange}
                onClick={() => navigate(PageRouteEnum.VehicleCreate)}
              >
                {t("new_vehicle")}
              </DefaultButton>
            </div>
          </div>
          <BaseFilterComponent
            filter={vehicleFilter}
            onChange={(filter) => {
              console.log(filter);
              setVehicleFilter({ ...vehicleFilter, query: filter.query });
            }}
          />
          <VehiclesTableComponent items={pagedVehicles?.items || []} />
          {(pagedVehicles?.items || []).length === 0 && <NoItemsComponent />}
          <PaginationComponent
            onPageChange={(page) => {
              setVehicleFilter({ ...vehicleFilter, pageNumber: page });
            }}
            currentPage={pagedVehicles?.pageNumber || 1}
            pageCount={pagedVehicles?.lastPage || 1}
          />
        </div>
      </Loader>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
