import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useCallback, useState } from "react";
import { GOOGLE_MAPS_KEY } from "../../config";
import { MarkerModel } from "../../types";
import { BasicLoader } from "../common";
import { StylesUtil } from "../../utils";

const mapStyle = {
  width: "100%",
  height: "100%",
};

const mapContainerStyle = {
  height: "80vh",
  width: "100%",
};

const center = {
  lat: 44.47378,
  lng: 16.46887,
};

export default function MapComponent({
  markers,
  onClick,
}: {
  markers: MarkerModel[];
  onClick: (marker: MarkerModel) => void;
}) {
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onLoad = useCallback((map: google.maps.Map) => {
    // const bounds = new google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);

  const onUnmount = useCallback((map: google.maps.Map) => setMap(null), []);

  // const [markers, setMarkers] = useState<MarkerModel[]>([
  //   { id: "1", position: { lat: 45.815, lng: 15.9819 } },
  //   { id: "2", position: { lat: 43.5081, lng: 16.4402 } },
  //   { id: "3", position: { lat: 45.3271, lng: 14.4411 } },
  //   { id: "4", position: { lat: 42.6403, lng: 18.1105 } },
  //   { id: "5", position: { lat: 44.1194, lng: 15.2314 } },
  //   { id: "6", position: { lat: 45.825, lng: 15.9819 } },
  //   { id: "7", position: { lat: 45.825, lng: 15.9925 } },
  //   { id: "8", position: { lat: 45.815, lng: 15.97 } },
  //   { id: "9", position: { lat: 45.835, lng: 15.979 } },
  //   { id: "10", position: { lat: 45.825, lng: 15.2314 } },
  // ]);

  const iconSize = 120;

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_KEY,
    language: "en",
  });

  if (!isLoaded) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={mapContainerStyle}
      >
        <BasicLoader />
      </div>
    );
  }

  console.log(loadError);

  const clusterStyles = [
    {
      textColor: "white",
      url: "/images/markers/blue_marker.png",
      height: iconSize,
      width: iconSize,
      textSize: 14,
    },
  ];

  const markerIcon: google.maps.Icon = {
    url: "/images/markers/red_marker.png",
    scaledSize: new google.maps.Size(iconSize, iconSize),
    anchor: new google.maps.Point(iconSize / 2, iconSize / 2),
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div style={mapContainerStyle}>
          <GoogleMap
            mapContainerStyle={mapStyle}
            center={center}
            zoom={7}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              styles: StylesUtil.mapStyle(),
            }}
          >
            <MarkerClusterer styles={clusterStyles}>
              {(clusterer) => (
                <div>
                  {markers.map((marker) => (
                    <Marker
                      key={marker.id}
                      position={marker.position}
                      clusterer={clusterer}
                      icon={markerIcon}
                      clickable={true}
                      onClick={(e: google.maps.MapMouseEvent) => {
                        e.stop();
                        onClick(marker);
                      }}
                    />
                  ))}
                </div>
              )}
            </MarkerClusterer>
          </GoogleMap>
        </div>
      </div>
    </div>
  );
}
