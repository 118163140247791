import { UserDataService } from "../../services";
import { RoleEnum } from "../../types";
import { AdminHomePage } from "../admin";
import { DeliveryHomePage } from "../delivery";
import { SenderHomePage } from "../sender";

export default function HomePage() {
  if (UserDataService.isInRole(RoleEnum.SuperAdmin)) {
    return <AdminHomePage />;
  }

  if (UserDataService.isInRole(RoleEnum.DeliveryBusinessAdmin)) {
    return <DeliveryHomePage />;
  }

  if (
    UserDataService.isInRole(RoleEnum.BusinessSender) ||
    UserDataService.isInRole(RoleEnum.SenderBusinessAdmin)
  ) {
    return <SenderHomePage />;
  }

  return <></>;
}
