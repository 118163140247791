import { useTranslation } from "react-i18next";
import { RouteModel } from "../../types";
import { DateTimeUtil } from "../../utils";
import { TableComponent } from "../common";

export default function RouteDetailsTableComponent({
  model,
}: {
  model: RouteModel;
}) {
  const { t } = useTranslation();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3"></th>
          <th scope="col" className="col-9"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("vehicle")}</td>
          <td>{model.vehicle?.toString()}</td>
        </tr>
        <tr>
          <td>{t("driver")}</td>
          <td>{model.driver?.name}</td>
        </tr>
        <tr>
          <td>{t("origin")}</td>
          <td>{model.origin}</td>
        </tr>
        <tr>
          <td>{t("destination")}</td>
          <td>{model.destination}</td>
        </tr>
        <tr>
          <td>{t("allowed_packages")}</td>
          <td>{model.allowedPackages?.join(", ") ?? "Not specified"}</td>
        </tr>
        <tr>
          <td>{t("drive_starting")}</td>
          <td>{DateTimeUtil.dateTimeFormat(model.driveStarting)}</td>
        </tr>
        <tr>
          <td>{t("drive_ending")}</td>
          <td>{DateTimeUtil.dateTimeFormat(model.driveEnding)}</td>
        </tr>
        <tr>
          <td>{t("detour_radius")}</td>
          <td>{(model.detourRadius ?? 0).toFixed(2)}km</td>
        </tr>
        <tr>
          <td>{t("price")}</td>
          <td>{model.price}€</td>
        </tr>
        <tr>
          <td>{t("avoid_tolls")}</td>
          <td>
            {(model.avoidTolls === false && t("false")) ||
              (model.avoidTolls === true && t("true"))}
          </td>
        </tr>
        <tr>
          <td>{t("vias")}</td>
          <td>{model.vias}</td>
        </tr>
        {model.isRecurrent && (
          <tr>
            <td>{t("recurrence_frequency")}</td>
            <td>{model.recurrenceFrequency}</td>
          </tr>
        )}
        {model.isRecurrent && (
          <tr>
            <td>{t("recurrent_route_name")}</td>
            <td>{model.recurrentRouteName}</td>
          </tr>
        )}
      </tbody>
    </TableComponent>
  );
}
