import { useEffect, useState } from "react";
import { BasicLoader, NavBar } from "../../components";
import { AuthService } from "../../services";
import { useParams } from "react-router-dom";
import { LanguageUtil, ToastUtil } from "../../utils";
import { ColorEnum } from "../../types";
import { useTranslation } from "react-i18next";

const authService = new AuthService();

export default function ConfirmEmailPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [text, setText] = useState(t("confirming_email") + "...");
  const param = useParams();

  useEffect(() => {
    const confirmEmailAsync = async () => {
      const result = await authService.confirmEmailAsync(param.token ?? "");

      if (result.isSuccess) {
        setText(t("email_confirmed"));
        ToastUtil.showSuccessMessage(t("email_confirmed"));
      } else {
        setText(t("email_confirmation_failed"));
        ToastUtil.showErrorMessage(
          LanguageUtil.translateErrorMessage(t, result.errorMessage)
        );
      }

      setIsLoading(false);
    };

    confirmEmailAsync();
  }, [param.token, t]);

  return (
    <div>
      <NavBar />
      <div className="container mt-5 d-flex flex-column align-items-center">
        {isLoading ? (
          <div className="d-flex flex-column align-items-center">
            <BasicLoader />
            <h5 style={{ color: ColorEnum.Orange }} className="mt-3">
              {text}
            </h5>
          </div>
        ) : (
          <h5 style={{ color: ColorEnum.Orange }}>{text}</h5>
        )}
      </div>
    </div>
  );
}
