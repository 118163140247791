export enum CompanyEmployeeStatusEnum {
  Waiting = 1,
  Accepted = 2,
  Declined = 3,
}

export namespace CompanyEmployeeStatusEnum {
  export function getStatusText(status?: CompanyEmployeeStatusEnum): string {
    switch (status) {
      case CompanyEmployeeStatusEnum.Accepted:
        return "accepted";
      case CompanyEmployeeStatusEnum.Declined:
        return "declined";
      case CompanyEmployeeStatusEnum.Waiting:
        return "waiting";
      default:
        return "unknown";
    }
  }
}
