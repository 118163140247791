import { useTranslation } from "react-i18next";
import { RouteMatchmakingModel } from "../../types";
import { DefaultButton, TableComponent } from "../common";
import { DateTimeUtil, LanguageUtil, ToastUtil } from "../../utils";
import { MatchmakingService } from "../../services";
import { useParams } from "react-router-dom";

const matchmakingService = new MatchmakingService();

export default function RouteRequestsTableComponent({
  items,
  onRequestSent,
}: {
  items: RouteMatchmakingModel[];
  onRequestSent: (model: RouteMatchmakingModel) => void;
}) {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <TableComponent>
      <thead>
        <th scope="col" className="col-2">
          {t("origin")}
        </th>
        <th scope="col" className="col-2">
          {t("destination")}
        </th>
        <th scope="col" className="col-1">
          {t("drive_starting")}
        </th>
        <th scope="col" className="col-1">
          {t("drive_ending")}
        </th>
        <th scope="col" className="col-1">
          {t("price")}
        </th>
        <th scope="col" className="col-1">
          {t("deliverer")}
        </th>
        <th scope="col" className="col-2">
          {t("actions")}
        </th>
      </thead>
      <tbody>
        {items.map((model) => (
          <tr key={model.id}>
            <td>{model.origin}</td>
            <td>{model.destination}</td>
            <td>{DateTimeUtil.dateTimeFormat(model.driveStarting)}</td>
            <td>{DateTimeUtil.dateTimeFormat(model.driveEnding)}</td>
            <td>{model.price?.toFixed(2)}€</td>
            <td>{model.user?.companyName || model.user?.name}</td>
            <td>
              <div className="btn-group gap-1">
                <DefaultButton
                  disabled={model.isPackageRequestSent}
                  width={120}
                  onClick={async () => {
                    const result = await matchmakingService.addAsync({
                      packageId: params.packageId!,
                      routeId: model.id!,
                    });

                    if (result.isSuccess) {
                      ToastUtil.showSuccessMessage(t("request_sent"));
                      onRequestSent(model);
                    } else {
                      ToastUtil.showErrorMessage(
                        LanguageUtil.translateErrorMessage(
                          t,
                          result.errorMessage
                        )
                      );
                    }
                  }}
                >
                  {t("send_request")}
                </DefaultButton>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
}
