import { CompanyEmployeeStatusEnum } from "../enums";

export class CompanyEmployeeModel {
  userId?: string;
  companyId?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  status?: CompanyEmployeeStatusEnum;
  companyName?: string;

  constructor({
    userId,
    companyId,
    name,
    email,
    phoneNumber,
    status,
    companyName,
  }: CompanyEmployeeModel = {}) {
    this.userId = userId;
    this.companyId = companyId;
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.status = status;
    this.companyName = companyName;
  }

  static fromJson(data: any): CompanyEmployeeModel {
    return new CompanyEmployeeModel({
      userId: data["userId"],
      companyId: data["companyId"],
      name: data["name"],
      email: data["email"],
      phoneNumber: data["phoneNumber"],
      status: data["status"],
      companyName: data["companyName"],
    });
  }
}
