import { TableComponent } from "../common";
import { CompanyEmployeeModel } from "../../types";
import { useTranslation } from "react-i18next";

export default function EmployeeProfileTableComponent({
  model,
}: {
  model: CompanyEmployeeModel;
}) {
  const { t } = useTranslation();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3"></th>
          <th scope="col" className="col-9"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("name")}</td>
          <td>{model.name}</td>
        </tr>
        <tr>
          <td>{t("email")}</td>
          <td>{model.email}</td>
        </tr>
        <tr>
          <td>{t("phone_number")}</td>
          <td>{model.phoneNumber}</td>
        </tr>
      </tbody>
    </TableComponent>
  );
}
