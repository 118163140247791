export class RouteStatisticsModel {
  id?: string;
  origin?: string;
  destination?: string;
  driveStarting?: Date;
  driveEnding?: Date;

  constructor({
    id,
    origin,
    destination,
    driveStarting,
    driveEnding,
  }: RouteStatisticsModel) {
    this.id = id;
    this.origin = origin;
    this.destination = destination;
    this.driveStarting = driveStarting;
    this.driveEnding = driveEnding;
  }

  static fromJson(data: any): RouteStatisticsModel {
    return new RouteStatisticsModel({
      id: data["id"],
      origin: data["origin"],
      destination: data["destination"],
      driveEnding: data["driveEnding"] && new Date(data["driveEnding"]),
      driveStarting: data["driveStarting"] && new Date(data["driveStarting"]),
    });
  }
}
