export class CompanyModel {
  id?: string;
  type?: number;
  email?: string;
  name?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  inviteCode?: string;
  personalIdentificationNumber?: string;
  phoneNumber?: string;
  adminName?: string;
  adminEmail?: string;
  employeesCount?: number;

  constructor({
    id,
    type,
    email,
    name,
    address,
    city,
    postalCode,
    inviteCode,
    personalIdentificationNumber,
    phoneNumber,
    adminName,
    adminEmail,
    employeesCount,
  }: CompanyModel = {}) {
    this.id = id;
    this.type = type;
    this.email = email;
    this.name = name;
    this.address = address;
    this.city = city;
    this.postalCode = postalCode;
    this.inviteCode = inviteCode;
    this.personalIdentificationNumber = personalIdentificationNumber;
    this.phoneNumber = phoneNumber;
    this.adminName = adminName;
    this.adminEmail = adminEmail;
    this.employeesCount = employeesCount;
  }

  static fromJson(data: any): CompanyModel {
    return new CompanyModel({
      id: data["id"],
      type: data["type"],
      email: data["email"],
      name: data["name"],
      address: data["address"],
      city: data["city"],
      postalCode: data["postalCode"],
      inviteCode: data["inviteCode"],
      personalIdentificationNumber: data["personalIdentificationNumber"],
      phoneNumber: data["phoneNumber"],
      employeesCount: data["employeesCount"],
    });
  }

  static getFullAddress(company: CompanyModel): string {
    return company.address + ", " + company.city + ", " + company.postalCode;
  }
}
