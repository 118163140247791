import { NavBar } from "../../components";

export default function UserStatisticsPage() {
  return (
    <div>
      <NavBar />
      <div className="container mt-3">UserStatisticsPage</div>
    </div>
  );
}
