import { useLocation, useNavigate } from "react-router-dom";
import {
  DefaultButton,
  InputForm,
  NavBar,
  Spacing,
  TextInput,
} from "../../components";
import { useCallback, useState } from "react";
import {
  ColorEnum,
  Constants,
  PageRouteEnum,
  SpacingEnum,
  UserRegisterModel,
} from "../../types";
import { AuthService } from "../../services";
import { LanguageUtil, ToastUtil, ValidationUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const authService = new AuthService();

export default function RegisterPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<UserRegisterModel>(
    new UserRegisterModel({
      inviteCode: queryParams.get("code") || undefined,
      language: "en",
    })
  );
  const [formValidations, setFormValidations] = useState({
    name: false,
    email: false,
    inviteCode: queryParams.get("code"),
    password: false,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(
      new UserRegisterModel({
        ...formData,
        [event.target.name]: event.target.value,
      })
    );
  };

  const handleValidationChange = useCallback(
    (name: string, isValid: boolean) => {
      setFormValidations((prevValidations) => ({
        ...prevValidations,
        [name]: isValid,
      }));
    },
    []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = Object.values(formValidations).every(
      (isValid) => isValid
    );

    if (!isFormValid) {
      return;
    }

    const result = await authService.registerAsync(formData);

    if (result.isSuccess) {
      ToastUtil.showSuccessMessage(t("account_created"));
      navigate(PageRouteEnum.Login);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
    }
  };

  return (
    <div>
      <NavBar />
      <div className="container mt-3" style={{ maxWidth: Constants.maxWidth }}>
        <h2>{t("register")}</h2>
        <InputForm onSubmit={handleSubmit}>
          <TextInput
            name="inviteCode"
            title={t("invite_code")}
            onChange={handleInputChange}
            value={formData.inviteCode}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_your_invite_code");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="name"
            title={t("name")}
            value={formData.name}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_your_full_name");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="email"
            title={t("email")}
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_your_email");
              } else if (!ValidationUtil.validateEmail(value)) {
                return t("please_enter_a_valid_email");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="password"
            title={t("password")}
            type="password"
            value={formData.password}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_your_password");
              } else if (value.length < 8) {
                return t("password_min_length");
              } else if (!ValidationUtil.validatePassword(value)) {
                return t("please_enter_valid_password");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <Spacing size={SpacingEnum.XXLarge} />
          <DefaultButton
            type="submit"
            color={ColorEnum.Orange}
            disabled={
              !Object.values(formValidations).every((isValid) => isValid)
            }
          >
            {t("register")}
          </DefaultButton>
        </InputForm>
      </div>
    </div>
  );
}
