import { PackageMatchmakingModel } from "./PackageMatchmakingModel";

export class MatchmakingModel {
  matchmakingId?: string;
  routeId?: string;
  driveStarting?: Date;
  packageInfo?: PackageMatchmakingModel;

  constructor({
    matchmakingId,
    routeId,
    driveStarting,
    packageInfo,
  }: MatchmakingModel) {
    this.matchmakingId = matchmakingId;
    this.routeId = routeId;
    this.driveStarting = driveStarting;
    this.packageInfo = packageInfo;
  }

  static fromJson(data: any): MatchmakingModel {
    return new MatchmakingModel({
      matchmakingId: data["matchmakingId"],
      routeId: data["routeId"],
      driveStarting: data["driveStarting"] && new Date(data["driveStarting"]),
      packageInfo: PackageMatchmakingModel.fromJson(data["package"]),
    });
  }
}
