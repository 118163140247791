import { NavBar } from "../../components";

export default function PackageStatisticsPage() {
  return (
    <div>
      <NavBar />
      <div className="container mt-3">PackageStatisticsPage</div>
    </div>
  );
}
