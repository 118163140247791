import { TableComponent } from "../common";
import { RouteStatisticsModel } from "../../types";
import { DateTimeUtil } from "../../utils";
import { useTranslation } from "react-i18next";

export default function RouteStatisticsTableComponent({
  items,
}: {
  items: RouteStatisticsModel[];
}) {
  const { t } = useTranslation();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3">
            {t("origin")}
          </th>
          <th scope="col" className="col-3">
            {t("destination")}
          </th>
          <th scope="col" className="col-3">
            {t("drive_starting")}
          </th>
          <th scope="col" className="col-3">
            {t("drive_ending")}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.id}>
            <td>{item.origin}</td>
            <td>{item.destination}</td>
            <td>{DateTimeUtil.dateTimeFormat(item.driveStarting)}</td>
            <td>{DateTimeUtil.dateTimeFormat(item.driveStarting)}</td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
}
