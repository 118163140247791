import {
  ColorEnum,
  PackageModel,
  PackageStatusEnum,
  PageRouteEnum,
} from "../../types";
import { useNavigate } from "react-router-dom";
import { DefaultButton, TableComponent } from "../common";
import { useTranslation } from "react-i18next";

export default function SenderPackagesTableComponent({
  items,
}: {
  items: PackageModel[];
  onItemClick?: (model: PackageModel) => void;
  isHome?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3">
            {t("origin")}
          </th>
          <th scope="col" className="col-3">
            {t("destination")}
          </th>
          <th scope="col" className="col-3">
            {t("status")}
          </th>
          <th scope="col" className="col-3">
            {t("actions")}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.id}>
            <td>{item.origin}</td>
            <td>{item.destination}</td>
            <td>{t(PackageStatusEnum.getStatusText(item.status))}</td>
            <td>
              <div className="btn-group gap-1">
                <DefaultButton
                  width={80}
                  disabled={item.status !== PackageStatusEnum.New}
                  color={ColorEnum.Grey}
                  onClick={() => {
                    navigate(
                      PageRouteEnum.PackageEdit.replace(":id", item.id || "")
                    );
                  }}
                >
                  {t("edit")}
                </DefaultButton>
                <DefaultButton
                  width={80}
                  onClick={() => {
                    navigate(
                      PageRouteEnum.PackageDetails.replace(":id", item.id || "")
                    );
                  }}
                >
                  {t("details")}
                </DefaultButton>
                <DefaultButton
                  width={110}
                  onClick={() => {
                    navigate(
                      PageRouteEnum.PackageOffers.replace(":id", item.id || "")
                    );
                  }}
                >
                  {t("view_offers")}
                </DefaultButton>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
}
