import { useEffect, useState } from "react";
import {
  Loader,
  NavBar,
  Spacing,
  StatisticsCardComponent,
} from "../../components";
import { StatisticsService } from "../../services";
import { useTranslation } from "react-i18next";
import {
  BootstrapIconsEnum,
  DelivererStatisticsModel,
  SpacingEnum,
} from "../../types";

const statisticsService = new StatisticsService();

export default function DelivererStatisticsPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [statistics, setStatistics] = useState<DelivererStatisticsModel>();

  useEffect(() => {
    const getStatisticsAsync = async () => {
      const response = await statisticsService.getDelivererStatisticsAsync();

      if (response.isSuccess && response.result) {
        setStatistics(response.result!);
      }
      setIsLoading(false);
    };

    getStatisticsAsync();
  }, []);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <h2>{t("statistics")}</h2>
          <Spacing size={SpacingEnum.Large} />
          <div className="row gy-4">
            <StatisticsCardComponent
              title={t("routes_created")}
              value={statistics?.createdRoutesCount || 0}
              icon={BootstrapIconsEnum.New}
            />
            <StatisticsCardComponent
              title={t("routes_in_progress")}
              value={statistics?.routesInProgressCount || 0}
              icon={BootstrapIconsEnum.InDelivery}
            />
            <StatisticsCardComponent
              title={t("routes_finished")}
              value={statistics?.finishedRoutesCount || 0}
              icon={BootstrapIconsEnum.Delivered}
            />
            <StatisticsCardComponent
              title={t("average_price")}
              value={(statistics?.averagePrice || 0).toFixed(2) + "€"}
              icon={BootstrapIconsEnum.Euro}
            />
            <StatisticsCardComponent
              title={t("packages_delivered")}
              value={statistics?.packagesDeliveredCount || 0}
              icon={BootstrapIconsEnum.BoxSeam}
            />
          </div>
          <Spacing size={SpacingEnum.Large} />
          <h2>{t("employees")}</h2>
          <Spacing size={SpacingEnum.Large} />
          <div className="row gy-4">
            <StatisticsCardComponent
              title={t("employees_count")}
              value={statistics?.employeesCount || 0}
              icon={BootstrapIconsEnum.Employees}
            />
            <StatisticsCardComponent
              title={t("average_rating")}
              value={(statistics?.averageRating || 0).toFixed(2)}
              icon={BootstrapIconsEnum.Rating}
            />
          </div>
        </div>
      </Loader>
    </div>
  );
}
