import { CSSProperties, MouseEventHandler } from "react";
import { ColorEnum } from "../../types";

export default function DefaultButton({
  onClick,
  type = "button",
  color = ColorEnum.Blue,
  width,
  children,
  disabled,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: "submit" | "reset" | "button";
  color?: ColorEnum;
  width?: number;
  children?: React.ReactNode;
  disabled?: boolean;
}) {
  const getButtonStyle = (): CSSProperties | undefined => {
    let styles: CSSProperties = {
      width: "100%",
      height: "100%",
      backgroundColor: color,
      borderRadius: "4px",
      color: ColorEnum.White,
      borderColor: color,
      borderTopColor: color,
    };

    // if (disabled) {
    //   styles = {
    //     ...styles,

    //   };
    // }

    return styles;
  };

  return (
    <div
      className="d-flex align-items-center"
      style={{ width: width ? `${width}px` : undefined, height: "36px" }}
    >
      <button
        type={type}
        className="btn"
        onClick={onClick}
        disabled={disabled}
        style={getButtonStyle()}
      >
        {children}
      </button>
    </div>
  );
}
