import { DelivererModel } from "./DelivererModel";

export class SenderPackageOfferModel {
  id?: string;
  price?: number;
  plannedDeliveryDate?: Date;
  isSelected?: boolean;
  packageId?: string;
  origin?: string;
  destination?: string;
  delivererInfo?: DelivererModel;

  constructor({
    id,
    price,
    plannedDeliveryDate,
    isSelected,
    packageId,
    origin,
    destination,
    delivererInfo,
  }: SenderPackageOfferModel = {}) {
    this.id = id;
    this.price = price;
    this.plannedDeliveryDate = plannedDeliveryDate;
    this.isSelected = isSelected;
    this.packageId = packageId;
    this.origin = origin;
    this.destination = destination;
    this.delivererInfo = delivererInfo;
  }

  static fromJson(data: any): SenderPackageOfferModel {
    return new SenderPackageOfferModel({
      id: data["id"],
      price: data["price"],
      plannedDeliveryDate:
        data["plannedDeliveryDate"] && new Date(data["plannedDeliveryDate"]),
      isSelected: data["isSelected"],
      packageId: data["packageId"],
      origin: data["origin"],
      destination: data["destination"],
      delivererInfo:
        data["delivererInfo"] && DelivererModel.fromJson(data["delivererInfo"]),
    });
  }
}
