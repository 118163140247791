export class AuthResponseModel {
  token: string;
  tokenExpiration: Date;
  refreshToken: string;
  language: string;
  roles: string;
  userId: string;
  companyId?: string;

  constructor({
    token,
    tokenExpiration,
    refreshToken,
    language,
    roles,
    userId,
    companyId,
  }: AuthResponseModel) {
    this.token = token;
    this.tokenExpiration = tokenExpiration;
    this.refreshToken = refreshToken;
    this.language = language;
    this.roles = roles;
    this.userId = userId;
    this.companyId = companyId;
  }

  static fromJson(data: any): AuthResponseModel {
    return new AuthResponseModel({
      token: data["token"],
      tokenExpiration: new Date(data["expirationTime"]),
      refreshToken: data["refreshToken"],
      language: data["language"],
      roles: data["roles"],
      userId: data["userId"],
      companyId: data["companyId"],
    });
  }
}
