import { DefaultButton, Spacing } from "../common";
import {
  ColorEnum,
  Constants,
  InvitePageStatusEnum,
  SpacingEnum,
} from "../../types";
import { useTranslation } from "react-i18next";

export default function EmployeesInvitedByEmailComponent({
  onStatusChange,
}: {
  onStatusChange: (status: InvitePageStatusEnum) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="container mt-3">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6">
          <div
            className="d-flex flex-column justify-content-center text-center"
            style={{ maxWidth: Constants.maxWidth }}
          >
            <div className="text-center">
              <img
                alt={t("invite")}
                src="/images/invite_sent.png"
                className="mb-4 col-8"
              />
            </div>
            <Spacing size={SpacingEnum.Large} />
            <h4>{t("invite_sent_to_the_given_emails")}</h4>
            <Spacing size={SpacingEnum.Giant} />
            <DefaultButton
              type="button"
              onClick={() => onStatusChange(InvitePageStatusEnum.CreateInvite)}
              color={ColorEnum.Orange}
            >
              {t("send_more_invites")}
            </DefaultButton>
          </div>
        </div>
      </div>
    </div>
  );
}
