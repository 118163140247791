import { useState } from "react";
import {
  EmployeesInviteCodeGeneratedComponent,
  EmployeesInviteComponent,
  EmployeesInvitedByEmailComponent,
  NavBar,
  Spacing,
} from "../../components";
import {
  CompanyInviteCodeModel,
  InvitePageStatusEnum,
  SpacingEnum,
} from "../../types";
import { CompanyService, UserDataService } from "../../services";
import { LanguageUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const companyService = new CompanyService();

export default function EmployeeInvitePage() {
  const { t } = useTranslation();
  const [inviteStatus, setInviteStatus] = useState(
    InvitePageStatusEnum.CreateInvite
  );
  const [inviteCodeModel, setInviteCodeModel] =
    useState<CompanyInviteCodeModel>({});

  const handleGenerateInvite = async () => {
    const companyId = UserDataService.getCompanyId();

    const result = await companyService.generateInviteCodeAsync(companyId!);

    if (!result.isSuccess || !result.result) {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
      return;
    }

    ToastUtil.showSuccessMessage(t("invite_code_generated"));

    setInviteCodeModel(result.result!);
    setInviteStatus(InvitePageStatusEnum.CodeGenerated);
  };

  const handleSendInvite = async (emails: string[]) => {
    const companyId = UserDataService.getCompanyId();

    const result = await companyService.inviteEmployeesByEmailAsync(
      companyId!,
      emails
    );

    if (!result.isSuccess) {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
      return;
    }

    ToastUtil.showSuccessMessage(t("invitations_sent_successfully"));

    setInviteStatus(InvitePageStatusEnum.InvitedByEmail);
  };

  return (
    <div>
      <NavBar />
      <div>
        {inviteStatus === InvitePageStatusEnum.CreateInvite && (
          <EmployeesInviteComponent
            generateInviteAsync={handleGenerateInvite}
            sendInviteAsync={handleSendInvite}
          />
        )}
        {inviteStatus === InvitePageStatusEnum.CodeGenerated && (
          <EmployeesInviteCodeGeneratedComponent
            model={inviteCodeModel}
            onStatusChange={setInviteStatus}
          />
        )}
        {inviteStatus === InvitePageStatusEnum.InvitedByEmail && (
          <EmployeesInvitedByEmailComponent onStatusChange={setInviteStatus} />
        )}
      </div>
      <Spacing size={SpacingEnum.Giant} />
    </div>
  );
}
