export class LoginModel {
  email?: string;
  password?: string;
  rememberMe?: boolean;

  constructor({ email, password, rememberMe }: LoginModel = {}) {
    this.email = email;
    this.password = password;
    this.rememberMe = rememberMe;
  }
}
