import {
  CompanyStatisticsModel,
  PackageStatisticsModel,
  RouteStatisticsModel,
  UserStatisticsModel,
} from ".";

export class StatisticsModel {
  companies: CompanyStatisticsModel[];
  routes: RouteStatisticsModel[];
  packages: PackageStatisticsModel[];
  users: UserStatisticsModel[];

  constructor({ companies, routes, packages, users }: StatisticsModel) {
    this.companies = companies || [];
    this.routes = routes || [];
    this.packages = packages || [];
    this.users = users || [];
  }

  static fromJson(data: any): StatisticsModel {
    return new StatisticsModel({
      companies:
        data["companies"] &&
        data["companies"].map((item: any) =>
          CompanyStatisticsModel.fromJson(item)
        ),
      routes:
        data["routes"] &&
        data["routes"].map((item: any) => RouteStatisticsModel.fromJson(item)),
      packages:
        data["packages"] &&
        data["packages"].map((item: any) =>
          PackageStatisticsModel.fromJson(item)
        ),
      users:
        data["users"] &&
        data["users"].map((item: any) => UserStatisticsModel.fromJson(item)),
    });
  }
}
