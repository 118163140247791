import { useEffect, useState } from "react";
import {
  DelivererInfoTableComponent,
  Loader,
  NavBar,
  PackageDetailsTableComponent,
  Spacing,
  TableComponent,
} from "../../components";
import { PackageOfferService } from "../../services";
import {
  ColorEnum,
  DelivererModel,
  PackageModel,
  PackageOfferFullInfoModel,
  SpacingEnum,
} from "../../types";
import { useParams } from "react-router-dom";
import { DateTimeUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const packageOfferService = new PackageOfferService();

export default function OfferDetailsPage() {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [model, setModel] = useState<PackageOfferFullInfoModel>();

  useEffect(() => {
    const fetchPackageOffer = async () => {
      const response = await packageOfferService.findByIdAsync(params.id || "");

      if (response.isSuccess && response.result) {
        setModel(response.result);
      }

      setIsLoading(false);
    };

    fetchPackageOffer();
  }, [params.id]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <h3 style={{ color: ColorEnum.Orange }}>{t("offer_details")}</h3>
          <TableComponent>
            <thead>
              <tr>
                <th scope="col" className="col-3"></th>
                <th scope="col" className="col-9"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("price")}</td>
                <td>{model?.price?.toFixed(2)}€</td>
              </tr>
              <tr>
                <td>{t("planned_delivery_date")}</td>
                <td>{DateTimeUtil.dateFormat(model?.plannedDeliveryDate)}</td>
              </tr>
            </tbody>
          </TableComponent>
          <h5 style={{ color: ColorEnum.Orange }}>{t("package details")}</h5>
          <PackageDetailsTableComponent
            model={model?.packageInfo || new PackageModel()}
            showFullInfo={false}
          />
          <Spacing size={SpacingEnum.Large} />
          <h5 style={{ color: ColorEnum.Orange }}>{t("deliverer_details")}</h5>
          <DelivererInfoTableComponent
            model={model?.delivererInfo || new DelivererModel()}
          />
          <Spacing size={SpacingEnum.Large} />
        </div>
      </Loader>
    </div>
  );
}
