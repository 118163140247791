import { PackageSpecialRequirementModel } from "./PackageSpecialRequirementModel";

export class PackageWithOfferModel {
  id?: string;
  origin?: string;
  destination?: string;
  size?: string;
  weight?: number;
  type?: string;
  targetPrice?: number;
  offerId?: string;
  offerPrice?: number;
  offerPlannedDeliveryDate?: Date;
  isSelected?: boolean;
  specialRequirements?: PackageSpecialRequirementModel[];

  constructor({
    id,
    origin,
    destination,
    size,
    weight,
    type,
    targetPrice,
    offerId,
    offerPrice,
    offerPlannedDeliveryDate,
    isSelected,
    specialRequirements,
  }: PackageWithOfferModel = {}) {
    this.id = id;
    this.origin = origin;
    this.destination = destination;
    this.size = size;
    this.weight = weight;
    this.type = type;
    this.targetPrice = targetPrice;
    this.offerId = offerId;
    this.offerPrice = offerPrice;
    this.offerPlannedDeliveryDate = offerPlannedDeliveryDate;
    this.isSelected = isSelected;
    this.specialRequirements = specialRequirements;
  }

  static fromJson(data: any): PackageWithOfferModel {
    return new PackageWithOfferModel({
      id: data["id"],
      origin: data["origin"],
      destination: data["destination"],
      size: data["size"],
      weight: data["weight"],
      type: data["type"],
      targetPrice: data["targetPrice"],
      offerId: data["offerId"],
      offerPrice: data["offerPrice"],
      offerPlannedDeliveryDate:
        data["offerPlannedDeliveryDate"] &&
        new Date(data["offerPlannedDeliveryDate"]),
      isSelected: data["isSelected"],
      specialRequirements:
        data["packageSpecialRequirements"] &&
        data["packageSpecialRequirements"].map((req: any) =>
          PackageSpecialRequirementModel.fromJson(req)
        ),
    });
  }
}
