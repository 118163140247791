export class ErrorDescriberModel {
  errorCode?: string;
  errorMessage?: string;

  constructor({ errorCode, errorMessage }: ErrorDescriberModel) {
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
  }

  static fromJson(data: any): ErrorDescriberModel {
    return new ErrorDescriberModel({
      errorCode: data["errorCode"],
      errorMessage: data["errorMessage"],
    });
  }
}
