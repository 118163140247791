import { DefaultButton, DropdownInput, InputForm, TextInput } from "../common";
import {
  ColorEnum,
  CompanyEmployeeFilter,
  CompanyEmployeeStatusEnum,
  DropdownItemModel,
} from "../../types";
import { SingleValue } from "react-select";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function EmployeesFilterComponent({
  filter,
  onChange,
}: {
  filter: CompanyEmployeeFilter;
  onChange?: (filter: CompanyEmployeeFilter) => void;
}) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<CompanyEmployeeFilter>(filter);
  const employeeStatusOptions = Object.values(CompanyEmployeeStatusEnum)
    .filter((value) => typeof value === "number")
    .map(
      (value) =>
        new DropdownItemModel(
          value as number,
          t(
            CompanyEmployeeStatusEnum.getStatusText(
              value as CompanyEmployeeStatusEnum
            )
          )
        )
    );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(
      new CompanyEmployeeFilter({
        ...formData,
        [event.target.name]: event.target.value,
      })
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (onChange) {
      onChange(formData);
    }
  };

  return (
    <InputForm onSubmit={handleSubmit}>
      <div className="d-flex flex-wrap gap-3">
        <TextInput
          name="query"
          title={t("search")}
          value={formData.query}
          width={170}
          onChange={handleInputChange}
        />
        <DropdownInput
          name="status"
          title={t("status")}
          width={170}
          value={
            filter.status &&
            new DropdownItemModel(
              filter.status as number,
              t(
                CompanyEmployeeStatusEnum.getStatusText(
                  filter.status as CompanyEmployeeStatusEnum
                )
              )
            )
          }
          options={employeeStatusOptions}
          onChange={(result) => {
            const value = (result as SingleValue<DropdownItemModel>)
              ?.value as number;
            setFormData(
              new CompanyEmployeeFilter({
                ...formData,
                status: value,
              })
            );
          }}
        />
        <div
          className="d-flex btn-group gap-3 align-items-end"
          style={{ marginBottom: "18px" }}
        >
          <DefaultButton type="submit" width={80} color={ColorEnum.Blue}>
            {t("filter")}
          </DefaultButton>
          {(filter.query || filter.status) && (
            <DefaultButton
              type="button"
              width={120}
              color={ColorEnum.Grey}
              onClick={() => {
                if (onChange) {
                  onChange({
                    ...formData,
                    query: undefined,
                    status: undefined,
                  });
                }
              }}
            >
              {t("clear_filter")}
            </DefaultButton>
          )}
        </div>
      </div>
    </InputForm>
  );
}
