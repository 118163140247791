export class PackageOwnerModel {
  ownerId?: string;
  ownerType?: string;
  name?: string;
  email?: string;
  image?: string;

  constructor({ ownerId, ownerType, name, email, image }: PackageOwnerModel) {
    this.ownerId = ownerId;
    this.ownerType = ownerType;
    this.name = name;
    this.email = email;
    this.image = image;
  }

  static fromJson(data: any): PackageOwnerModel {
    return new PackageOwnerModel({
      ownerId: data["ownerId"],
      ownerType: data["ownerType"],
      name: data["name"],
      email: data["email"],
      image: data["image"],
    });
  }
}
