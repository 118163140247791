import { useEffect, useState } from "react";
import {
  CompanyStatisticsTableComponent,
  Loader,
  NavBar,
  PackageStatisticsTableComponent,
  RouteStatisticsTableComponent,
  Spacing,
  UserStatisticsTableComponent,
} from "../../components";
import { StatisticsService } from "../../services";
import { ColorEnum, SpacingEnum, StatisticsModel } from "../../types";
import { useTranslation } from "react-i18next";

const statisticsService = new StatisticsService();

export default function AdminStatisticsPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [statistics, setStatistics] = useState<StatisticsModel>();

  useEffect(() => {
    const getStatisticsAsync = async () => {
      const response = await statisticsService.getAdminStatisticsAsync();

      if (response.isSuccess && response.result) {
        setStatistics(response.result!);
      }
      setIsLoading(false);
    };

    getStatisticsAsync();
  }, []);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <h2>{t("statistics")}</h2>
          <Spacing size={SpacingEnum.Large} />
          <h4 style={{ color: ColorEnum.Orange }}>{t("latest_companies")}</h4>
          <CompanyStatisticsTableComponent
            items={statistics?.companies || []}
          />
          <Spacing size={SpacingEnum.Large} />
          <h4 style={{ color: ColorEnum.Orange }}>{t("latest_routes")}</h4>
          <RouteStatisticsTableComponent items={statistics?.routes || []} />
          <Spacing size={SpacingEnum.Large} />
          <h4 style={{ color: ColorEnum.Orange }}>{t("latest_packages")}</h4>
          <PackageStatisticsTableComponent items={statistics?.packages || []} />
          <Spacing size={SpacingEnum.Large} />
          <h4 style={{ color: ColorEnum.Orange }}>{t("latest_users")}</h4>
          <UserStatisticsTableComponent items={statistics?.users || []} />
        </div>
      </Loader>
    </div>
  );
}
