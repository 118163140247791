import { CompanyEmployeeStatusEnum } from "../enums";
import { BaseFilter } from "./BaseFilter";

export class CompanyEmployeeFilter extends BaseFilter {
  companyId?: string;
  status?: CompanyEmployeeStatusEnum;
  query?: string;

  constructor({
    companyId,
    status,
    query,
    ...other
  }: CompanyEmployeeFilter = {}) {
    super(other);
    this.companyId = companyId;
    this.status = status;
    this.query = query;
  }
}
