import { NavBar } from "../../components";

export default function PackageImportPage() {
  return (
    <div>
      <NavBar />
      <div className="container mt-3">
        <h3>PackageImportPage</h3>
      </div>
    </div>
  );
}
