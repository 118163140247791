import { PackageModel } from "./PackageModel";
import { UserModel } from "./UserModel";
import { VehicleModel } from "./VehicleModel";

export class RouteModel {
  id?: string;
  userId?: string;
  vehicleId?: string;
  companyId?: string;
  origin?: string;
  destination?: string;
  detourRadius?: number;
  driveStarting?: Date;
  driveEnding?: Date;
  allowedPackages?: string[];
  price?: number;
  avoidTolls?: boolean;
  vias?: string;
  startedAt?: Date;
  finishedAt?: Date;
  state?: string;
  isRecurrent?: boolean;
  recurrenceFrequency?: string;
  recurrentRouteName?: string;
  packages?: PackageModel[];
  vehicle?: VehicleModel;
  driver?: UserModel;

  constructor({
    id,
    userId,
    vehicleId,
    companyId,
    origin,
    destination,
    detourRadius,
    driveStarting,
    driveEnding,
    allowedPackages,
    price,
    avoidTolls,
    vias,
    startedAt,
    finishedAt,
    state,
    isRecurrent,
    recurrenceFrequency,
    recurrentRouteName,
    vehicle,
    driver,
  }: RouteModel = {}) {
    this.id = id;
    this.userId = userId;
    this.vehicleId = vehicleId;
    this.companyId = companyId;
    this.origin = origin;
    this.destination = destination;
    this.detourRadius = detourRadius;
    this.driveStarting = driveStarting;
    this.driveEnding = driveEnding;
    this.allowedPackages = allowedPackages;
    this.price = price;
    this.avoidTolls = avoidTolls;
    this.vias = vias;
    this.startedAt = startedAt;
    this.finishedAt = finishedAt;
    this.state = state;
    this.isRecurrent = isRecurrent;
    this.recurrenceFrequency = recurrenceFrequency;
    this.recurrentRouteName = recurrentRouteName;
    this.vehicle = vehicle;
    this.driver = driver;
  }

  static fromJson(data: any): RouteModel {
    return new RouteModel({
      id: data["id"],
      userId: data["userId"],
      vehicleId: data["vehicleId"],
      companyId: data["companyId"],
      origin: data["origin"],
      destination: data["destination"],
      driveStarting: new Date(data["driveStarting"]),
      driveEnding: new Date(data["driveEnding"]),
      detourRadius: data["detourRadius"],
      allowedPackages: data["allowedPackages"],
      price: data["price"],
      avoidTolls: data["avoidTolls"],
      isRecurrent: data["isRecurrent"],
      recurrenceFrequency: data["recurrenceFrequency"],
      recurrentRouteName: data["recurrentRouteName"],
      vias: data["vias"],
      startedAt: new Date(data["startedAt"]),
      finishedAt: new Date(data["finishedAt"]),
      state: data["state"],
      packages:
        data["packages"] &&
        data["packages"].map((item: any) => PackageModel.fromJson(item)),
      vehicle: data["vehicle"] && VehicleModel.fromJson(data["vehicle"]),
      driver: data["user"] && UserModel.fromJson(data["user"]),
    });
  }
}
