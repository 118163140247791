import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DefaultButton,
  Loader,
  ModalComponent,
  NavBar,
  Spacing,
} from "../../components";
import {
  ColorEnum,
  PackageModel,
  PackageStatusEnum,
  PageRouteEnum,
  RoleEnum,
  SpacingEnum,
} from "../../types";
import PackageDetailsTableComponent from "../../components/packages/PackageDetailsTableComponent";
import { PackageService, UserDataService } from "../../services";
import { LanguageUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const packageService = new PackageService();

export default function PackageDetailsPage() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [model, setModel] = useState(new PackageModel());
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const deletePackageAsync = async () => {
    const response = await packageService.deleteAsync(params.id ?? "");

    if (response.isSuccess) {
      ToastUtil.showSuccessMessage(t("package_deleted"));
      navigate(-1);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, response.errorMessage)
      );
    }
  };

  useEffect(() => {
    const getPackageAsync = async () => {
      const response = await packageService.findByIdAsync(params.id ?? "");

      if (response.isSuccess) {
        setModel(response.result!);
      }
    };

    const fetchData = async () => {
      const promises = [getPackageAsync()];

      await Promise.all(promises);

      setIsLoading(false);
    };

    fetchData();
  }, [params.id]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <div className="d-flex justify-content-between ">
            <div className="d-flex justify-content-between align-items-center ">
              <h2>{t("package_details")}</h2>
            </div>
            <div className="d-flex btn-group gap-1">
              {(UserDataService.isInRole(RoleEnum.SenderBusinessAdmin) ||
                UserDataService.isInRole(RoleEnum.SenderBusinessAdmin)) && (
                <DefaultButton
                  onClick={() => {
                    navigate(
                      PageRouteEnum.PackageOffers.replace(
                        ":id",
                        params.id || ""
                      )
                    );
                  }}
                >
                  {t("view_offers")}
                </DefaultButton>
              )}
              {(UserDataService.isInRole(RoleEnum.SenderBusinessAdmin) ||
                (UserDataService.isInRole(RoleEnum.BusinessSender) &&
                  UserDataService.getCompanyId() === model.companyId)) && (
                <>
                  <DefaultButton
                    width={80}
                    color={ColorEnum.Grey}
                    disabled={model.status !== PackageStatusEnum.New}
                    onClick={() => {
                      navigate(
                        PageRouteEnum.PackageEdit.replace(
                          ":id",
                          params.id || ""
                        )
                      );
                    }}
                  >
                    {t("edit")}
                  </DefaultButton>
                  <DefaultButton
                    width={80}
                    color={ColorEnum.Red}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {t("delete")}
                  </DefaultButton>
                </>
              )}
            </div>
          </div>
          <PackageDetailsTableComponent model={model} />
        </div>
      </Loader>
      <ModalComponent
        title={t("confirm_delete")}
        show={showModal}
        onShowChange={setShowModal}
        onConfirm={deletePackageAsync}
      >
        <p>{t("package_confirm_delete")}</p>
      </ModalComponent>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
