export class PackageShortInfoModel {
  id?: string;
  origin?: string;
  destination?: string;
  sender?: string;

  constructor({ id, origin, destination, sender }: PackageShortInfoModel) {
    this.id = id;
    this.origin = origin;
    this.destination = destination;
    this.sender = sender;
  }

  static fromJson(data: any): PackageShortInfoModel {
    return new PackageShortInfoModel({
      id: data["id"],
      origin: data["origin"],
      destination: data["destination"],
      sender: data["sender"],
    });
  }
}
