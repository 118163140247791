import { UserDataService } from "../../services";
import { RoleEnum } from "../../types";
import AdminStatisticsPage from "./AdminStatisticsPage";
import DelivererStatisticsPage from "./DelivererStatisticsPage";
import SenderStatisticsPage from "./SenderStatisticsPage";

export default function StatisticsPage() {
  if (UserDataService.isInRole(RoleEnum.DeliveryBusinessAdmin)) {
    return <DelivererStatisticsPage />;
  }

  if (
    UserDataService.isInRole(RoleEnum.BusinessSender) ||
    UserDataService.isInRole(RoleEnum.SenderBusinessAdmin)
  ) {
    return <SenderStatisticsPage />;
  }

  return <AdminStatisticsPage />;
}
