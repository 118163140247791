import { useEffect, useState } from "react";
import Spacing from "./Spacing";
import { SpacingEnum } from "../../types";
import { useTranslation } from "react-i18next";

export default function DateTimeInput({
  title,
  name,
  onChange,
  value,
  onValidate,
  onValidationChange,
}: {
  title: string;
  name: string;
  value: Date;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onValidate?: (value: string) => string;
  onValidationChange?: (name: string, isValid: boolean) => void;
} & any) {
  const { t } = useTranslation();
  const [isTouched, setIsTouched] = useState(false);
  const [inputValue, setInputValue] = useState(value || "");
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const defaultErrorMessage = t("field_required");

  useEffect(() => {
    const error = (onValidate && onValidate(inputValue)) || undefined;
    const isInputValid = !error;

    setIsValid(isInputValid);
    setErrorMessage(error || defaultErrorMessage);
    if (onValidationChange) {
      onValidationChange(name, isInputValid);
    }
  }, [inputValue, name, defaultErrorMessage, onValidationChange]);

  const formatDateForInput = (date: Date) => {
    if (!date) return "";

    const localDate = new Date(date.toLocaleString());

    const year = localDate.getFullYear();
    const month = (localDate.getMonth() + 1).toString().padStart(2, "0");
    const day = localDate.getDate().toString().padStart(2, "0");
    const hours = localDate.getHours().toString().padStart(2, "0");
    const minutes = localDate.getMinutes().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    return formattedDate;
  };

  return (
    <div className="form-group mb-3 mt-3">
      {title && (
        <>
          <label htmlFor={name} className="form-label">
            {title}
          </label>
          <Spacing size={SpacingEnum.Small} />
        </>
      )}
      <input
        className={`form-control ${isTouched && !isValid ? "is-invalid" : ""}`}
        type="datetime-local"
        id={name}
        name={name}
        onFocus={() => {
          if (!isTouched) {
            setIsTouched(true);
          }
        }}
        onChange={(event) => {
          if (!isTouched) {
            setIsTouched(true);
          }
          setInputValue(event.target.value);
          onChange(event);
        }}
        value={formatDateForInput(inputValue)}
      />
      {isTouched && <div className="invalid-feedback">{errorMessage}</div>}
    </div>
  );
}
