import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

i18n
  .use(HttpBackend) // loads translations from your server or local
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng: "en", // use en if detected lng is not available
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // path to the translation files
    },
    debug: true, // enable debug mode to see logs in the console
    interpolation: {
      escapeValue: false, // not needed for React as it escapes by default
    },
  });

export default i18n;
