import { useTranslation } from "react-i18next";
import { VehicleModel } from "../../types";
import { TableComponent } from "../common";

export default function VehicleDetailsTableComponent({
  model,
}: {
  model: VehicleModel;
}) {
  const { t } = useTranslation();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3"></th>
          <th scope="col" className="col-9"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("type")}</td>
          <td>{model.type}</td>
        </tr>
        <tr>
          <td>{t("brand")}</td>
          <td>{model.brand}</td>
        </tr>
        <tr>
          <td>{t("model")}</td>
          <td>{model.model}</td>
        </tr>
        <tr>
          <td>{t("color")}</td>
          <td>{model.color}</td>
        </tr>
        <tr>
          <td>{t("registration_number")}</td>
          <td>{model.registration}</td>
        </tr>
        <tr>
          <td>{t("allowed_packages")}</td>
          <td>{model.allowedPackages}</td>
        </tr>
      </tbody>
    </TableComponent>
  );
}
