import { DelivererModel } from "./DelivererModel";
import { PackageModel } from "./PackageModel";
import { PackageSpecialRequirementModel } from "./PackageSpecialRequirementModel";

export class PackageOfferFullInfoModel {
  packageOfferId?: string;
  price?: number;
  plannedDeliveryDate?: Date;
  packageInfo?: PackageModel;
  delivererInfo?: DelivererModel;
  isSelected?: boolean;

  constructor({
    packageOfferId,
    price,
    plannedDeliveryDate,
    packageInfo,
    delivererInfo,
    isSelected,
  }: PackageOfferFullInfoModel = {}) {
    this.packageOfferId = packageOfferId;
    this.price = price;
    this.plannedDeliveryDate = plannedDeliveryDate;
    this.packageInfo = packageInfo;
    this.delivererInfo = delivererInfo;
    this.isSelected = isSelected;
  }

  static fromJson(data: any): PackageOfferFullInfoModel {
    return new PackageOfferFullInfoModel({
      packageOfferId: data["packageOfferId"],
      price: data["price"],
      plannedDeliveryDate:
        data["plannedDeliveryDate"] && new Date(data["plannedDeliveryDate"]),
      packageInfo: new PackageModel({
        id: data["packageId"],
        origin: data["origin"],
        destination: data["destination"],
        size: data["packageSize"],
        weight: data["packageWeight"],
        type: data["packageType"],
        specialRequirements:
          data["specialRequirements"] &&
          data["specialRequirements"].map((req: any) =>
            PackageSpecialRequirementModel.fromJson(req)
          ),
      }),
      delivererInfo: DelivererModel.fromJson(data["delivererInfo"]),
      isSelected: data["isSelected"],
    });
  }
}
