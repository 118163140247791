import { useEffect, useState } from "react";
import {
  DefaultButton,
  DelivererRouteTableComponent,
  Loader,
  NavBar,
  NoItemsComponent,
  PaginationComponent,
  RouteFilterComponent,
  Spacing,
} from "../../components";
import {
  ColorEnum,
  PageRouteEnum,
  PagedList,
  RouteFilter,
  RouteModel,
  SpacingEnum,
} from "../../types";
import { useNavigate } from "react-router-dom";
import { RouteService, UserDataService } from "../../services";
import { useTranslation } from "react-i18next";

const routeService = new RouteService();

export default function DelivererRoutesPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [pagedRoutes, setPagedRoutes] = useState<PagedList<RouteModel>>();
  const [routeFilter, setRouteFilter] = useState<RouteFilter>({
    companyId: UserDataService.getCompanyId() || undefined,
    pageNumber: 1,
    pageSize: 10,
  });
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const getRoutesAsync = async () => {
      const response = await routeService.getPagedAsync(routeFilter);

      if (response.isSuccess) {
        setPagedRoutes(response.result!);
      }
      setIsLoading(false);
    };

    getRoutesAsync();
  }, [routeFilter]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <div className="d-flex justify-content-between">
            <h2>{t("routes")}</h2>
            <DefaultButton
              width={200}
              color={ColorEnum.Orange}
              onClick={() => navigate(PageRouteEnum.RouteCreate)}
            >
              {t("create_route")}
            </DefaultButton>
          </div>
          <Spacing size={SpacingEnum.Large} />
          <RouteFilterComponent
            filter={routeFilter}
            onChange={(filter: RouteFilter) => {
              setRouteFilter(filter);
            }}
          />
          <DelivererRouteTableComponent items={pagedRoutes?.items || []} />
          {(pagedRoutes?.items || []).length === 0 && <NoItemsComponent />}
          <PaginationComponent
            onPageChange={(page) => {
              setRouteFilter({ ...routeFilter, pageNumber: page });
            }}
            currentPage={pagedRoutes?.pageNumber || 1}
            pageCount={pagedRoutes?.lastPage || 1}
          />
        </div>
      </Loader>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
