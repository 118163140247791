import axios from "axios";
import { DOTNET_API_URL } from "../config";
import {
  CompanyEmployeeFilter,
  CompanyEmployeeModel,
  CompanyEmployeeStatusEnum,
  HttpHeaders,
  PagedList,
  ResponseModel,
} from "../types";

const URL_PREFIX = DOTNET_API_URL + "/company-employee";

export class CompanyEmployeeService {
  async getByUserIdAsync(
    userId: string
  ): Promise<ResponseModel<CompanyEmployeeModel>> {
    try {
      const response = await axios.get(URL_PREFIX + `/${userId}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = CompanyEmployeeModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async getPagedAsync(
    filter: CompanyEmployeeFilter
  ): Promise<ResponseModel<PagedList<CompanyEmployeeModel>>> {
    const params = new URLSearchParams();

    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        params.append(key, value.toString());
      }
    });

    try {
      const response = await axios.get(URL_PREFIX + `?${params}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PagedList.fromJson<CompanyEmployeeModel>(
        response.data,
        CompanyEmployeeModel
      );

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async updateStatusAsync(
    userId: string,
    companyId: string,
    status: CompanyEmployeeStatusEnum
  ): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.patch(
        URL_PREFIX + `/status`,
        {
          userId,
          companyId,
          status,
        },
        {
          headers: HttpHeaders.get(),
        }
      );

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch {}

    return ResponseModel.failure();
  }

  async deleteAsync(
    userId: string,
    companyId: string
  ): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.delete(
        URL_PREFIX + `?userId=${userId}&companyId=${companyId}`,
        {
          headers: HttpHeaders.get(),
        }
      );

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch {
      return ResponseModel.failure();
    }
  }
}
