import { useState } from "react";
import { AddressInput, DateInput, DefaultButton, InputForm } from "../common";
import { ColorEnum, PackageOfferFilter } from "../../types";
import { useTranslation } from "react-i18next";

export default function DelivererOfferFilterComponent({
  filter,
  onChange,
}: {
  filter: PackageOfferFilter;
  onChange: (filter: PackageOfferFilter) => void;
}) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<PackageOfferFilter>(filter);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (onChange) {
      onChange(formData);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(
      new PackageOfferFilter({
        ...formData,
        [event.target.name]: event.target.value,
      })
    );
  };

  return (
    <InputForm onSubmit={handleSubmit}>
      <div className="d-flex flex-wrap gap-3">
        <AddressInput
          name="origin"
          title={t("origin")}
          initialValue={formData.origin}
          width={170}
          onChange={(value: string) => {
            setFormData({ ...formData, origin: value });
          }}
        />
        <AddressInput
          name="destination"
          title={t("destination")}
          initialValue={formData.destination}
          width={170}
          onChange={(value: string) => {
            setFormData({ ...formData, destination: value });
          }}
        />
        <DateInput
          name="deliveryDate"
          title={t("delivery_date")}
          value={formData.deliveryDate}
          width={170}
          onChange={handleInputChange}
        />
        <div
          className="d-flex btn-group gap-3 align-items-end"
          style={{ marginBottom: "18px" }}
        >
          <DefaultButton type="submit" width={80} color={ColorEnum.Blue}>
            {t("filter")}
          </DefaultButton>
          {(filter.origin || filter.destination || filter.deliveryDate) && (
            <DefaultButton
              type="button"
              width={120}
              color={ColorEnum.Grey}
              onClick={() => {
                if (onChange) {
                  onChange({
                    ...formData,
                    origin: undefined,
                    destination: undefined,
                    deliveryDate: undefined,
                  });
                }
              }}
            >
              {t("clear_filter")}
            </DefaultButton>
          )}
        </div>
      </div>
    </InputForm>
  );
}
