import { useNavigate } from "react-router-dom";
import { DelivererPackageOfferModel, PageRouteEnum } from "../../types";
import { DateTimeUtil } from "../../utils";
import { DefaultButton, TableComponent } from "../common";
import { useTranslation } from "react-i18next";

export default function DelivererPackageOffersTableComponent({
  items,
  onSelect,
  showOffersButton = true,
}: {
  items: DelivererPackageOfferModel[];
  onSelect: (packageOffer: DelivererPackageOfferModel) => void;
  showOffersButton?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3">
            {t("origin")}
          </th>
          <th scope="col" className="col-3">
            {t("destination")}
          </th>
          <th scope="col" className="col-2">
            {t("delivery_date")}
          </th>
          <th scope="col" className="col-2">
            {t("offered_price")}
          </th>
          <th scope="col" className="col-2">
            {t("actions")}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.id}>
            <td>{item.origin}</td>
            <td>{item.destination}</td>
            <td>{DateTimeUtil.dateFormat(item.plannedDeliveryDate)}</td>
            <td>{item.price?.toFixed(2)}€</td>
            <td>
              <div className="btn-group gap-1">
                <DefaultButton
                  width={110}
                  onClick={() => {
                    navigate(
                      PageRouteEnum.PackageDetails.replace(
                        ":id",
                        item.packageId || ""
                      )
                    );
                  }}
                >
                  {t("details")}
                </DefaultButton>
                {showOffersButton && (
                  <DefaultButton
                    width={110}
                    onClick={() => {
                      onSelect(item);
                    }}
                  >
                    {t("view_offer")}
                  </DefaultButton>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
}
