import { useCallback, useEffect, useState } from "react";
import {
  DefaultButton,
  DropdownInput,
  InputForm,
  Loader,
  NavBar,
  Spacing,
  TextInput,
} from "../../components";
import { useNavigate } from "react-router-dom";
import {
  ColorEnum,
  CompanyEmployeeFilter,
  CompanyEmployeeModel,
  CompanyEmployeeStatusEnum,
  Constants,
  DropdownItemModel,
  PagedList,
  SpacingEnum,
  VehicleModel,
} from "../../types";
import { MultiValue, SingleValue } from "react-select";
import {
  CompanyEmployeeService,
  UserDataService,
  VehicleService,
} from "../../services";
import { LanguageUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const vehicleService = new VehicleService();
const companyEmployeeService = new CompanyEmployeeService();

export default function VehicleCreatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<VehicleModel>(
    new VehicleModel({ companyId: UserDataService.getCompanyId() || "" })
  );
  const [vehicleTypes, setVehicleTypes] = useState<string[]>([]);
  const [pagedCompanyEmployees, setPagedCompanyEmployees] = useState<
    PagedList<CompanyEmployeeModel>
  >({});
  const [formValidations, setFormValidations] = useState({
    type: false,
    brand: false,
    model: false,
    registration: false,
    allowedPackages: false,
    driver: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleValidationChange = useCallback(
    (name: string, isValid: boolean) => {
      setFormValidations((prevValidations) => ({
        ...prevValidations,
        [name]: isValid,
      }));
    },
    []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = Object.values(formValidations).every(
      (isValid) => isValid
    );

    if (!isFormValid) {
      return;
    }

    const result = await vehicleService.createAsync(formData);

    if (result.isSuccess) {
      ToastUtil.showSuccessMessage(t("vehicle_created"));
      navigate(-1);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
    }
  };

  useEffect(() => {
    const getVehicleTypesAsync = async () => {
      const response = await vehicleService.getTypesAsync();

      if (response.isSuccess) {
        setVehicleTypes(response.result!);
      }
    };

    const getCompanyEmployeesAsync = async () => {
      const response = await companyEmployeeService.getPagedAsync(
        new CompanyEmployeeFilter({
          companyId: UserDataService.getCompanyId()!,
          status: CompanyEmployeeStatusEnum.Accepted,
          pageNumber: 1,
          pageSize: 50,
        })
      );

      if (response.isSuccess) {
        setPagedCompanyEmployees(response.result!);
      }
    };

    const fetchData = async () => {
      const promises = [getVehicleTypesAsync(), getCompanyEmployeesAsync()];

      await Promise.all(promises);

      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div
          className="container mt-3"
          style={{ maxWidth: Constants.maxWidth }}
        >
          <h2>{t("new_vehicle")}</h2>
          <InputForm onSubmit={handleSubmit}>
            <DropdownInput
              name="type"
              title={t("type")}
              options={vehicleTypes.map(
                (value) => new DropdownItemModel(value.toLowerCase(), value)
              )}
              onChange={(result) => {
                setFormData({
                  ...formData,
                  type: result
                    ? ((result as SingleValue<DropdownItemModel>)!
                        .value as string)
                    : undefined,
                });
              }}
              defaultErrorMessage={t("please_select_the_vehicle_type")}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="brand"
              title={t("brand")}
              value={formData.brand}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_vehicle_brand");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="model"
              title={t("model")}
              value={formData.model}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_vehicle_model");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="color"
              title={t("color")}
              value={formData.color}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_vehicle_color");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="registration"
              title={t("registration_number")}
              value={formData.registration}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_vehicle_registration_number");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <DropdownInput
              name="allowedPackages"
              title={t("allowed_packages")}
              options={Constants.packageSizes.map(
                (size) => new DropdownItemModel(size, size)
              )}
              multiple={true}
              onChange={(result) => {
                setFormData({
                  ...formData,
                  allowedPackages: result
                    ? (result as MultiValue<DropdownItemModel>).map(
                        ({ value }) => value as string
                      )
                    : [],
                });
              }}
              defaultErrorMessage={t("please_select_the_allowed_packages")}
              onValidationChange={handleValidationChange}
            />
            <DropdownInput
              name="driver"
              title={t("driver")}
              options={pagedCompanyEmployees.items?.map(
                (item) =>
                  new DropdownItemModel(
                    item.userId!,
                    `${item.name} (${item.email})`
                  )
              )}
              onChange={(result) => {
                setFormData({
                  ...formData,
                  userId: result
                    ? ((result as SingleValue<DropdownItemModel>)!
                        .value as string)
                    : undefined,
                });
              }}
              defaultErrorMessage={t("please_select_the_driver")}
              onValidationChange={handleValidationChange}
            />
            <Spacing size={SpacingEnum.XXLarge} />
            <DefaultButton
              type="submit"
              color={ColorEnum.Orange}
              disabled={
                !Object.values(formValidations).every((isValid) => isValid)
              }
            >
              {t("create")}
            </DefaultButton>
          </InputForm>
        </div>
      </Loader>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
