import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import {
  AccountPage,
  HomePage,
  LoginPage,
  RouteCreatePage,
  RouteDetailsPage,
  RouteEditPage,
  EmployeesPage,
  EmployeeInvitePage,
  EmployeeProfilePage,
  NotFoundPage,
  VehiclesPage,
  VehicleCreatePage,
  VehicleDetailsPage,
  VehicleEditPage,
  PackageAssignDriverPage,
  PackageDetailsPage,
  ResetPasswordPage,
  RoutesPage,
  AuthPage,
  CompaniesPage,
  CompanyCreatePage,
  CompanyDetailsPage,
  CompanyEditPage,
  StatisticsPage,
  UserStatisticsPage,
  RouteStatisticsPage,
  PackageStatisticsPage,
  PackageCreatePage,
  PackageEditPage,
  PackageImportPage,
  PackagesPage,
  RegisterPage,
  ConfirmEmailPage,
  OffersPage,
  PackageOffersPage,
  OfferDetailsPage,
  AcceptedOffersPage,
  RequestsPage,
  RouteRequestsPage,
} from "./pages";
import {
  AdminRoleEnums,
  AllRoleEnums,
  DeliveryRoleEnums,
  PageRouteEnum,
  RoleEnum,
  SenderRoleEnums,
} from "./types";
import { ToastContainer } from "react-toastify";
import i18n from "./config/TranslationsConfig";

const router = createBrowserRouter([
  {
    path: PageRouteEnum.Home,
    element: <AuthPage component={HomePage} roles={AllRoleEnums} />,
  },
  {
    path: PageRouteEnum.Login,
    element: <LoginPage />,
  },
  {
    path: PageRouteEnum.Register,
    element: <RegisterPage />,
  },
  {
    path: PageRouteEnum.Account,
    element: <AuthPage component={AccountPage} roles={AllRoleEnums} />,
  },
  {
    path: PageRouteEnum.ResetPassword,
    element: <ResetPasswordPage />,
  },
  {
    path: PageRouteEnum.ConfirmEmail,
    element: <ConfirmEmailPage />,
  },

  //Routes
  {
    path: PageRouteEnum.Routes,
    element: <AuthPage component={RoutesPage} roles={AllRoleEnums} />,
  },
  {
    path: PageRouteEnum.RouteCreate,
    element: <AuthPage component={RouteCreatePage} roles={DeliveryRoleEnums} />,
  },
  {
    path: PageRouteEnum.RouteDetails,
    element: <AuthPage component={RouteDetailsPage} roles={AllRoleEnums} />,
  },
  {
    path: PageRouteEnum.RouteEdit,
    element: <AuthPage component={RouteEditPage} roles={DeliveryRoleEnums} />,
  },

  //Employees
  {
    path: PageRouteEnum.Employees,
    element: <AuthPage component={EmployeesPage} roles={AdminRoleEnums} />,
  },
  {
    path: PageRouteEnum.EmployeesInvite,
    element: <AuthPage component={EmployeeInvitePage} roles={AdminRoleEnums} />,
  },
  {
    path: PageRouteEnum.EmployeesProfile,
    element: <AuthPage component={EmployeeProfilePage} roles={AllRoleEnums} />,
  },

  //Vehicles
  {
    path: PageRouteEnum.Vehicles,
    element: <AuthPage component={VehiclesPage} roles={DeliveryRoleEnums} />,
  },
  {
    path: PageRouteEnum.VehicleCreate,
    element: (
      <AuthPage component={VehicleCreatePage} roles={DeliveryRoleEnums} />
    ),
  },
  {
    path: PageRouteEnum.VehicleDetails,
    element: (
      <AuthPage component={VehicleDetailsPage} roles={DeliveryRoleEnums} />
    ),
  },
  {
    path: PageRouteEnum.VehicleEdit,
    element: <AuthPage component={VehicleEditPage} roles={DeliveryRoleEnums} />,
  },

  //Packages
  {
    path: PageRouteEnum.PackageCreate,
    element: <AuthPage component={PackageCreatePage} roles={SenderRoleEnums} />,
  },
  {
    path: PageRouteEnum.PackageEdit,
    element: <AuthPage component={PackageEditPage} roles={SenderRoleEnums} />,
  },
  {
    path: PageRouteEnum.PackageImport,
    element: <AuthPage component={PackageImportPage} roles={SenderRoleEnums} />,
  },
  {
    path: PageRouteEnum.Packages,
    element: <AuthPage component={PackagesPage} roles={AllRoleEnums} />,
  },
  {
    path: PageRouteEnum.PackageDetails,
    element: <AuthPage component={PackageDetailsPage} roles={AllRoleEnums} />,
  },
  {
    path: PageRouteEnum.PackageAssignDriver,
    element: (
      <AuthPage component={PackageAssignDriverPage} roles={DeliveryRoleEnums} />
    ),
  },

  //Company
  {
    path: PageRouteEnum.Companies,
    element: (
      <AuthPage component={CompaniesPage} roles={[RoleEnum.SuperAdmin]} />
    ),
  },
  {
    path: PageRouteEnum.CompanyCreate,
    element: (
      <AuthPage component={CompanyCreatePage} roles={[RoleEnum.SuperAdmin]} />
    ),
  },
  {
    path: PageRouteEnum.CompanyDetails,
    element: (
      <AuthPage component={CompanyDetailsPage} roles={[RoleEnum.SuperAdmin]} />
    ),
  },
  {
    path: PageRouteEnum.CompanyEdit,
    element: (
      <AuthPage component={CompanyEditPage} roles={[RoleEnum.SuperAdmin]} />
    ),
  },

  //Statistics
  {
    path: PageRouteEnum.Statistics,
    element: <AuthPage component={StatisticsPage} roles={AllRoleEnums} />,
  },
  {
    path: PageRouteEnum.UserStatistics,
    element: (
      <AuthPage component={UserStatisticsPage} roles={[RoleEnum.SuperAdmin]} />
    ),
  },
  {
    path: PageRouteEnum.RouteStatistics,
    element: (
      <AuthPage component={RouteStatisticsPage} roles={[RoleEnum.SuperAdmin]} />
    ),
  },
  {
    path: PageRouteEnum.PackageStatistics,
    element: (
      <AuthPage
        component={PackageStatisticsPage}
        roles={[RoleEnum.SuperAdmin]}
      />
    ),
  },

  // Offers
  {
    path: PageRouteEnum.Offers,
    element: <AuthPage component={OffersPage} roles={AllRoleEnums} />,
  },
  {
    path: PageRouteEnum.PackageOffers,
    element: <AuthPage component={PackageOffersPage} roles={SenderRoleEnums} />,
  },
  {
    path: PageRouteEnum.PackageOfferDetails,
    element: <AuthPage component={OfferDetailsPage} roles={AllRoleEnums} />,
  },
  {
    path: PageRouteEnum.AcceptedOffers,
    element: (
      <AuthPage component={AcceptedOffersPage} roles={DeliveryRoleEnums} />
    ),
  },

  // Requests
  {
    path: PageRouteEnum.Requests,
    element: <AuthPage component={RequestsPage} roles={DeliveryRoleEnums} />,
  },
  {
    path: PageRouteEnum.RouteRequests,
    element: <AuthPage component={RouteRequestsPage} roles={SenderRoleEnums} />,
  },

  //Not Found
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <>
    <ToastContainer autoClose={5000} />
    <RouterProvider router={router} />
  </>
);
