import axios from "axios";
import {
  HttpHeaders,
  PagedList,
  ResponseModel,
  VehicleFilter,
  VehicleModel,
} from "../types";
import { DOTNET_API_URL } from "../config";

const URL_PREFIX = DOTNET_API_URL + "/vehicle";

export class VehicleService {
  async getPagedAsync(
    filter: VehicleFilter
  ): Promise<ResponseModel<PagedList<VehicleModel>>> {
    const params = new URLSearchParams();

    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        params.append(key, value.toString());
      }
    });

    try {
      const response = await axios.get(URL_PREFIX + `?${params}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PagedList.fromJson<VehicleModel>(
        response.data,
        VehicleModel
      );

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async findByIdAsync(id: string): Promise<ResponseModel<VehicleModel>> {
    try {
      const response = await axios.get(URL_PREFIX + `/${id}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = VehicleModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async getTypesAsync(): Promise<ResponseModel<string[]>> {
    try {
      const response = await axios.get(URL_PREFIX + "/types", {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = response.data;

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async createAsync(model: VehicleModel): Promise<ResponseModel<VehicleModel>> {
    try {
      const response = await axios.post(URL_PREFIX, model, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = VehicleModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async updateAsync(model: VehicleModel): Promise<ResponseModel<VehicleModel>> {
    try {
      const response = await axios.put(URL_PREFIX + `/${model.id}`, model, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = VehicleModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async deleteAsync(id: string): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.delete(URL_PREFIX + `/${id}`, {
        headers: HttpHeaders.get(),
      });

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch {
      return ResponseModel.failure();
    }
  }
}
