import axios from "axios";
import { DOTNET_API_URL } from "../config";
import {
  DelivererPackageOfferModel,
  HttpHeaders,
  PackageOfferFilter,
  PackageOfferFullInfoModel,
  PackageOfferModel,
  PagedList,
  ResponseModel,
  SenderPackageOfferModel,
} from "../types";

const URL_PREFIX = DOTNET_API_URL + `/package-offer`;

export class PackageOfferService {
  async getPagedForDelivererAsync(
    filter: PackageOfferFilter
  ): Promise<ResponseModel<PagedList<DelivererPackageOfferModel>>> {
    const params = new URLSearchParams();

    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        params.append(key, value.toString());
      }
    });

    try {
      const response = await axios.get(URL_PREFIX + `/deliverer?${params}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PagedList.fromJson<DelivererPackageOfferModel>(
        response.data,
        DelivererPackageOfferModel
      );

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async getPagedForSenderAsync(
    filter: PackageOfferFilter
  ): Promise<ResponseModel<PagedList<SenderPackageOfferModel>>> {
    const params = new URLSearchParams();

    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        params.append(key, value.toString());
      }
    });

    try {
      const response = await axios.get(URL_PREFIX + `/sender?${params}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PagedList.fromJson<SenderPackageOfferModel>(
        response.data,
        SenderPackageOfferModel
      );

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async findByIdAsync(
    id: string
  ): Promise<ResponseModel<PackageOfferFullInfoModel>> {
    try {
      const response = await axios.get(URL_PREFIX + `/${id}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PackageOfferFullInfoModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async createAsync(model: PackageOfferModel): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.post(URL_PREFIX, model, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      return ResponseModel.success();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }

  async updateAsync(model: PackageOfferModel): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.put(
        URL_PREFIX + `/${model.packageOfferId}`,
        model,
        {
          headers: HttpHeaders.get(),
        }
      );

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }

  async deleteAsync(id: string): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.delete(URL_PREFIX + `/${id}`, {
        headers: HttpHeaders.get(),
      });

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }

  async acceptAsync(
    id: string,
    packageId: string
  ): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.post(
        URL_PREFIX + `/accept`,
        { id, packageId },
        {
          headers: HttpHeaders.get(),
        }
      );

      if (response.status !== 200) return ResponseModel.failure();

      return ResponseModel.success();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }
}
