export class UserModel {
  id?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  language?: string;
  address?: string;
  rating?: number;

  constructor({
    id,
    name,
    email,
    phoneNumber,
    language,
    address,
    rating,
  }: UserModel = {}) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.language = language;
    this.address = address;
    this.rating = rating;
  }

  static fromJson(data: any): UserModel {
    return new UserModel({
      id: data["id"],
      name: data["name"],
      email: data["email"],
      phoneNumber: data["phoneNumber"],
      language: data["language"],
      address: data["address"],
      rating: data["rating"],
    });
  }
}
