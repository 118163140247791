import { useEffect, useState } from "react";
import {
  DefaultButton,
  Loader,
  ModalComponent,
  NavBar,
  Spacing,
  VehicleDetailsTableComponent,
} from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import {
  ColorEnum,
  PageRouteEnum,
  RoleEnum,
  SpacingEnum,
  VehicleModel,
} from "../../types";
import { UserDataService, VehicleService } from "../../services";
import { LanguageUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const vehicleService = new VehicleService();

export default function VehicleDetailsPage() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [model, setModel] = useState(new VehicleModel());
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const deleteVehicleAsync = async () => {
    const response = await vehicleService.deleteAsync(params.id ?? "");

    if (response.isSuccess) {
      ToastUtil.showSuccessMessage(t("vehicle_deleted"));
      navigate(-1);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, response.errorMessage)
      );
    }
  };

  useEffect(() => {
    const getVehicleAsync = async () => {
      const response = await vehicleService.findByIdAsync(params.id ?? "");

      if (response.isSuccess) {
        setModel(response.result!);
      }

      setIsLoading(false);
    };

    getVehicleAsync();
  }, [params.id]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <div className="d-flex justify-content-between align-items-center ">
            <h2>{t("vehicle_info")}</h2>
            <div className="btn-group gap-1">
              {(UserDataService.isInRole(RoleEnum.SuperAdmin) ||
                (UserDataService.isInRole(RoleEnum.DeliveryBusinessAdmin) &&
                  UserDataService.getCompanyId() === model.companyId)) && (
                <>
                  <DefaultButton
                    width={80}
                    onClick={() => {
                      navigate(
                        PageRouteEnum.VehicleEdit.replace(
                          ":id",
                          params.id || ""
                        )
                      );
                    }}
                  >
                    {t("edit")}
                  </DefaultButton>
                  <DefaultButton
                    width={80}
                    color={ColorEnum.Red}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {t("delete")}
                  </DefaultButton>
                </>
              )}
            </div>
          </div>
          <div>
            <VehicleDetailsTableComponent model={model} />
          </div>
        </div>
      </Loader>
      <ModalComponent
        title={t("confirm_delete")}
        show={showModal}
        onShowChange={setShowModal}
        onConfirm={deleteVehicleAsync}
      >
        <p>{t("vehicle_confirm_delete")}</p>
      </ModalComponent>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
