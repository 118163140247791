import { useCallback, useEffect, useState } from "react";
import {
  DefaultButton,
  InputForm,
  DefaultNavBar,
  Spacing,
  TextInput,
  CheckBoxInput,
} from "../../components";
import {
  ColorEnum,
  Constants,
  LoginModel,
  PageRouteEnum,
  SpacingEnum,
} from "../../types";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "../../services";
import { LanguageUtil, ToastUtil, ValidationUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const authService = new AuthService();

export default function LoginPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const fromPage = location.state?.from || PageRouteEnum.Home;
  const navigate = useNavigate();
  const [formData, setFormData] = useState(new LoginModel());
  const [formValidations, setFormValidations] = useState({
    email: false,
    password: false,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleValidationChange = useCallback(
    (name: string, isValid: boolean) => {
      setFormValidations((prevValidations) => ({
        ...prevValidations,
        [name]: isValid,
      }));
    },
    []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = Object.values(formValidations).every(
      (isValid) => isValid
    );

    if (!isFormValid) {
      return;
    }

    const result = await authService.loginAsync(formData);

    if (result.isSuccess) {
      navigate(fromPage);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
    }
  };

  return (
    <div>
      <DefaultNavBar />
      <div
        className="container justify-content-center align-items-center"
        style={{ maxWidth: Constants.maxWidth }}
      >
        <div className="mt-5">
          <Spacing size={SpacingEnum.XXLarge} />
          <div className="text-center">
            <h2>
              {t("sign_in_to")} <span style={{ fontWeight: "400" }}>Gepek</span>
            </h2>
          </div>
          <Spacing size={SpacingEnum.XXLarge} />
          <div className="mt-5">
            <InputForm onSubmit={handleSubmit}>
              <TextInput
                name="email"
                title={t("email")}
                value={formData.email}
                onChange={handleInputChange}
                onValidate={(value: string) => {
                  if (!value) {
                    return t("please_enter_your_email");
                  } else if (!ValidationUtil.validateEmail(value)) {
                    return t("please_enter_a_valid_email");
                  }
                  return undefined;
                }}
                onValidationChange={handleValidationChange}
              />
              <Spacing size={SpacingEnum.Large} />
              <TextInput
                name="password"
                title={t("password")}
                type="password"
                value={formData.password}
                onChange={handleInputChange}
                onValidate={(value: string) => {
                  if (!value) {
                    return t("please_enter_your_password");
                  }
                  return undefined;
                }}
                onValidationChange={handleValidationChange}
              />
              <div className="d-flex justify-content-end">
                <CheckBoxInput
                  name="rememberMe"
                  title={t("remember_me")}
                  value={formData.rememberMe}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let value: boolean = event.target.checked;
                    setFormData({
                      ...formData,
                      rememberMe: value,
                    });
                  }}
                />
              </div>
              <Spacing size={SpacingEnum.XXLarge} />
              <DefaultButton
                type="submit"
                color={ColorEnum.Orange}
                disabled={
                  !Object.values(formValidations).every((isValid) => isValid)
                }
              >
                {t("login")}
              </DefaultButton>
            </InputForm>
            <Spacing size={SpacingEnum.Large} />
            <div className="text-center">
              <a href={PageRouteEnum.ResetPassword} className="text-info">
                {t("forgot_your_password")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <Spacing size={SpacingEnum.Giant} />
    </div>
  );
}
