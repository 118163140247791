import { useCallback, useEffect, useState } from "react";
import {
  CheckBoxInput,
  DateInput,
  DefaultButton,
  DropdownInput,
  InputForm,
  Loader,
  NavBar,
  Spacing,
  TextInput,
} from "../../components";
import {
  ColorEnum,
  Constants,
  DropdownItemModel,
  PackageModel,
  PackageOfferAutoAcceptPolicyModel,
  SpacingEnum,
} from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { PackageService } from "../../services";
import { MultiValue, SingleValue } from "react-select";
import { LanguageUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const packageService = new PackageService();

export default function PackageEditPage() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState<PackageModel>(new PackageModel());
  const [formValidations, setFormValidations] = useState({
    weight: true,
    size: true,
    type: true,
    pickUpName: true,
    pickUpPhoneNumber: true,
    addressOrigin: true,
    countryOrigin: true,
    postalCodeOrigin: true,
    townOrigin: true,
    addressDestination: true,
    countryDestination: true,
    postalCodeDestination: true,
    targetPrice: true,
    policyPrice: true,
    finalDeliveryDate: true,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(
      new PackageModel({ ...formData, [event.target.name]: event.target.value })
    );
  };

  const handleValidationChange = useCallback(
    (name: string, isValid: boolean) => {
      console.log(formValidations);
      setFormValidations((prevValidations) => ({
        ...prevValidations,
        [name]: isValid,
      }));
    },
    []
  );

  const handlePolicyInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const key =
      event.target.name === "policyPrice" ? "price" : event.target.name;
    setFormData(
      (prevFormData) =>
        new PackageModel({
          ...prevFormData,
          isAutoAcceptPolicyEnabled: true,
          packageOfferAutoAcceptPolicy: {
            ...prevFormData.packageOfferAutoAcceptPolicy,
            [key]: event.target.value,
          },
        })
    );
  };

  const checkIfFormValid = () => {
    console.log(formValidations);
    const isFormValid = Object.entries(formValidations).every(
      ([key, isValid]) => {
        if (
          [
            "policyPrice",
            "finalDeliveryDate",
            "minimalRating",
            "minimalNumberOfReviews",
          ].includes(key) &&
          !formData.isAutoAcceptPolicyEnabled
        ) {
          return true;
        }
        return isValid;
      }
    );
    return isFormValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = checkIfFormValid();

    if (!isFormValid) {
      return;
    }

    const result = await packageService.updateAsync(formData);

    if (result.isSuccess) {
      ToastUtil.showSuccessMessage(t("package_updated"));
      navigate(-1);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
    }
  };

  useEffect(() => {
    const getCompanyAsync = async () => {
      const response = await packageService.findByIdAsync(params.id ?? "");

      if (response.isSuccess) {
        setFormData(response.result!);
      }

      setIsLoading(false);
    };

    getCompanyAsync();
  }, [params.id]);

  return (
    <div>
      <NavBar />
      <div className="container mt-3" style={{ maxWidth: Constants.maxWidth }}>
        <Loader isLoading={isLoading}>
          <h2>{t("edit_package")}</h2>
          <p>{t("fields_with_are_mandatory")}</p>
          <InputForm onSubmit={handleSubmit}>
            <DateInput
              name="pickUpDate"
              title={t("pick_up_date")}
              value={formData.pickUpDate}
              onChange={handleInputChange}
            />
            <DropdownInput
              name="size"
              title={t("size") + "*"}
              value={
                (formData.size &&
                  new DropdownItemModel(formData.size, formData.size)) ||
                undefined
              }
              options={Constants.packageSizes.map(
                (size) => new DropdownItemModel(size, size)
              )}
              onChange={(
                result:
                  | MultiValue<DropdownItemModel>
                  | SingleValue<DropdownItemModel>
              ) => {
                setFormData({
                  ...formData,
                  size: result
                    ? ((result as SingleValue<DropdownItemModel>)
                        ?.value as string)
                    : undefined,
                });
              }}
              defaultErrorMessage={t("please_select_the_package_size")}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="weight"
              title={t("weight") + "*"}
              value={formData.weight}
              onChange={handleInputChange}
              type="number"
              step="01"
              unit="kg"
              defaultErrorMessage={t("please_enter_the_package_weight")}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="type"
              title={t("type") + "*"}
              value={formData.type}
              onChange={handleInputChange}
              defaultErrorMessage={t("please_enter_the_package_type")}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="targetPrice"
              title={t("target_price") + "*"}
              type="number"
              step="01"
              value={formData.targetPrice}
              unit="€"
              onChange={handleInputChange}
              defaultErrorMessage={t("please_enter_the_target_price")}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="additionalInfo"
              title={t("additional_info")}
              value={formData.additionalInfo}
              onChange={handleInputChange}
            />
            <TextInput
              name="pickUpName"
              title={t("pick_up_name") + "*"}
              value={formData.pickUpName}
              onChange={handleInputChange}
              onValidationChange={handleValidationChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_pickup_name");
                }
                return undefined;
              }}
            />
            <TextInput
              name="pickUpPhoneNumber"
              title={t("pick_up_phone_number") + "*"}
              value={formData.pickUpPhoneNumber}
              onChange={handleInputChange}
              onValidationChange={handleValidationChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_pick_up_phone_number");
                }
                return undefined;
              }}
            />
            <TextInput
              name="pickUpEmail"
              title={t("pick_up_email")}
              value={formData.pickUpEmail}
              onChange={handleInputChange}
            />
            <TextInput
              name="countryOrigin"
              title={t("origin_country") + "*"}
              value={formData.countryOrigin}
              onChange={handleInputChange}
              onValidationChange={handleValidationChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_origin_country");
                }
                return undefined;
              }}
            />
            <TextInput
              name="townOrigin"
              title={t("origin_town") + "*"}
              value={formData.townOrigin}
              onChange={handleInputChange}
              onValidationChange={handleValidationChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_origin_town");
                }
                return undefined;
              }}
            />
            <TextInput
              name="postalCodeOrigin"
              title={t("origin_postal_code") + "*"}
              value={formData.postalCodeOrigin}
              onChange={handleInputChange}
              onValidationChange={handleValidationChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_origin_postal_code");
                }
                return undefined;
              }}
            />
            <TextInput
              name="addressOrigin"
              title={t("origin_address") + "*"}
              value={formData.addressOrigin}
              onChange={handleInputChange}
              onValidationChange={handleValidationChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_origin_address");
                }
                return undefined;
              }}
            />
            <TextInput
              name="pickUpComments"
              title={t("pick_up_comments")}
              value={formData.pickUpComments}
              onChange={handleInputChange}
            />
            <TextInput
              name="dropOffName"
              title={t("drop_off_name")}
              value={formData.dropOffName}
              onChange={handleInputChange}
            />
            <TextInput
              name="dropOffPhoneNumber"
              title={t("drop_off_phone_number")}
              value={formData.dropOffPhoneNumber}
              onChange={handleInputChange}
            />
            <TextInput
              name="dropOffEmail"
              title={t("drop_off_email")}
              value={formData.dropOffEmail}
              onChange={handleInputChange}
            />
            <TextInput
              name="countryDestination"
              title={t("destination_country") + "*"}
              value={formData.countryDestination}
              onChange={handleInputChange}
              onValidationChange={handleValidationChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_destination_country");
                }
                return undefined;
              }}
            />
            <TextInput
              name="townDestination"
              title={t("destination_town") + "*"}
              value={formData.townDestination}
              onChange={handleInputChange}
              onValidationChange={handleValidationChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_destination_town");
                }
                return undefined;
              }}
            />
            <TextInput
              name="postalCodeDestination"
              title={t("destination_postal_code") + "*"}
              value={formData.postalCodeDestination}
              onChange={handleInputChange}
              onValidationChange={handleValidationChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_destination_postal_code");
                }
                return undefined;
              }}
            />
            <TextInput
              name="addressDestination"
              title={t("origin_address") + "*"}
              value={formData.addressDestination}
              onChange={handleInputChange}
              onValidationChange={handleValidationChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_origin_address");
                }
                return undefined;
              }}
            />
            <TextInput
              name="dropOffComments"
              title={t("drop_off_comments")}
              value={formData.dropOffComments}
              onChange={handleInputChange}
            />
            <CheckBoxInput
              title={t("enable_auto_accept_policy")}
              value={formData.isAutoAcceptPolicyEnabled}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let value: boolean = event.target.checked;
                if (value) {
                  setFormValidations({
                    ...formValidations,
                    policyPrice: false,
                    finalDeliveryDate: false,
                  });
                }
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  isAutoAcceptPolicyEnabled: value,
                  packageOfferAutoAcceptPolicy: value
                    ? new PackageOfferAutoAcceptPolicyModel({})
                    : undefined,
                }));
              }}
            />
            {formData.isAutoAcceptPolicyEnabled && (
              <>
                <TextInput
                  name="policyPrice"
                  title={t("price")}
                  type="number"
                  step="01"
                  value={formData.packageOfferAutoAcceptPolicy?.price}
                  onChange={handlePolicyInputChange}
                  onValidationChange={handleValidationChange}
                  onValidate={(value: string) => {
                    if (!value) {
                      return t("please_enter_the_policy_price");
                    } else if (+value < 0) {
                      return t("the_value_cant_be_negative");
                    } else if (+value > (formData.targetPrice || 0)) {
                      return t(
                        "policy_price_cant_be_greater_than_target_price"
                      );
                    }

                    return undefined;
                  }}
                />
                <DateInput
                  name="finalDeliveryDate"
                  title={t("final_delivery_date")}
                  value={
                    formData.packageOfferAutoAcceptPolicy?.finalDeliveryDate
                  }
                  onChange={handlePolicyInputChange}
                  onValidationChange={handleValidationChange}
                  minDate={new Date()}
                  onValidate={(value: string) => {
                    if (!value) {
                      return t("please_select_the_final_delivery_date");
                    }
                    return undefined;
                  }}
                />
                <TextInput
                  name="minimalRating"
                  title={t("minimal_rating")}
                  type="number"
                  step="01"
                  minValue={0}
                  value={formData.packageOfferAutoAcceptPolicy?.minimalRating}
                  onChange={handlePolicyInputChange}
                  onValidationChange={handleValidationChange}
                  onValidate={(value: string) => {
                    if (+value > 5 || +value < 0) {
                      return t("minimal_rating_has_to_be_between_0_and_5");
                    }

                    return undefined;
                  }}
                />
                <TextInput
                  name="minimalNumberOfReviews"
                  title={t("minimal_number_of_reviews")}
                  type="number"
                  step="01"
                  minValue={0}
                  value={
                    formData.packageOfferAutoAcceptPolicy
                      ?.minimalNumberOfReviews
                  }
                  onChange={handlePolicyInputChange}
                  onValidationChange={handleValidationChange}
                  onValidate={(value: string) => {
                    if (+value < 0) {
                      return t("the_value_cant_be_negative");
                    }

                    return undefined;
                  }}
                />
              </>
            )}
            <Spacing size={SpacingEnum.XXLarge} />
            <DefaultButton
              type="submit"
              disabled={!checkIfFormValid()}
              color={ColorEnum.Orange}
            >
              {t("save_changes")}
            </DefaultButton>
          </InputForm>
        </Loader>
        <Spacing size={SpacingEnum.XLarge} />
      </div>
    </div>
  );
}
