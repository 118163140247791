import { useNavigate } from "react-router-dom";
import { ColorEnum, PageRouteEnum, RouteModel } from "../../types";
import { DefaultButton, TableComponent } from "../common";
import { DateTimeUtil } from "../../utils";
import { useTranslation } from "react-i18next";

export default function SenderRouteTableComponent({
  items,
}: {
  items: RouteModel[];
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-2">
            {t("origin")}
          </th>
          <th scope="col" className="col-2">
            {t("destination")}
          </th>
          <th scope="col" className="col-1">
            {t("drive_starting")}
          </th>
          <th scope="col" className="col-1">
            {t("drive_ending")}
          </th>
          <th scope="col" className="col-2">
            {t("vehicle")}
          </th>
          <th scope="col" className="col-2">
            {t("driver")}
          </th>
          <th scope="col" className="col-2">
            {t("actions")}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((model) => (
          <tr key={model.id}>
            <td>{model.origin}</td>
            <td>{model.destination}</td>
            <td>{DateTimeUtil.dateTimeFormat(model.driveStarting)}</td>
            <td>{DateTimeUtil.dateTimeFormat(model.driveEnding)}</td>
            <td>{model.vehicle?.toString()}</td>
            <td>{model.driver?.name}</td>
            <td>
              <div className="btn-group gap-1">
                <DefaultButton
                  width={80}
                  onClick={(_) =>
                    navigate(
                      PageRouteEnum.RouteDetails.replace(":id", model.id || "")
                    )
                  }
                >
                  {t("details")}
                </DefaultButton>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
}
