export class DateTimeUtil {
  static dateTimeFormat(
    date?: Date,
    defaultValue: string = "Not specified"
  ): string {
    if (!date) {
      return defaultValue;
    }

    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  }

  static dateFormat(
    date?: Date,
    defaultValue: string = "Not specified"
  ): string {
    if (!date) {
      return defaultValue;
    }

    return date.toLocaleDateString();
  }
}
