import { BaseFilter } from "./BaseFilter";

export class VehicleFilter extends BaseFilter {
  companyId?: string;
  userId?: string;

  constructor({ companyId, userId, ...other }: VehicleFilter) {
    super(other);
    this.companyId = companyId;
    this.userId = userId;
  }
}
