export class VehicleModel {
  id?: string;
  companyId?: string;
  userId?: string;
  type?: string;
  brand?: string;
  model?: string;
  color?: string;
  registration?: string;
  allowedPackages?: string[];

  constructor({
    id,
    companyId,
    userId,
    type,
    brand,
    model,
    color,
    registration,
    allowedPackages,
  }: VehicleModel = {}) {
    this.id = id;
    this.companyId = companyId;
    this.userId = userId;
    this.type = type;
    this.brand = brand;
    this.model = model;
    this.color = color;
    this.registration = registration;
    this.allowedPackages = allowedPackages;
  }

  static fromJson(data: any): VehicleModel {
    return new VehicleModel({
      id: data["id"],
      companyId: data["companyId"],
      userId: data["userId"],
      type: data["type"],
      brand: data["brand"],
      model: data["model"],
      color: data["color"],
      registration: data["registration"],
      allowedPackages: data["allowedPackages"],
    });
  }

  static dropdownValue(model: VehicleModel): string {
    return model.brand + " " + model.model + " (" + model.registration + ")";
  }

  toString(): string {
    return this.brand + " " + this.model + " (" + this.registration + ")";
  }
}
