import { CoordinatesModel } from "./CoordinatesModel";

export class PackageMapModel {
  id: string;
  coordinatesOrigin: CoordinatesModel;
  coordinatesDestination: CoordinatesModel;

  constructor({
    id,
    coordinatesOrigin,
    coordinatesDestination,
  }: {
    id: string;
    coordinatesOrigin: CoordinatesModel;
    coordinatesDestination: CoordinatesModel;
  }) {
    this.id = id;
    this.coordinatesOrigin = coordinatesOrigin;
    this.coordinatesDestination = coordinatesDestination;
  }

  static fromJson(data: any): PackageMapModel {
    return new PackageMapModel({
      id: data["id"],
      coordinatesOrigin: CoordinatesModel.fromJson(data["coordinatesOrigin"]),
      coordinatesDestination: CoordinatesModel.fromJson(
        data["coordinatesDestination"]
      ),
    });
  }
}
