import { ChangeEventHandler } from "react";

export default function CheckBoxInput({
  title = undefined,
  name,
  value,
  onChange,
}: {
  title: string;
  name?: string;
  value: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
} & any) {
  return (
    <div className="form-group form-check mb-3 mt-3">
      <input
        className="form-check-input"
        type="checkbox"
        id={name}
        name={name}
        checked={value || false}
        onChange={onChange}
      />
      <label htmlFor={name} className="form-check-label">
        {title}
      </label>
    </div>
  );
}
