export enum ColorEnum {
  Blue = "#5eaadc",
  Orange = "#f47e40",
  Green = "#8dbf44",
  Red = "#dc3545",
  Grey = "#343a40",
  DarkBlue = "#23272b",
  White = "#ffffff",
  Black = "#000000",
  ControlBackground = "black",
  ControlText = "white",
  PlaceHolderText = "#ccc",
}
