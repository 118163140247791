import { useEffect, useState } from "react";
import {
  DefaultButton,
  DropdownInput,
  InputForm,
  Loader,
  NavBar,
  Spacing,
  TextInput,
} from "../../components";
import {
  Constants,
  PageRouteEnum,
  ChangePasswordModel,
  SpacingEnum,
  UpdateUserModel,
  UserModel,
  ColorEnum,
  DropdownItemModel,
} from "../../types";
import { AuthService, UserDataService, UserService } from "../../services";
import { useNavigate } from "react-router-dom";
import { LanguageUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";
import { MultiValue, SingleValue } from "react-select";

const userService = new UserService();
const authService = new AuthService();

export default function AccountPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState<UserModel>({});
  const [profileFormData, setProfileFormData] = useState<UpdateUserModel>({
    language: UserDataService.getLanguage(),
  });
  const [passwordFormData, setPasswordFormData] = useState<ChangePasswordModel>(
    {}
  );
  const [isLoading, setIsLoading] = useState(true);

  const handleProfileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProfileFormData({
      ...profileFormData,
      [event.target.name]: event.target.value,
    });
  };

  const handlePasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordFormData({
      ...passwordFormData,
      [event.target.name]: event.target.value,
    });
  };

  const handleProfileUpdate = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const result = await userService.updateAsync(user.id!, profileFormData);

    if (result.isSuccess) {
      ToastUtil.showSuccessMessage(t("profile_info_updated"));
      if (profileFormData.language !== UserDataService.getLanguage()) {
        setIsLoading(true);
        UserDataService.setLanguage(profileFormData.language!);
        setIsLoading(false);
      }
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
    }
  };

  const handlePasswordChange = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    passwordFormData.userId = user.id!;

    const result = await authService.changePasswordAsync(passwordFormData);

    if (result.isSuccess) {
      setPasswordFormData({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      });
      ToastUtil.showSuccessMessage(t("password_changed"));
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
    }
  };

  useEffect(() => {
    const getUserByIdAsync = async () => {
      const response = await userService.findByIdAsync(
        UserDataService.getUserId()!
      );

      if (response.isSuccess && response.result) {
        setUser(response.result);
        setProfileFormData(response.result!);
      } else {
        ToastUtil.showErrorMessage(
          LanguageUtil.translateErrorMessage(t, response.errorMessage)
        );
      }
      setIsLoading(false);
    };

    getUserByIdAsync();
  }, [t]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div
          className="container mt-3"
          style={{ maxWidth: Constants.maxWidth }}
        >
          <div className="d-flex justify-content-between align-items-center ">
            <h2 style={{ color: ColorEnum.Orange }}>{t("account")}</h2>
            <div className="btn-group gap-1">
              <DefaultButton
                type="button"
                width={180}
                color={ColorEnum.Red}
                onClick={async () => {
                  const result = await authService.logoutAsync();

                  if (result.isSuccess) {
                    navigate(PageRouteEnum.Login);
                  } else {
                    ToastUtil.showErrorMessage(t("failed_to_logout"));
                  }
                }}
              >
                {t("logout")}
              </DefaultButton>
            </div>
          </div>
          <div className="mt-5">
            <InputForm onSubmit={handleProfileUpdate}>
              <h5 style={{ color: ColorEnum.Orange }}>{t("profile")}</h5>
              <DropdownInput
                name="language"
                title={t("language")}
                value={LanguageUtil.getSelectedLanguageDropdown(
                  profileFormData.language!
                )}
                options={LanguageUtil.getLanguagesDropdown()}
                onChange={(
                  value:
                    | MultiValue<DropdownItemModel>
                    | SingleValue<DropdownItemModel>
                ) => {
                  const selectedValue = value as SingleValue<DropdownItemModel>;
                  setProfileFormData({
                    ...profileFormData,
                    language: selectedValue?.value as string,
                  });
                }}
              />
              <TextInput
                name="name"
                title={t("name")}
                value={profileFormData.name}
                onChange={handleProfileInputChange}
              />
              <TextInput
                name="phoneNumber"
                title={t("phone_number")}
                value={profileFormData.phoneNumber}
                onChange={handleProfileInputChange}
              />
              <TextInput
                name="address"
                title={t("address")}
                value={profileFormData.address}
                onChange={handleProfileInputChange}
              />
              <DefaultButton width={180} type="submit" color={ColorEnum.Orange}>
                {t("update_info")}
              </DefaultButton>
            </InputForm>
          </div>
          <div className="mt-5">
            <InputForm onSubmit={handlePasswordChange}>
              <h5 style={{ color: ColorEnum.Orange }}>
                {t("change_password")}
              </h5>
              <TextInput
                name="oldPassword"
                title={t("old_password")}
                type="password"
                value={passwordFormData.oldPassword}
                onChange={handlePasswordInputChange}
              />
              <TextInput
                name="newPassword"
                title={t("new_password")}
                type="password"
                value={passwordFormData.newPassword}
                onChange={handlePasswordInputChange}
              />
              <TextInput
                name="confirmNewPassword"
                title={t("confirm_new_password")}
                type="password"
                value={passwordFormData.confirmNewPassword}
                onChange={handlePasswordInputChange}
              />
              <DefaultButton width={180} type="submit" color={ColorEnum.Orange}>
                {t("change_password")}
              </DefaultButton>
            </InputForm>
          </div>
        </div>
      </Loader>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
