import axios from "axios";
import { DOTNET_API_URL } from "../config";
import {
  HttpHeaders,
  MatchmakingFilter,
  MatchmakingModel,
  PackageDelivererMatchmakingFilter,
  PackageDelivererMatchmakingModel,
  PackageMatchmakingFilter,
  PagedList,
  ResponseModel,
  RouteMatchmakingModel,
} from "../types";

const URL_PREFIX = DOTNET_API_URL + `/matchmaking`;

export class MatchmakingService {
  async addAsync(model: {
    packageId: string;
    routeId: string;
  }): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.post(URL_PREFIX, model, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      return ResponseModel.success();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }

  async getPackagesMatchedWithDelivererAsync(
    filter: PackageDelivererMatchmakingFilter
  ): Promise<ResponseModel<PagedList<PackageDelivererMatchmakingModel>>> {
    const params = new URLSearchParams();

    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        params.append(key, value.toString());
      }
    });

    try {
      const response = await axios.get(
        URL_PREFIX + `/package-deliverer?${params}`,
        {
          headers: HttpHeaders.get(),
        }
      );

      if (response.status !== 200) return ResponseModel.failure();

      const result = PagedList.fromJson<PackageDelivererMatchmakingModel>(
        response.data,
        PackageDelivererMatchmakingModel
      );

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async getRoutesMatchedWithPackage(
    filter: PackageMatchmakingFilter
  ): Promise<ResponseModel<PagedList<RouteMatchmakingModel>>> {
    try {
      const params = new URLSearchParams();

      Object.entries(filter).forEach(([key, value]) => {
        if (value) {
          params.append(key, value.toString());
        }
      });

      const response = await axios.get(URL_PREFIX + `/package?${params}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PagedList.fromJson<RouteMatchmakingModel>(
        response.data,
        RouteMatchmakingModel
      );

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async getRouteRequestsAsync(
    filter: MatchmakingFilter
  ): Promise<ResponseModel<PagedList<MatchmakingModel>>> {
    const params = new URLSearchParams();

    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        params.append(key, value.toString());
      }
    });

    try {
      const response = await axios.get(
        URL_PREFIX + `/route-requests?${params}`,
        {
          headers: HttpHeaders.get(),
        }
      );

      if (response.status !== 200) return ResponseModel.failure();

      const result = PagedList.fromJson<MatchmakingModel>(
        response.data,
        MatchmakingModel
      );

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }
}
