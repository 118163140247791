import { useTranslation } from "react-i18next";
import {
  Loader,
  NavBar,
  NoItemsComponent,
  PaginationComponent,
  RouteRequestsTableComponent,
  Spacing,
} from "../../components";
import { MatchmakingService } from "../../services";
import {
  PackageMatchmakingFilter,
  PagedList,
  RouteMatchmakingModel,
  SpacingEnum,
} from "../../types";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const matchmakingService = new MatchmakingService();

export default function RouteRequestsPage() {
  const params = useParams();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<PackageMatchmakingFilter>({
    packageId: params.packageId,
    pageNumber: 1,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pagedRoutes, setPagedRoutes] = useState<
    PagedList<RouteMatchmakingModel>
  >({});

  useEffect(() => {
    const fetchPackages = async () => {
      var response = await matchmakingService.getRoutesMatchedWithPackage(
        filter
      );

      if (response.isSuccess && response.result) {
        setPagedRoutes(response.result);
      }

      setIsLoading(false);
    };

    fetchPackages();
  }, [filter, params.packageId]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <h2>{t("routes_matching_with_package")}</h2>{" "}
          <Spacing size={SpacingEnum.Large} />
          <RouteRequestsTableComponent
            items={pagedRoutes?.items || []}
            onRequestSent={(model) => {
              setFilter({ ...filter });
            }}
          />
          {(pagedRoutes?.items || []).length === 0 && <NoItemsComponent />}
          <PaginationComponent
            onPageChange={(page) => {
              setFilter({
                ...filter,
                pageNumber: page,
              });
            }}
            currentPage={pagedRoutes?.pageNumber || 1}
            pageCount={pagedRoutes?.lastPage || 1}
          />
        </div>
      </Loader>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
