import { CoordinatesModel } from "./CoordinatesModel";
import { UserRatingModel } from "./UserRatingModel";

export class RouteMatchmakingModel {
  id?: string;
  user?: UserRatingModel;
  vehicleId?: string;
  origin?: string;
  destination?: string;
  driveStarting?: Date;
  driveEnding?: Date;
  price?: number;
  avoidTools?: boolean;
  vias?: string[];
  isBus?: string;
  coordinatesOrigin?: CoordinatesModel;
  coordinatesDestination?: CoordinatesModel;
  isPackageRequestSent?: boolean;

  constructor({
    id,
    user,
    vehicleId,
    origin,
    destination,
    driveStarting,
    driveEnding,
    price,
    avoidTools,
    vias,
    isBus,
    coordinatesOrigin,
    coordinatesDestination,
    isPackageRequestSent,
  }: RouteMatchmakingModel) {
    this.id = id;
    this.user = user;
    this.vehicleId = vehicleId;
    this.origin = origin;
    this.destination = destination;
    this.driveStarting = driveStarting;
    this.driveEnding = driveEnding;
    this.price = price;
    this.avoidTools = avoidTools;
    this.vias = vias;
    this.isBus = isBus;
    this.coordinatesOrigin = coordinatesOrigin;
    this.coordinatesDestination = coordinatesDestination;
    this.isPackageRequestSent = isPackageRequestSent;
  }

  static fromJson(data: any): RouteMatchmakingModel {
    return new RouteMatchmakingModel({
      id: data["id"],
      user: data["user"],
      vehicleId: data["vehicleId"],
      origin: data["origin"],
      destination: data["destination"],
      driveStarting: data["driveStarting"] && new Date(data["driveStarting"]),
      driveEnding: data["driveEnding"] && new Date(data["driveEnding"]),
      price: data["price"],
      avoidTools: data["avoidTools"],
      vias: data["vias"],
      isBus: data["isBus"],
      coordinatesOrigin: CoordinatesModel.fromJson(data["coordinatesOrigin"]),
      coordinatesDestination: CoordinatesModel.fromJson(
        data["coordinatesDestination"]
      ),
      isPackageRequestSent: data["isPackageRequestSent"],
    });
  }
}
