import { useEffect, useState } from "react";
import {
  DefaultButton,
  EmployeeProfileTableComponent,
  Loader,
  ModalComponent,
  NavBar,
  Spacing,
} from "../../components";
import {
  ColorEnum,
  CompanyEmployeeModel,
  RoleEnum,
  SpacingEnum,
} from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyEmployeeService, UserDataService } from "../../services";
import { LanguageUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const companyEmployeeService = new CompanyEmployeeService();

export default function EmployeeProfilePage() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [model, setModel] = useState(new CompanyEmployeeModel());
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const deleteCompanyEmployeeAsync = async () => {
    const response = await companyEmployeeService.deleteAsync(
      model.userId!,
      model.companyId!
    );

    if (response.isSuccess) {
      ToastUtil.showSuccessMessage(t("employee_removed"));
      navigate(-1);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, response.errorMessage)
      );
    }
  };

  useEffect(() => {
    const getCompanyEmployeeAsync = async () => {
      const response = await companyEmployeeService.getByUserIdAsync(
        params.id || ""
      );

      if (response.isSuccess) {
        setModel(response.result!);
      }

      setIsLoading(false);
    };

    getCompanyEmployeeAsync();
  }, [params.id]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="d-flex gap-2 align-items-center ">
              <img src="/logo192.png" width={64} height={64} alt="avatar" />
              <h4>{model.name}</h4>
              <h5>{4.8}</h5>
            </div>
            <div>
              {(UserDataService.isInRole(RoleEnum.SuperAdmin) ||
                (model.companyId === UserDataService.getCompanyId() &&
                  (UserDataService.isInRole(RoleEnum.DeliveryBusinessAdmin) ||
                    UserDataService.isInRole(
                      RoleEnum.SenderBusinessAdmin
                    )))) && (
                <>
                  <DefaultButton
                    width={200}
                    color={ColorEnum.Red}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {t("remove_employee")}
                  </DefaultButton>
                </>
              )}
            </div>
          </div>
          <EmployeeProfileTableComponent model={model} />
        </div>
      </Loader>
      <ModalComponent
        title={t("confirm_remove")}
        show={showModal}
        onShowChange={setShowModal}
        onConfirm={deleteCompanyEmployeeAsync}
      >
        <p>{t("employee_confirm_remove")}</p>
      </ModalComponent>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
