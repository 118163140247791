export enum RoleEnum {
  SuperAdmin = "super_admin",
  DeliveryBusinessAdmin = "delivery_business_admin",
  SenderBusinessAdmin = "sender_business_admin",
  BusinessSender = "business_sender",
}

export const DeliveryRoleEnums: RoleEnum[] = [
  RoleEnum.SuperAdmin,
  RoleEnum.DeliveryBusinessAdmin,
];

export const SenderRoleEnums: RoleEnum[] = [
  RoleEnum.SuperAdmin,
  RoleEnum.SenderBusinessAdmin,
  RoleEnum.BusinessSender,
];

export const AdminRoleEnums: RoleEnum[] = [
  RoleEnum.SuperAdmin,
  RoleEnum.SenderBusinessAdmin,
  RoleEnum.DeliveryBusinessAdmin,
];

export const AllRoleEnums: RoleEnum[] = [
  RoleEnum.SuperAdmin,
  RoleEnum.DeliveryBusinessAdmin,
  RoleEnum.SenderBusinessAdmin,
  RoleEnum.BusinessSender,
];
