import { useEffect, useState } from "react";
import {
  BasicLoader,
  DateInput,
  DefaultButton,
  SidePanelComponent,
  Spacing,
  TableComponent,
  TextInput,
} from "../common";
import {
  PackageOfferService,
  PackageService,
  UserDataService,
} from "../../services";
import { ColorEnum, PackageWithOfferModel, SpacingEnum } from "../../types";
import PackageDetailsTableComponent from "./PackageDetailsTableComponent";
import { DateTimeUtil, LanguageUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const packageService = new PackageService();
const packageOfferService = new PackageOfferService();

export default function PackageOfferSidePanelComponent({
  packageId,
  showPanel,
  onShowChange,
}: {
  packageId: string;
  showPanel: boolean;
  onShowChange: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const [customPrice, setCustomPrice] = useState<number>();
  const [plannedDeliveryDate, setPlannedDeliveryDate] = useState<Date>();
  const [isLoading, setIsLoading] = useState(true);
  const [packageModel, setPackageModel] = useState<PackageWithOfferModel>({});

  useEffect(() => {
    const fetchPackage = async () => {
      const response = await packageService.getPackageWithOfferAsync({
        packageId,
        companyId: UserDataService.getCompanyId() || "",
      });

      if (response.isSuccess && response.result) {
        setPackageModel(response.result);
        setCustomPrice(
          response.result.offerPrice || response.result.targetPrice || 0
        );
        setPlannedDeliveryDate(
          response.result.offerPlannedDeliveryDate || new Date()
        );
      }

      setIsLoading(false);
    };

    fetchPackage();
  }, [packageId]);

  const handleOfferDelete = async () => {
    const response = await packageOfferService.deleteAsync(
      packageModel.offerId!
    );

    if (response.isSuccess) {
      ToastUtil.showSuccessMessage(t("offer_deleted"));
      onShowChange(false);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, response.errorMessage)
      );
    }
  };

  const handleOfferSubmit = async () => {
    const response = await packageOfferService.createAsync({
      plannedDeliveryDate: plannedDeliveryDate,
      price: customPrice || 0,
      packageId: packageModel.id,
      companyId: UserDataService.getCompanyId() || undefined,
    });

    if (response.isSuccess) {
      ToastUtil.showSuccessMessage(t("offer_sent"));
      onShowChange(false);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, response.errorMessage)
      );
    }
  };

  return (
    <SidePanelComponent
      show={showPanel}
      showChange={onShowChange}
      width="450px"
    >
      <div>
        <div className="d-flex justify-content-between align-items-baseline ">
          <div className="d-flex align-items-baseline">
            <button
              type="button"
              className="btn-close"
              aria-label={t("close")}
              onClick={() => onShowChange(false)}
              style={{ marginRight: "10px" }}
            ></button>
            <h3 style={{ color: ColorEnum.Orange }}>{t("offer_price")}</h3>
          </div>
        </div>
        {isLoading && (
          <div className="d-flex justify-content-center align-items-center flex-grow-1 vh-90">
            <BasicLoader />
          </div>
        )}
        {!isLoading && (
          <>
            <PackageDetailsTableComponent
              model={packageModel}
              showFullInfo={false}
            />
            {packageModel.offerId && (
              <>
                <h5 style={{ color: ColorEnum.Orange }}>{t("offer_info")}</h5>
                <TableComponent>
                  <thead>
                    <tr>
                      <th scope="col" className="col-3"></th>
                      <th scope="col" className="col-9"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("delivery_date")}</td>
                      <td>
                        {DateTimeUtil.dateFormat(
                          packageModel.offerPlannedDeliveryDate
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("price")}</td>
                      <td>{packageModel.offerPrice?.toFixed(2)}€</td>
                    </tr>
                  </tbody>
                </TableComponent>
                <DefaultButton
                  type="button"
                  color={ColorEnum.Red}
                  onClick={handleOfferDelete}
                >
                  {t("remove")}
                </DefaultButton>
                <Spacing size={SpacingEnum.Medium} />
              </>
            )}
            {!packageModel.offerId && (
              <>
                <h5 style={{ color: ColorEnum.Orange }}>Offer info</h5>
                <DateInput
                  name="plannedDeliveryDate"
                  title={t("planned_delivery_date")}
                  value={plannedDeliveryDate}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPlannedDeliveryDate(
                      event.target.valueAsDate || new Date()
                    )
                  }
                />
                <TextInput
                  name="offerPrice"
                  title={t("i_am_offering_this_price_for_delivery") + ""}
                  value={customPrice}
                  minValue={0}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setCustomPrice(+event.target.value)
                  }
                  type="number"
                  unit="€"
                />
                <DefaultButton
                  onClick={() => {
                    handleOfferSubmit();
                  }}
                  color={ColorEnum.Orange}
                >
                  {t("send_offer")}
                </DefaultButton>
                <Spacing size={SpacingEnum.Medium} />
              </>
            )}
          </>
        )}
      </div>
    </SidePanelComponent>
  );
}
