export class DelivererPackageOfferModel {
  id?: string;
  price?: number;
  plannedDeliveryDate?: Date;
  packageId?: string;
  origin?: string;
  destination?: string;
  isSelected?: boolean;

  constructor({
    id,
    price,
    plannedDeliveryDate,
    packageId,
    origin,
    destination,
    isSelected,
  }: DelivererPackageOfferModel = {}) {
    this.id = id;
    this.price = price;
    this.plannedDeliveryDate = plannedDeliveryDate;
    this.packageId = packageId;
    this.origin = origin;
    this.destination = destination;
    this.isSelected = isSelected;
  }

  static fromJson(data: any): DelivererPackageOfferModel {
    return new DelivererPackageOfferModel({
      id: data["id"],
      price: data["price"],
      plannedDeliveryDate:
        data["plannedDeliveryDate"] && new Date(data["plannedDeliveryDate"]),
      packageId: data["packageId"],
      origin: data["origin"],
      destination: data["destination"],
      isSelected: data["isSelected"],
    });
  }
}
