import Spacing from "./Spacing";
import { SpacingEnum } from "../../types";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export default function Loader({
  children,
  isLoading,
}: {
  children: ReactNode;
  isLoading: boolean;
}) {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 9999,
        }}
      >
        <div className="text-center">
          <img alt="Loading" src="/images/loading.png" />
          <Spacing size={SpacingEnum.Medium} />
          <div
            className="spinner-border"
            role="status"
            style={{ width: "3rem", height: "3rem", color: "#F47E40" }}
          >
            <span className="visually-hidden">{t("loading")}...</span>
          </div>
        </div>
      </div>
    );
  }

  return <>{children}</>;
}
