import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { GOOGLE_MAPS_KEY } from "../../config";
import Spacing from "./Spacing";
import { ColorEnum, SpacingEnum } from "../../types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const loadGoogleMapsScript = (callback: () => void): void => {
  if (typeof window !== "undefined" && (window as any).google) {
    callback();
  } else {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => callback();
    document.body.appendChild(script);
  }
};

export default function AddressInput({
  name,
  initialValue,
  required = true,
  title,
  width,
  defaultErrorMessage,
  onChange,
  onValidationChange,
}: {
  name: string;
  initialValue: string;
  required: boolean;
  placeholder: string;
  title: string;
  width: number;
  defaultErrorMessage?: string;
  onChange: (value: string) => void;
  onValidationChange?: (name: string, isValid: boolean) => void;
} & any) {
  const { t } = useTranslation();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [inputValue, setInputValue] = useState(initialValue || "");
  const [initialInputValue, setInitialInputValue] = useState(
    initialValue || ""
  );

  useEffect(() => {
    loadGoogleMapsScript(() => setScriptLoaded(true));
    const isFormInvalid = !initialInputValue;
    setIsValid(!isFormInvalid);
    if (onValidationChange) onValidationChange(name, true);
  }, [initialInputValue, name, onValidationChange]);

  return (
    <div
      className="form-group mb-3 mt-3"
      style={{ maxWidth: width, width: "100%" }}
    >
      {title && (
        <>
          <label htmlFor={title} className="form-label">
            {title}
          </label>
          <Spacing size={SpacingEnum.Small} />
        </>
      )}
      {scriptLoaded && (
        <GooglePlacesAutocomplete
          apiKey={GOOGLE_MAPS_KEY}
          apiOptions={{
            language: "en",
            libraries: ["places"],
            url: "https://maps.googleapis.com/maps/api/js",
            apiKey: GOOGLE_MAPS_KEY,
          }}
          minLengthAutocomplete={2}
          selectProps={{
            placeholder: t("enter") + "...",
            onInputChange: (_, action) => {
              if (action.action === "input-change") {
                setInputValue(undefined);
              }
            },
            onChange: (value, action) => {
              if (
                action.action === "clear" ||
                action.action === "select-option"
              ) {
                const newValue = value?.label || "";
                onChange(newValue);
                setInputValue(undefined);
                setInitialInputValue(newValue);
              }
            },
            onFocus: () => {
              if (!isTouched) {
                setIsTouched(true);
              }
            },
            className: `bg-dark text-white ${
              isTouched && !isValid ? "is-invalid" : ""
            }`,
            styles: {
              control: (styles, { isFocused }) => ({
                ...styles,
                backgroundColor: ColorEnum.ControlBackground,
                color: ColorEnum.ControlText,
                boxShadow: "none",
                border: isFocused || (isTouched && !isValid) ? "1" : "none",
                borderColor:
                  isTouched && !isValid ? ColorEnum.Red : ColorEnum.Blue,
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                backgroundColor: isSelected
                  ? ColorEnum.Grey
                  : isFocused
                  ? ColorEnum.DarkBlue
                  : ColorEnum.Black,
                color: ColorEnum.White,
                cursor: "pointer",
                border: "none",
              }),
              input: (styles) => ({
                ...styles,
                color: ColorEnum.ControlText,
              }),
              placeholder: (styles) => ({
                ...styles,
                color: ColorEnum.PlaceHolderText,
              }),
              singleValue: (styles) => ({
                ...styles,
                color: ColorEnum.ControlText,
              }),
              menu: (styles) => ({
                ...styles,
                backgroundColor: ColorEnum.ControlBackground,
                color: ColorEnum.ControlText,
                borderRadius: "0.25rem",
                marginTop: "0.1rem",
              }),
              menuList: (styles) => ({
                ...styles,
                backgroundColor: ColorEnum.ControlBackground,
                color: ColorEnum.ControlText,
              }),
            },
            defaultInputValue: initialInputValue,
            inputValue: inputValue,
            controlShouldRenderValue: true,
            closeMenuOnSelect: true,
            isSearchable: true,
            isClearable: true,
            captureMenuScroll: true,
            required: !inputValue && required,
            blurInputOnSelect: true,
            openMenuOnFocus: true,
          }}
        />
      )}
      {isTouched && (
        <div className="invalid-feedback">{defaultErrorMessage}</div>
      )}
    </div>
  );
}
