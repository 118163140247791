export class PackageStatisticsModel {
  id?: string;
  origin?: string;
  destination?: string;

  constructor({ id, origin, destination }: PackageStatisticsModel) {
    this.id = id;
    this.origin = origin;
    this.destination = destination;
  }

  static fromJson(data: any): PackageStatisticsModel {
    return new PackageStatisticsModel({
      id: data["id"],
      origin: data["origin"],
      destination: data["destination"],
    });
  }
}
