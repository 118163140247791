import { DelivererModel } from "./DelivererModel";

export class PackageOfferModel {
  packageOfferId?: string;
  packageId?: string;
  companyId?: string;
  price?: number;
  plannedDeliveryDate?: Date;
  isSelected?: boolean;
  delivererInfo?: DelivererModel;

  constructor({
    packageOfferId,
    packageId,
    companyId,
    price,
    plannedDeliveryDate,
    delivererInfo,
    isSelected,
  }: PackageOfferModel = {}) {
    this.packageId = packageId;
    this.companyId = companyId;
    this.isSelected = isSelected;
    this.packageOfferId = packageOfferId;
    this.plannedDeliveryDate = plannedDeliveryDate;
    this.price = price;
    this.delivererInfo = delivererInfo;
  }

  static fromJson(data: any): PackageOfferModel {
    return new PackageOfferModel({
      packageOfferId: data["packageOfferId"],
      packageId: data["packageId"],
      companyId: data["companyId"],
      price: data["price"],
      plannedDeliveryDate:
        data["plannedDeliveryDate"] && new Date(data["plannedDeliveryDate"]),
      isSelected: data["isSelected"],
      delivererInfo:
        data["delivererInfo"] && DelivererModel.fromJson(data["delivererInfo"]),
    });
  }
}
