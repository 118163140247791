import { CoordinatesModel } from "./CoordinatesModel";

export class PackageDelivererMatchmakingModel {
  id?: string;
  origin?: string;
  destination?: string;
  sender?: string;
  coordinatesOrigin?: CoordinatesModel;
  coordinatesDestination?: CoordinatesModel;

  constructor({
    id,
    origin,
    destination,
    sender,
    coordinatesOrigin,
    coordinatesDestination,
  }: PackageDelivererMatchmakingModel = {}) {
    this.id = id;
    this.origin = origin;
    this.destination = destination;
    this.sender = sender;
    this.coordinatesOrigin = coordinatesOrigin;
    this.coordinatesDestination = coordinatesDestination;
  }

  static fromJson(data: any): PackageDelivererMatchmakingModel {
    return new PackageDelivererMatchmakingModel({
      id: data["id"],
      origin: data["origin"],
      destination: data["destination"],
      sender: data["sender"],
      coordinatesOrigin:
        data["coordinatesOrigin"] &&
        CoordinatesModel.fromJson(data["coordinatesOrigin"]),
      coordinatesDestination:
        data["coordinatesDestination"] &&
        CoordinatesModel.fromJson(data["coordinatesDestination"]),
    });
  }
}
