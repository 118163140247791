import { useTranslation } from "react-i18next";
import { ColorEnum, PageRouteEnum, VehicleModel } from "../../types";
import { DefaultButton, TableComponent } from "../common";
import { useNavigate } from "react-router-dom";

export default function VehiclesTableComponent({
  items,
}: {
  items: VehicleModel[];
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col-3" className="col-3">
            {t("model")}
          </th>
          <th scope="col-3" className="col-3">
            {t("type")}
          </th>
          <th scope="col-3" className="col-3">
            {t("registration")}
          </th>
          <th scope="col-3" className="col-3">
            {t("actions")}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.id}>
            <td>{item.model}</td>
            <td>{item.type}</td>
            <td>{item.registration}</td>
            <td>
              <div className="btn-group gap-1">
                <DefaultButton
                  width={80}
                  color={ColorEnum.Grey}
                  onClick={() => {
                    navigate(
                      PageRouteEnum.VehicleEdit.replace(":id", item.id || "")
                    );
                  }}
                >
                  {t("edit")}
                </DefaultButton>
                <DefaultButton
                  width={80}
                  onClick={() => {
                    navigate(
                      PageRouteEnum.VehicleDetails.replace(":id", item.id || "")
                    );
                  }}
                >
                  {t("details")}
                </DefaultButton>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
}
