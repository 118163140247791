import { useEffect, useState } from "react";
import {
  CompaniesTableComponent,
  DefaultButton,
  Loader,
  NavBar,
  NoItemsComponent,
  PaginationComponent,
  Spacing,
} from "../../components";
import { useNavigate } from "react-router-dom";
import {
  ColorEnum,
  CompanyFilter,
  CompanyModel,
  PageRouteEnum,
  PagedList,
  SpacingEnum,
} from "../../types";
import { CompanyService } from "../../services";
import { useTranslation } from "react-i18next";

const companyService = new CompanyService();

export default function CompaniesPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [pagedCompanies, setPagedCompanies] =
    useState<PagedList<CompanyModel>>();
  const [companyFilter, setCompanyFilter] = useState<CompanyFilter>({
    pageNumber: 1,
    pageSize: 10,
  });
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const getCompaniesAsync = async () => {
      const response = await companyService.getPagedAsync(companyFilter);

      if (response.isSuccess && response.result) {
        setPagedCompanies(response.result!);
      }
      setIsLoading(false);
    };

    getCompaniesAsync();
  }, [companyFilter]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <div className="d-flex justify-content-between">
            <h2>{t("companies")}</h2>
            <DefaultButton
              width={200}
              color={ColorEnum.Orange}
              onClick={() => navigate(PageRouteEnum.CompanyCreate)}
            >
              {t("create_company")}
            </DefaultButton>
          </div>
          <Spacing size={SpacingEnum.Large} />
          <CompaniesTableComponent items={pagedCompanies?.items || []} />
          {(pagedCompanies?.items || []).length === 0 && <NoItemsComponent />}
          <PaginationComponent
            onPageChange={(page) => {
              setCompanyFilter({ ...companyFilter, pageNumber: page });
            }}
            currentPage={pagedCompanies?.pageNumber || 1}
            pageCount={pagedCompanies?.lastPage || 1}
          />
        </div>
      </Loader>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
