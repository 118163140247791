import { TableComponent } from "../common";
import { UserStatisticsModel } from "../../types";
import { useTranslation } from "react-i18next";

export default function UserStatisticsTableComponent({
  items,
}: {
  items: UserStatisticsModel[];
}) {
  const { t } = useTranslation();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-5">
            {t("name")}
          </th>
          <th scope="col" className="col-5">
            {t("email")}
          </th>
          <th scope="col" className="col-2">
            {t("rating")}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.id}>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.rating}</td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
}
