import { useState } from "react";
import { BaseFilter, ColorEnum } from "../../types";
import InputForm from "./InputForm";
import TextInput from "./TextInput";
import DefaultButton from "./DefaultButton";
import { useTranslation } from "react-i18next";

export default function BaseFilterComponent({
  filter,
  onChange,
}: {
  filter: BaseFilter;
  onChange: (filter: BaseFilter) => void;
}) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<BaseFilter>(filter);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (onChange) {
      onChange(formData);
    }
  };

  return (
    <InputForm onSubmit={handleSubmit}>
      <div className="d-flex flex-wrap gap-3">
        <TextInput
          name="search"
          title={t("search")}
          value={formData.query}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFormData({
              ...formData,
              query: event.target.value,
            });
          }}
        />
        <div
          className="d-flex align-items-end"
          style={{ marginBottom: "18px" }}
        >
          <DefaultButton type="submit" width={80} color={ColorEnum.Blue}>
            {t("filter")}
          </DefaultButton>
        </div>
      </div>
    </InputForm>
  );
}
