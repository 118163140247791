import { useRef } from "react";
import DefaultButton from "./DefaultButton";
import { ColorEnum } from "../../types";
import { useTranslation } from "react-i18next";

export default function ModalComponent({
  title,
  children,
  show,
  onShowChange,
  onCancel,
  onConfirm,
}: {
  title: string;
  children: React.ReactNode;
  show: boolean;
  onShowChange: (show: boolean) => void;
  onCancel?: () => void;
  onConfirm?: () => void;
}) {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onShowChange(false);
    }
  };

  return (
    <div>
      {show && (
        <div
          className="modal d-flex align-items-center justify-content-center"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          onClick={handleClickOutside}
        >
          <div className="modal-dialog">
            <div className="modal-content" ref={modalRef}>
              <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
              </div>
              <div className="modal-body">{children}</div>
              <div className="modal-footer">
                <DefaultButton
                  onClick={() => {
                    if (onCancel) {
                      onCancel();
                    }
                    onShowChange(false);
                  }}
                >
                  {t("close")}
                </DefaultButton>
                <DefaultButton
                  onClick={() => {
                    if (onConfirm) {
                      onConfirm();
                    }
                    onShowChange(false);
                  }}
                  color={ColorEnum.Green}
                >
                  {t("confirm")}
                </DefaultButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
