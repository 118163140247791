export enum PageRouteEnum {
  Home = "/",
  Login = "/login",
  Register = "/register",
  Account = "/account",
  ResetPassword = "/reset-password",
  Routes = "/routes",
  RouteCreate = "/route/create",
  RouteDetails = "/route/details/:id",
  RouteEdit = "/route/edit/:id",
  Employees = "/employees",
  EmployeesInvite = "/employee/invite",
  EmployeesProfile = "/employee/profile/:id",
  NotFound = "/notfound",
  Vehicles = "/vehicles",
  VehicleEdit = "/vehicle/edit/:id",
  VehicleDetails = "/vehicle/details/:id",
  VehicleCreate = "/vehicle/create",
  Packages = "/packages",
  PackageCreate = "/package/create",
  PackageImport = "/package/import",
  PackageEdit = "/package/edit/:id",
  PackageDetails = "/package/details/:id",
  PackageAssignDriver = "/package/assign-driver/:id",
  PackageOffers = "/package/:id/offers",
  Companies = "/companies",
  CompanyCreate = "/company/create",
  CompanyEdit = "/company/edit/:id",
  CompanyDetails = "/company/details/:id",
  Statistics = "/statistics",
  RouteStatistics = "/statistics/route",
  UserStatistics = "/statistics/user",
  PackageStatistics = "/statistics/package",
  ConfirmEmail = "/confirm-email/:token",
  Offers = "/offers",
  AcceptedOffers = "/offers/accepted",
  PackageOfferDetails = "/offer/details/:id",
  Requests = "/requests",
  RouteRequests = "/requests/route/:packageId",
}
