import { useTranslation } from "react-i18next";
import { CompanyEmployeeService, UserDataService } from "../../services";
import {
  ColorEnum,
  CompanyEmployeeModel,
  CompanyEmployeeStatusEnum,
  PageRouteEnum,
  RoleEnum,
} from "../../types";
import { LanguageUtil, ToastUtil } from "../../utils";
import { DefaultButton, TableComponent } from "../common";
import { useNavigate } from "react-router-dom";

export default function EmployeesTableComponent({
  onChange,
  items,
}: {
  onChange: () => void;
  items: CompanyEmployeeModel[];
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const companyEmployeeService = new CompanyEmployeeService();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3">
            {t("name")}
          </th>
          <th scope="col" className="col-3">
            {t("email")}
          </th>
          {UserDataService.isInRole(RoleEnum.SuperAdmin) && (
            <th scope="col" className="col-3">
              {t("company")}
            </th>
          )}
          <th scope="col" className="col-1">
            {t("rating")}
          </th>
          <th scope="col" className="col-2">
            {t("actions")}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.userId}>
            <td>{item.name}</td>
            <td>{item.email}</td>
            {UserDataService.isInRole(RoleEnum.SuperAdmin) && (
              <td>{item.companyName}</td>
            )}
            <td>{t("No rating")}</td>
            <td>
              {item.status === CompanyEmployeeStatusEnum.Waiting && (
                <div className="btn-group gap-1">
                  <DefaultButton
                    width={80}
                    color={ColorEnum.Green}
                    onClick={async () => {
                      const result =
                        await companyEmployeeService.updateStatusAsync(
                          item.userId!,
                          item.companyId!,
                          CompanyEmployeeStatusEnum.Accepted
                        );

                      if (result.isSuccess) {
                        onChange();
                      } else {
                        ToastUtil.showErrorMessage(
                          LanguageUtil.translateErrorMessage(
                            t,
                            result.errorMessage
                          )
                        );
                      }
                    }}
                  >
                    {t("accept")}
                  </DefaultButton>
                  <DefaultButton
                    width={80}
                    color={ColorEnum.Red}
                    onClick={async () => {
                      const result =
                        await companyEmployeeService.updateStatusAsync(
                          item.userId!,
                          item.companyId!,
                          CompanyEmployeeStatusEnum.Declined
                        );

                      if (result.isSuccess) {
                        onChange();
                      } else {
                        ToastUtil.showErrorMessage(
                          LanguageUtil.translateErrorMessage(
                            t,
                            result.errorMessage
                          )
                        );
                      }
                    }}
                  >
                    {t("decline")}
                  </DefaultButton>
                </div>
              )}
              {item.status === CompanyEmployeeStatusEnum.Accepted && (
                <div className="btn-group gap-1">
                  <DefaultButton
                    width={80}
                    onClick={() => {
                      navigate(
                        PageRouteEnum.EmployeesProfile.replace(
                          ":id",
                          item.userId || ""
                        )
                      );
                    }}
                  >
                    {t("profile")}
                  </DefaultButton>
                  {UserDataService.isInRole(RoleEnum.DeliveryBusinessAdmin) && (
                    <DefaultButton
                      width={140}
                      onClick={() => {
                        navigate(
                          PageRouteEnum.EmployeesProfile.replace(
                            ":id",
                            item.userId || ""
                          )
                        );
                      }}
                    >
                      {t("assign_vehicle")}
                    </DefaultButton>
                  )}
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
}
