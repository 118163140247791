import { Link } from "react-router-dom";
import { ColorEnum, PageRouteEnum } from "../../types";
import NavigationLink from "./NavigationLink";
import { UserDataService } from "../../services";
import { useTranslation } from "react-i18next";

export default function DelivererNavBar() {
  const { t } = useTranslation();
  const companyInfo = UserDataService.getCompanyInfo();

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-3">
      <Link className="navbar-brand" to={PageRouteEnum.Home}>
        <div
          className="d-flex align-items-center gap-2"
          style={{ color: ColorEnum.Orange }}
        >
          <img
            src="/logo32.png"
            width="32"
            height="32"
            className="d-inline-block align-top"
            alt="Logo"
          />
          {companyInfo ? companyInfo.name : "Gepek Business"}
        </div>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse justify-content-end"
        id="navbarNav"
      >
        <ul className="navbar-nav">
          <NavigationLink path={PageRouteEnum.Home}>{t("home")}</NavigationLink>
          <NavigationLink path={PageRouteEnum.Routes}>
            {t("routes")}
          </NavigationLink>
          <NavigationLink path={PageRouteEnum.Employees}>
            {t("employees")}
          </NavigationLink>
          <NavigationLink path={PageRouteEnum.Vehicles}>
            {t("vehicles")}
          </NavigationLink>
          <NavigationLink path={PageRouteEnum.Packages}>
            {t("packages")}
          </NavigationLink>
          <NavigationLink path={PageRouteEnum.Requests}>
            {t("requests")}
          </NavigationLink>
          <NavigationLink path={PageRouteEnum.Offers}>
            {t("sent_offers")}
          </NavigationLink>
          <NavigationLink path={PageRouteEnum.AcceptedOffers}>
            {t("for_delivery")}
          </NavigationLink>
          <NavigationLink path={PageRouteEnum.Statistics}>
            {t("statistics")}
          </NavigationLink>
          {companyInfo && (
            <NavigationLink
              path={PageRouteEnum.Companies}
              onClick={() => UserDataService.removeCompanyInfo()}
            >
              {t("switch_company")}
            </NavigationLink>
          )}
          {!companyInfo && (
            <NavigationLink path={PageRouteEnum.Account}>
              {t("account")}
            </NavigationLink>
          )}
        </ul>
      </div>
    </nav>
  );
}
