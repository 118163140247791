import {
  Loader,
  ModalComponent,
  NavBar,
  NoItemsComponent,
  PaginationComponent,
  RequestsTableComponent,
  Spacing,
} from "../../components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  MatchmakingFilter,
  MatchmakingModel,
  PagedList,
  SpacingEnum,
} from "../../types";
import {
  MatchmakingService,
  ShipmentService,
  UserDataService,
} from "../../services";
import { LanguageUtil, ToastUtil } from "../../utils";

const matchmakingService = new MatchmakingService();
const shipmentService = new ShipmentService();

export default function RequestsPage() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<MatchmakingFilter>({
    companyId: UserDataService.getCompanyId()!,
    pageNumber: 1,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pagedPackages, setPagedMatchmakingRequests] = useState<
    PagedList<MatchmakingModel>
  >({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<MatchmakingModel>({});

  useEffect(() => {
    const fetchPackages = async () => {
      var response = await matchmakingService.getRouteRequestsAsync(filter);

      if (response.isSuccess && response.result) {
        setPagedMatchmakingRequests(response.result);
      }

      setIsLoading(false);
    };

    fetchPackages();
  }, [filter]);

  const acceptRequestAsync = async () => {
    const result = await shipmentService.addAsync({
      packageId: selectedModel.packageInfo!.id!,
      routeId: selectedModel.routeId!,
    });

    if (result.isSuccess) {
      ToastUtil.showSuccessMessage(t("request_accepted"));
      setFilter({
        ...filter,
      });
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
    }
  };

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <h2>{t("requests")}</h2> <Spacing size={SpacingEnum.Large} />
          <RequestsTableComponent
            items={pagedPackages?.items || []}
            onAccept={async (model) => {
              setSelectedModel(model);
              setShowModal(true);
            }}
          />
          {(pagedPackages?.items || []).length === 0 && <NoItemsComponent />}
          <PaginationComponent
            onPageChange={(page) => {
              setFilter({
                ...filter,
                pageNumber: page,
              });
            }}
            currentPage={pagedPackages?.pageNumber || 1}
            pageCount={pagedPackages?.lastPage || 1}
          />
        </div>
      </Loader>
      <ModalComponent
        title={t("confirm_accept")}
        show={showModal}
        onShowChange={setShowModal}
        onConfirm={acceptRequestAsync}
      >
        <p>{t("package_request_confirm_accept")}</p>
      </ModalComponent>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
