import { useCallback, useEffect, useState } from "react";
import {
  DefaultButton,
  InputForm,
  Loader,
  NavBar,
  Spacing,
  TextInput,
} from "../../components";
import { ColorEnum, CompanyModel, Constants, SpacingEnum } from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyService } from "../../services";
import { LanguageUtil, ToastUtil, ValidationUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const companyService = new CompanyService();

export default function CompanyEditPage() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<CompanyModel>(new CompanyModel());
  const [formValidations, setFormValidations] = useState({
    name: true,
    email: true,
    address: true,
    city: true,
    postalCode: true,
    personalIdentificationNumber: true,
    phoneNumber: true,
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(
      new CompanyModel({ ...formData, [event.target.name]: event.target.value })
    );
  };

  const handleValidationChange = useCallback(
    (name: string, isValid: boolean) => {
      setFormValidations((prevValidations) => ({
        ...prevValidations,
        [name]: isValid,
      }));
    },
    []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = Object.values(formValidations).every(
      (isValid) => isValid
    );

    if (!isFormValid) {
      return;
    }

    const result = await companyService.updateAsync(formData);

    if (result.isSuccess) {
      ToastUtil.showSuccessMessage(t("company_updated"));
      navigate(-1);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
    }
  };

  useEffect(() => {
    const getCompanyAsync = async () => {
      const response = await companyService.findByIdAsync(params.id ?? "");

      if (response.isSuccess) {
        setFormData(response.result!);
      }

      setIsLoading(false);
    };

    getCompanyAsync();
  }, [params.id]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div
          className="container mt-3"
          style={{ maxWidth: Constants.maxWidth }}
        >
          <h2>{t("edit_company")}</h2>
          <InputForm onSubmit={handleSubmit}>
            <TextInput
              name="name"
              title={t("name")}
              value={formData.name}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_company_name");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="email"
              title={t("email")}
              value={formData.email}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_company_email");
                } else if (!ValidationUtil.validateEmail(value)) {
                  return t("please_enter_a_valid_email");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="address"
              title={t("address")}
              value={formData.address}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_company_address");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="city"
              title={t("city")}
              value={formData.city}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_company_city");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="postalCode"
              title={t("postal_code")}
              value={formData.postalCode}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_company_postal_code");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="personalIdentificationNumber"
              title={t("pid")}
              value={formData.personalIdentificationNumber}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_company_pid");
                } else if (value.length !== 11) {
                  return t("pid_11_characters_long");
                } else if (!ValidationUtil.onlyNumbers(value)) {
                  return t("pid_only_numbers");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="phoneNumber"
              title={t("phone_number")}
              value={formData.phoneNumber}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_company_contact_number");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <Spacing size={SpacingEnum.XXLarge} />
            <DefaultButton
              type="submit"
              color={ColorEnum.Orange}
              disabled={
                !Object.values(formValidations).every((isValid) => isValid)
              }
            >
              {t("save_changes")}
            </DefaultButton>
          </InputForm>
          <Spacing size={SpacingEnum.Giant} />
        </div>
      </Loader>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
