import { UserDataService } from "../../services";
import DelivererOffersPage from "./DelivererOffersPage";
import SenderOffersPage from "./SenderOffersPage";
import { RoleEnum } from "../../types";

export default function OffersPage() {
  if (UserDataService.isInRole(RoleEnum.DeliveryBusinessAdmin)) {
    return <DelivererOffersPage />;
  }

  if (
    UserDataService.isInRole(RoleEnum.BusinessSender) ||
    UserDataService.isInRole(RoleEnum.SenderBusinessAdmin)
  ) {
    return <SenderOffersPage />;
  }

  return <div>You are a super admin!</div>;
}
