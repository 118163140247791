export class CompanyInviteCodeModel {
  companyId?: string;
  code?: string;
  expirationTime?: Date;
  inviteUrl?: string;

  constructor({
    companyId,
    code,
    expirationTime,
    inviteUrl,
  }: CompanyInviteCodeModel) {
    this.companyId = companyId;
    this.code = code;
    this.expirationTime = expirationTime;
    this.inviteUrl = inviteUrl;
  }

  static fromJson(data: any): CompanyInviteCodeModel {
    return new CompanyInviteCodeModel({
      companyId: data["companyId"],
      code: data["code"],
      expirationTime:
        data["expirationTime"] && new Date(data["expirationTime"]),
      inviteUrl: data["inviteUrl"],
    });
  }
}
