import { useEffect, useState } from "react";
import {
  DefaultButton,
  EmployeesFilterComponent,
  EmployeesTableComponent,
  Loader,
  NavBar,
  NoItemsComponent,
  PaginationComponent,
  Spacing,
} from "../../components";
import {
  ColorEnum,
  CompanyEmployeeFilter,
  CompanyEmployeeModel,
  PagedList,
  PageRouteEnum,
  SpacingEnum,
} from "../../types";
import { useNavigate } from "react-router-dom";
import { CompanyEmployeeService, UserDataService } from "../../services";
import { useTranslation } from "react-i18next";

const companyEmployeeService = new CompanyEmployeeService();

export default function EmployeesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [pagedCompanyEmployees, setPagedCompanyEmployees] = useState<
    PagedList<CompanyEmployeeModel>
  >({});
  const [filter, setFilter] = useState<CompanyEmployeeFilter>({
    companyId: UserDataService.getCompanyId()!,
    pageNumber: 1,
    pageSize: 10,
  });

  useEffect(() => {
    setIsLoading(true);
    const getCompanyEmployeesAsync = async () => {
      const response = await companyEmployeeService.getPagedAsync(filter);

      if (response.isSuccess) {
        setPagedCompanyEmployees(response.result!);
      }

      setIsLoading(false);
    };

    getCompanyEmployeesAsync();
  }, [filter]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <div className="row">
            <div className="col">
              <h2>{t("employees")}</h2>
            </div>
            <div className="col d-flex justify-content-end ">
              <DefaultButton
                width={200}
                color={ColorEnum.Orange}
                onClick={() => {
                  navigate(PageRouteEnum.EmployeesInvite);
                }}
              >
                {t("invite")}
              </DefaultButton>
            </div>
          </div>
          <EmployeesFilterComponent
            filter={filter}
            onChange={(filter: CompanyEmployeeFilter) => {
              setFilter(filter);
            }}
          />
          <EmployeesTableComponent
            onChange={() => {
              setFilter({ ...filter });
            }}
            items={pagedCompanyEmployees.items || []}
          />
          {(pagedCompanyEmployees?.items || []).length === 0 && (
            <NoItemsComponent />
          )}
          <PaginationComponent
            onPageChange={(page) => {
              setFilter({ ...filter, pageNumber: page });
            }}
            currentPage={pagedCompanyEmployees?.pageNumber || 1}
            pageCount={pagedCompanyEmployees?.lastPage || 1}
          />
        </div>
      </Loader>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
