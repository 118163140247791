import { useEffect, useState } from "react";
import {
  ColorEnum,
  PackageFilter,
  PackageModel,
  PageRouteEnum,
  PagedList,
  RoleEnum,
  SpacingEnum,
} from "../../types";
import { useNavigate } from "react-router-dom";
import { PackageService, UserDataService } from "../../services";
import {
  DefaultButton,
  Loader,
  NavBar,
  NoItemsComponent,
  PackagesFilterComponent,
  PaginationComponent,
  SenderPackagesTableComponent,
  Spacing,
} from "../../components";
import { useTranslation } from "react-i18next";

const packageService = new PackageService();

export default function SenderPackagesPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [pagedPackages, setPagedPackages] = useState<PagedList<PackageModel>>();
  const [packageFilter, setPackageFilter] = useState<PackageFilter>({
    companyId: UserDataService.getCompanyId() || undefined,
    userId:
      (UserDataService.isInRole(RoleEnum.BusinessSender) &&
        UserDataService.getUserId()) ||
      undefined,
    pageNumber: 1,
    pageSize: 10,
  });
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const getPackagesAsync = async () => {
      const response = await packageService.getPagedAsync(packageFilter);

      if (response.isSuccess && response.result) {
        setPagedPackages(response.result!);
      }

      setIsLoading(false);
    };

    getPackagesAsync();
  }, [packageFilter]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <div className="d-flex justify-content-between">
            <h2>{t("packages")}</h2>
            <div className="btn-group gap-3">
              <DefaultButton
                width={200}
                onClick={() => navigate(PageRouteEnum.PackageImport)}
                color={ColorEnum.Orange}
              >
                {t("import_packages")}
              </DefaultButton>
              <DefaultButton
                width={200}
                onClick={() => navigate(PageRouteEnum.PackageCreate)}
                color={ColorEnum.Orange}
              >
                {t("create_package")}
              </DefaultButton>
            </div>
          </div>
          <Spacing size={SpacingEnum.Large} />
          <PackagesFilterComponent
            filter={packageFilter}
            onChange={(filter) => {
              setPackageFilter(filter);
            }}
          />
          <SenderPackagesTableComponent items={pagedPackages?.items || []} />
          {(pagedPackages?.items || []).length === 0 && <NoItemsComponent />}
          <PaginationComponent
            onPageChange={(page) => {
              setPackageFilter({ ...packageFilter, pageNumber: page });
            }}
            currentPage={pagedPackages?.pageNumber || 1}
            pageCount={pagedPackages?.lastPage || 1}
          />
        </div>
      </Loader>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
