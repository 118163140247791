export class UserRegisterModel {
  name?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  language?: string;
  address?: string;
  inviteCode?: string;

  constructor({
    name,
    email,
    password,
    phoneNumber,
    language,
    address,
    inviteCode,
  }: UserRegisterModel = {}) {
    this.name = name;
    this.email = email;
    this.password = password;
    this.phoneNumber = phoneNumber;
    this.language = language;
    this.address = address;
    this.inviteCode = inviteCode;
  }
}
