import { useTranslation } from "react-i18next";
import { ColorEnum } from "../../types";

export default function BasicLoader() {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
        className="spinner-border"
        role="status"
        style={{ width: "3rem", height: "3rem", color: ColorEnum.Orange }}
      >
        <span className="visually-hidden">{t("loading")}...</span>
      </div>
    </div>
  );
}
