import { useEffect, useState } from "react";
import { ColorEnum, DropdownItemModel, SpacingEnum } from "../../types";
import Spacing from "./Spacing";
import ReactSelect, { MultiValue, SingleValue } from "react-select";

export default function DropdownInput({
  title,
  name,
  value,
  options = [],
  multiple = false,
  width,
  defaultErrorMessage,
  onChange,
  onValidationChange,
}: {
  title: string;
  name: string;
  value?: DropdownItemModel | DropdownItemModel[];
  options?: DropdownItemModel[];
  multiple?: boolean;
  width?: number;
  defaultErrorMessage?: string;
  onChange?: (
    newValue: MultiValue<DropdownItemModel> | SingleValue<DropdownItemModel>
  ) => void;
  onValidationChange?: (name: string, isValid: boolean) => void;
}) {
  const [inputValue, setInputValue] = useState<
    MultiValue<DropdownItemModel> | SingleValue<DropdownItemModel>
  >(
    multiple
      ? (value as MultiValue<DropdownItemModel>)
      : (value as SingleValue<DropdownItemModel>)
  );
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const isFormInvalid =
      !inputValue || (inputValue as MultiValue<DropdownItemModel>).length === 0;
    setIsValid(!isFormInvalid);
    if (onValidationChange) {
      onValidationChange(name, !isFormInvalid);
    }
  }, [inputValue, onValidationChange]);

  return (
    <div className="form-group mb-3 mt-3" style={{ width }}>
      {title && (
        <>
          <label htmlFor={name} className="form-label">
            {title}
          </label>
          <Spacing size={SpacingEnum.Small} />
        </>
      )}
      <ReactSelect
        id={name}
        name={name}
        value={value}
        onFocus={() => {
          if (!isTouched) {
            setIsTouched(true);
          }
        }}
        className={`${isTouched && !isValid ? "is-invalid" : ""}`}
        onChange={(value) => {
          if (!isTouched) {
            setIsTouched(true);
          }
          setInputValue(value);
          onChange!(value);
        }}
        isMulti={multiple}
        options={options}
        styles={{
          control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: ColorEnum.ControlBackground,
            color: ColorEnum.ControlText,
            boxShadow: "none",
            border: isFocused || (isTouched && !isValid) ? "1" : "none",
            borderColor: isTouched && !isValid ? ColorEnum.Red : ColorEnum.Blue,
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isSelected
              ? ColorEnum.Grey
              : isFocused
              ? ColorEnum.DarkBlue
              : ColorEnum.Black,
            color: ColorEnum.White,
            cursor: "pointer",
            border: "none",
          }),
          input: (styles) => ({
            ...styles,
            color: ColorEnum.ControlText,
          }),
          placeholder: (styles) => ({
            ...styles,
            color: ColorEnum.PlaceHolderText,
          }),
          singleValue: (styles) => ({
            ...styles,
            color: ColorEnum.ControlText,
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: ColorEnum.ControlBackground,
            color: ColorEnum.ControlText,
            borderRadius: "0.25rem",
            marginTop: "0.1rem",
          }),
          menuList: (styles) => ({
            ...styles,
            backgroundColor: ColorEnum.ControlBackground,
            color: ColorEnum.ControlText,
          }),
          multiValue: (styles) => ({
            ...styles,
            backgroundColor: ColorEnum.Grey,
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            color: ColorEnum.White,
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            color: ColorEnum.White,
            ":hover": {
              backgroundColor: ColorEnum.Red,
            },
          }),
        }}
      />
      {isTouched && (
        <div className="invalid-feedback">{defaultErrorMessage}</div>
      )}
    </div>
  );
}
