import { useEffect, useState } from "react";
import {
  Loader,
  NavBar,
  NoItemsComponent,
  PaginationComponent,
  SenderPackageOffersTableComponent,
  Spacing,
} from "../../components";
import { PackageOfferService, UserDataService } from "../../services";
import {
  ColorEnum,
  PackageOfferFilter,
  PagedList,
  SenderPackageOfferModel,
  SpacingEnum,
} from "../../types";
import { useTranslation } from "react-i18next";

const packageOfferService = new PackageOfferService();

export default function SenderOffersPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [packageOfferFilter, setPackageOfferFilter] =
    useState<PackageOfferFilter>({
      pageSize: 10,
      pageNumber: 1,
      packageCompanyId: UserDataService.getCompanyId() || "",
    });
  const [pagedPackageOffers, setPagedPackageOffers] = useState<
    PagedList<SenderPackageOfferModel>
  >({});

  useEffect(() => {
    const fetchPackageOffers = async () => {
      const response = await packageOfferService.getPagedForSenderAsync(
        packageOfferFilter
      );

      if (response.isSuccess && response.result) {
        setPagedPackageOffers(response.result!);
      }

      setIsLoading(false);
    };

    fetchPackageOffers();
  }, [packageOfferFilter]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <h3 style={{ color: ColorEnum.Orange }}>{t("offers_page")}</h3>
          <Spacing size={SpacingEnum.Large} />
          <SenderPackageOffersTableComponent
            items={pagedPackageOffers?.items || []}
          />
          {(pagedPackageOffers?.items || []).length === 0 && (
            <NoItemsComponent />
          )}
          <PaginationComponent
            onPageChange={(page) => {
              setPackageOfferFilter({
                ...packageOfferFilter,
                pageNumber: page,
              });
            }}
            currentPage={pagedPackageOffers?.pageNumber || 1}
            pageCount={pagedPackageOffers?.lastPage || 1}
          />
        </div>
      </Loader>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
