export class DelivererModel {
  delivererId?: string;
  name?: string;
  rating?: number;
  numberOfReviews?: number;
  delivererType?: string;

  constructor({
    delivererId,
    name,
    rating,
    numberOfReviews,
    delivererType,
  }: DelivererModel = {}) {
    this.delivererId = delivererId;
    this.name = name;
    this.rating = rating;
    this.numberOfReviews = numberOfReviews;
    this.delivererType = delivererType;
  }

  static fromJson(data: any): DelivererModel {
    return new DelivererModel({
      delivererId: data["delivererId"],
      name: data["name"],
      rating: data["rating"],
      numberOfReviews: data["numberOfReviews"],
      delivererType: data["delivererType"],
    });
  }
}
