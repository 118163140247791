import { Link } from "react-router-dom";
import { ColorEnum, PageRouteEnum } from "../../types";

export default function DefaultNavBar() {
  return (
    <nav className="navbar navbar-dark bg-dark px-3">
      <Link className="navbar-brand" to={PageRouteEnum.Home}>
        <div
          className="d-flex align-items-center gap-2"
          style={{ color: ColorEnum.Orange }}
        >
          <img
            src="/logo32.png"
            width="32"
            height="32"
            className="d-inline-block align-top"
            alt="Logo"
          />
          Gepek Business
        </div>
      </Link>
    </nav>
  );
}
