import { UserDataService } from "../../services";
import { RoleEnum } from "../../types";
import DelivererRoutesPage from "./DelivererRoutesPage";
import SenderRoutesPage from "./SenderRoutesPage";

export default function RoutesPage() {
  if (UserDataService.isInRole(RoleEnum.DeliveryBusinessAdmin)) {
    return <DelivererRoutesPage />;
  }

  if (
    UserDataService.isInRole(RoleEnum.BusinessSender) ||
    UserDataService.isInRole(RoleEnum.SenderBusinessAdmin)
  ) {
    return <SenderRoutesPage />;
  }

  return <>You are a super admin!</>;
}
