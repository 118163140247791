import { useEffect, useState } from "react";
import {
  DelivererOfferFilterComponent,
  DelivererPackageOffersTableComponent,
  Loader,
  NavBar,
  NoItemsComponent,
  PaginationComponent,
} from "../../components";
import {
  DelivererPackageOfferModel,
  PackageOfferFilter,
  PackageStatusEnum,
  PagedList,
} from "../../types";
import { PackageOfferService, UserDataService } from "../../services";
import { useTranslation } from "react-i18next";

const packageOfferService = new PackageOfferService();

export default function AcceptedOffersPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [packageOfferFilter, setPackageOfferFilter] =
    useState<PackageOfferFilter>({
      companyId: UserDataService.getCompanyId() || "",
      packageStatus: [PackageStatusEnum.WaitingPickup],
    });
  const [pagedPackageOffers, setPagedPackageOffers] =
    useState<PagedList<DelivererPackageOfferModel>>();

  useEffect(() => {
    setIsLoading(true);

    const fetchPackageOffers = async () => {
      const response = await packageOfferService.getPagedForDelivererAsync(
        packageOfferFilter
      );

      if (response.isSuccess && response.result) {
        setPagedPackageOffers(response.result);
      }

      setIsLoading(false);
    };

    fetchPackageOffers();
  }, [packageOfferFilter]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <h2>{t("accepted_offers")}</h2>
          <DelivererOfferFilterComponent
            filter={packageOfferFilter}
            onChange={(filter: PackageOfferFilter) => {
              setPackageOfferFilter(filter);
            }}
          />
          <DelivererPackageOffersTableComponent
            items={pagedPackageOffers?.items || []}
            showOffersButton={false}
            onSelect={() => {}}
          />
          {(pagedPackageOffers?.items || []).length === 0 && (
            <NoItemsComponent />
          )}
          <PaginationComponent
            onPageChange={(page) => {
              setPackageOfferFilter({
                ...packageOfferFilter,
                pageNumber: page,
              });
            }}
            currentPage={pagedPackageOffers?.pageNumber || 1}
            pageCount={pagedPackageOffers?.lastPage || 1}
          />
        </div>
      </Loader>
    </div>
  );
}
