import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { ColorEnum, DropdownItemModel, SpacingEnum } from "../../types";
import { ActionMeta } from "react-select";
import Spacing from "./Spacing";

export default function AsyncDropdownInput({
  name,
  title,
  loadOptions,
  onChange,
  value,
  width,
  defaultErrorMessage,
  onValidationChange,
}: {
  name: string;
  title: string;
  loadOptions: (inputValue: string) => Promise<DropdownItemModel[]>;
  onChange: (
    option: DropdownItemModel | null,
    actionMeta: ActionMeta<DropdownItemModel>
  ) => void;
  defaultOptions?: DropdownItemModel[];
  value?: DropdownItemModel | null;
  width?: number;
  defaultErrorMessage?: string;
  onValidationChange?: (name: string, isValid: boolean) => void;
}) {
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [inputValue, setInputValue] = useState<DropdownItemModel | null>(
    value || null
  );

  useEffect(() => {
    const isFormInvalid = !inputValue;
    setIsValid(!isFormInvalid);
    if (onValidationChange) {
      onValidationChange(name, !isFormInvalid);
    }
  }, [inputValue, name, onValidationChange]);

  return (
    <div className="form-group mb-3 mt-3" style={{ width }}>
      {title && (
        <>
          <label htmlFor={name} className="form-label">
            {title}
          </label>
          <Spacing size={SpacingEnum.Small} />
        </>
      )}
      <AsyncSelect
        name={name}
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        isSearchable={true}
        onChange={(value, meta) => {
          setInputValue(value as DropdownItemModel);
          onChange(value, meta);
        }}
        value={value}
        className={`text-light bg-dark ${
          isTouched && !isValid ? "is-invalid" : ""
        }`}
        styles={{
          control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: ColorEnum.ControlBackground,
            color: ColorEnum.ControlText,
            boxShadow: "none",
            border: isFocused || (isTouched && !isValid) ? "1" : "none",
            borderColor: isTouched && !isValid ? ColorEnum.Red : ColorEnum.Blue,
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isSelected
              ? ColorEnum.Grey
              : isFocused
              ? ColorEnum.DarkBlue
              : ColorEnum.Black,
            color: ColorEnum.White,
            cursor: "pointer",
            border: "none",
          }),
          input: (styles) => ({
            ...styles,
            color: ColorEnum.ControlText,
          }),
          placeholder: (styles) => ({
            ...styles,
            color: ColorEnum.PlaceHolderText,
          }),
          singleValue: (styles) => ({
            ...styles,
            color: ColorEnum.ControlText,
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: ColorEnum.ControlBackground,
            color: ColorEnum.ControlText,
            borderRadius: "0.25rem",
            marginTop: "0.1rem",
          }),
          menuList: (styles) => ({
            ...styles,
            backgroundColor: ColorEnum.ControlBackground,
            color: ColorEnum.ControlText,
          }),
        }}
        onFocus={() => {
          if (!isTouched) setIsTouched(true);
        }}
        controlShouldRenderValue={true}
        pageSize={15}
      />
      {isTouched && (
        <div className="invalid-feedback">{defaultErrorMessage}</div>
      )}
    </div>
  );
}
