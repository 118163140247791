import { useEffect, useState } from "react";
import {
  DefaultButton,
  DropdownInput,
  Loader,
  NavBar,
  PackageDetailsTableComponent,
  Spacing,
} from "../../components";
import {
  ColorEnum,
  DropdownItemModel,
  PackageModel,
  PageRouteEnum,
  SpacingEnum,
} from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { PackageService } from "../../services";
import { useTranslation } from "react-i18next";

const packageService = new PackageService();

export default function PackageAssignDriverPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [model, setModel] = useState(new PackageModel());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPackageAsync = async () => {
      const response = await packageService.findByIdAsync(params.id ?? "");

      if (response.isSuccess) {
        setModel(response.result!);
      }
    };

    const fetchData = async () => {
      const promises = [getPackageAsync()];

      await Promise.all(promises);

      setIsLoading(false);
    };

    fetchData();
  }, [params.id]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <div>
            <h2>{t("assign_driver")}</h2>
          </div>
          <PackageDetailsTableComponent model={model} showFullInfo={false} />
          <DropdownInput
            name="vehicle"
            title={t("vehicle")}
            onChange={(result) => {}}
            options={[
              new DropdownItemModel("1", "Mario"),
              new DropdownItemModel("2", "Kiki"),
            ]}
          />
          <Spacing size={SpacingEnum.Large} />
          <div className="d-flex justify-content-center">
            <DefaultButton
              width={200}
              onClick={() => navigate(PageRouteEnum.Packages)}
              color={ColorEnum.Orange}
            >
              {t("save_changes")}
            </DefaultButton>
          </div>
        </div>
      </Loader>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
