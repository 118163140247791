import DelivererNavBar from "./DelivererNavBar";
import { UserDataService } from "../../services";
import { RoleEnum } from "../../types";
import AdminNavBar from "./AdminNavBar";
import DefaultNavBar from "./DefaultNavBar";
import SenderNavBar from "./SenderNavBar";

export default function NavBar() {
  if (UserDataService.isInRole(RoleEnum.SuperAdmin)) {
    if (UserDataService.getCompanyInfo()) {
      return <DelivererNavBar />;
    }
    return <AdminNavBar />;
  }

  if (
    UserDataService.isInRole(RoleEnum.BusinessSender) ||
    UserDataService.isInRole(RoleEnum.SenderBusinessAdmin)
  ) {
    return <SenderNavBar />;
  }

  if (UserDataService.isInRole(RoleEnum.DeliveryBusinessAdmin)) {
    return <DelivererNavBar />;
  }

  return <DefaultNavBar />;
}
