import { useEffect, useState } from "react";
import {
  Loader,
  ModalComponent,
  NavBar,
  NoItemsComponent,
  PackageDetailsTableComponent,
  PaginationComponent,
  SenderOfferFilterComponent,
  SenderPackageOffersTableComponent,
  Spacing,
} from "../../components";
import {
  PackageModel,
  PackageOfferFilter,
  PackageStatusEnum,
  PagedList,
  SenderPackageOfferModel,
  SpacingEnum,
} from "../../types";
import { useParams } from "react-router-dom";
import { PackageOfferService, PackageService } from "../../services";
import { LanguageUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const packageOfferService = new PackageOfferService();
const packageService = new PackageService();

export default function PackageOffersPage() {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState<PackageOfferFilter>({
    pageNumber: 1,
    pageSize: 10,
    packageId: params.id,
  });
  const [model, setModel] = useState<PackageModel>();
  const [pagedPackageOffers, setPagedPackageOffers] =
    useState<PagedList<SenderPackageOfferModel>>();
  const [selectedOffer, setSelectedOffer] = useState<SenderPackageOfferModel>(
    {}
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const acceptOfferAsync = async () => {
    var response = await packageOfferService.acceptAsync(
      selectedOffer.id!,
      selectedOffer.packageId!
    );

    if (response.isSuccess) {
      ToastUtil.showSuccessMessage(t("offer_accepted"));
      setFilter({
        ...filter,
        pageNumber: 1,
        pageSize: 10,
        packageId: params.id,
      });
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, response.errorMessage)
      );
    }
  };

  useEffect(() => {
    const fetchPackage = async () => {
      const response = await packageService.findByIdAsync(params.id || "");

      if (response.isSuccess && response.result) {
        setModel(response.result);
      }
    };

    const fetchPackageOffers = async () => {
      const response = await packageOfferService.getPagedForSenderAsync(filter);

      if (response.isSuccess && response.result) {
        setPagedPackageOffers(response.result!);
      }
    };

    const fetchData = async () => {
      setIsLoading(true);

      const promises = [fetchPackage(), fetchPackageOffers()];

      await Promise.all(promises);

      setIsLoading(false);
    };

    fetchData();
  }, [params.id, filter]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <h2>{t("offers_for_package")}</h2>
          <Spacing size={SpacingEnum.Large} />
          <PackageDetailsTableComponent
            model={model || new PackageModel()}
            showFullInfo={false}
          />
          <SenderOfferFilterComponent
            filter={filter}
            onChange={(filter) => {
              setFilter(filter);
            }}
          />
          <SenderPackageOffersTableComponent
            items={pagedPackageOffers?.items || []}
            isSelectingDeliverer={
              model?.status === PackageStatusEnum.SelectingDeliverer
            }
            onAccept={(offer) => {
              setSelectedOffer(offer);
              setShowModal(true);
            }}
          />
          {(pagedPackageOffers?.items || []).length === 0 && (
            <NoItemsComponent />
          )}
          <PaginationComponent
            onPageChange={(page) => {
              setFilter({
                ...filter,
                pageNumber: page,
              });
            }}
            currentPage={pagedPackageOffers?.pageNumber || 1}
            pageCount={pagedPackageOffers?.lastPage || 1}
          />
        </div>
      </Loader>
      <ModalComponent
        title={t("confirm_accept")}
        show={showModal}
        onShowChange={setShowModal}
        onConfirm={acceptOfferAsync}
      >
        <p>
          {t("offer_confirm_accept").replace(
            "%name%",
            selectedOffer.delivererInfo?.name || ""
          )}
        </p>
      </ModalComponent>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
