import { useCallback, useEffect, useState } from "react";
import {
  AddressInput,
  AsyncDropdownInput,
  CheckBoxInput,
  DateTimeInput,
  DefaultButton,
  DropdownInput,
  InputForm,
  Loader,
  NavBar,
  Spacing,
  TextInput,
} from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import {
  ColorEnum,
  Constants,
  DropdownItemModel,
  RouteModel,
  SpacingEnum,
  VehicleFilter,
  VehicleModel,
} from "../../types";
import { MultiValue, SingleValue } from "react-select";
import { RouteService, VehicleService } from "../../services";
import { LanguageUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";

const vehicleService = new VehicleService();
const routeService = new RouteService();

export default function RouteEditPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [formData, setFormData] = useState<RouteModel>(new RouteModel());
  const [formValidations, setFormValidations] = useState({
    vehicleId: true,
    origin: true,
    destination: true,
    driveStarting: true,
    driveEnding: true,
    price: true,
    detourRadius: true,
    packageSize: true,
    recurrenceFrequency: true,
    recurrentRouteName: true,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedVehicle, setSelectedVehicle] =
    useState<DropdownItemModel | null>(null);
  const [vehicleFilter, setVehicleFilter] = useState<VehicleFilter>({
    pageNumber: 1,
    pageSize: 15,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleValidationChange = useCallback(
    (name: string, isValid: boolean) => {
      setFormValidations((prevValidations) => ({
        ...prevValidations,
        [name]: isValid,
      }));
    },
    []
  );

  const checkIfFormValid = () => {
    const isFormValid = Object.entries(formValidations).every(
      ([key, isValid]) => {
        if (
          ["recurrenceFrequency", "routeName"].includes(key) &&
          !formData.isRecurrent
        ) {
          return true;
        }
        return isValid;
      }
    );
    return isFormValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = checkIfFormValid();

    if (!isFormValid) {
      return;
    }

    formData.userId = selectedVehicle?.data.userId;

    const result = await routeService.updateAsync(formData);

    if (result.isSuccess) {
      ToastUtil.showSuccessMessage(t("route_updated"));
      navigate(-1);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
    }
  };

  const getVehiclesAsync = useCallback(
    async (inputValue: string): Promise<DropdownItemModel[]> => {
      const newFilter = new VehicleFilter(vehicleFilter);
      newFilter.query = inputValue;

      if (vehicleFilter.query !== inputValue) {
        newFilter.pageNumber = 1;
      } else {
        newFilter.pageNumber = (newFilter.pageNumber || 0) + 1;
      }

      setVehicleFilter(newFilter);
      const response = await vehicleService.getPagedAsync(newFilter);

      if (response.isSuccess && response.result && response.result.items) {
        const options = response.result.items.map(
          (item) =>
            new DropdownItemModel(item.id!, VehicleModel.dropdownValue(item), {
              userId: item.userId,
            })
        );

        return options;
      }

      return [];
    },
    [params.id]
  );

  useEffect(() => {
    const getRouteAsync = async (): Promise<RouteModel | null> => {
      const response = await routeService.findByIdAsync(params.id ?? "");

      if (response.isSuccess) {
        setFormData(response.result!);
        return response.result!;
      }
      return null;
    };

    const fetchData = async () => {
      const route = await getRouteAsync();

      if (route) {
        const vehicleResponse = await vehicleService.findByIdAsync(
          route.vehicleId!
        );
        const vehicle = vehicleResponse.result;

        if (vehicle) {
          setSelectedVehicle(
            new DropdownItemModel(
              vehicle.id!,
              VehicleModel.dropdownValue(vehicle),
              { userId: vehicle.userId }
            )
          );
        }
      }

      setIsLoading(false);
    };

    fetchData();
  }, [params.id]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div
          className="container mt-3"
          style={{ maxWidth: Constants.maxWidth }}
        >
          <h2>{t("edit_route")}</h2>
          <InputForm onSubmit={handleSubmit}>
            <AsyncDropdownInput
              name="vehicleId"
              title={t("vehicle")}
              loadOptions={getVehiclesAsync}
              onChange={(result) => {
                setSelectedVehicle(result);
                setFormData({
                  ...formData,
                  vehicleId: result
                    ? ((result as SingleValue<DropdownItemModel>)!
                        .value as string)!
                    : undefined,
                });
              }}
              value={selectedVehicle}
              defaultErrorMessage={t("please_select_the_vehicle")}
              onValidationChange={handleValidationChange}
            />
            <AddressInput
              name="origin"
              placeholder={t("origin")}
              title={t("origin")}
              initialValue={formData.origin}
              onChange={(value: string) => {
                setFormData({ ...formData, origin: value });
              }}
              defaultErrorMessage={t("enter_the_origin")}
              onValidationChange={handleValidationChange}
            />
            <AddressInput
              name="destination"
              title={t("destination")}
              initialValue={formData.destination}
              onChange={(value: string) => {
                setFormData({ ...formData, destination: value });
              }}
              defaultErrorMessage={t("please_enter_the_destination")}
              onValidationChange={handleValidationChange}
            />
            <DateTimeInput
              name="driveStarting"
              title={t("drive_start")}
              value={formData.driveStarting}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_drive_start");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <DateTimeInput
              name="driveEnding"
              title={t("drive_end")}
              value={formData.driveEnding}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_drive_end");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="price"
              title={t("price")}
              type="number"
              step=".01"
              value={formData.price}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_price");
                } else if (+value < 0) {
                  return t("the_value_cant_be_negative");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <TextInput
              name="detourRadius"
              title={t("detour_radius")}
              type="number"
              value={formData.detourRadius}
              onChange={handleInputChange}
              onValidate={(value: string) => {
                if (!value) {
                  return t("please_enter_the_detour_radius");
                } else if (+value < 0) {
                  return t("the_value_cant_be_negative");
                }
                return undefined;
              }}
              onValidationChange={handleValidationChange}
            />
            <DropdownInput
              name="packageSize"
              title={t("package_size")}
              options={Constants.packageSizes.map(
                (size) => new DropdownItemModel(size, size)
              )}
              multiple={true}
              value={formData.allowedPackages?.map(
                (size) => new DropdownItemModel(size, size)
              )}
              onChange={(
                result:
                  | MultiValue<DropdownItemModel>
                  | SingleValue<DropdownItemModel>
              ) => {
                setFormData({
                  ...formData,
                  allowedPackages: result
                    ? (result as MultiValue<DropdownItemModel>).map(
                        ({ value }) => value as string
                      )
                    : [],
                });
              }}
              defaultErrorMessage={t("please_select_the_package_size")}
              onValidationChange={handleValidationChange}
            />
            <CheckBoxInput
              name="avoidTolls"
              title={t("avoid_tolls")}
              value={formData.avoidTolls}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value: boolean = event.target.checked;
                setFormData({
                  ...formData,
                  avoidTolls: value,
                });
              }}
            />
            {formData.isRecurrent === true && (
              <>
                <DropdownInput
                  title={t("recurrence_frequency")}
                  name="recurrenceFrequency"
                  value={
                    new DropdownItemModel(
                      formData.recurrenceFrequency!,
                      formData.recurrenceFrequency!.charAt(0).toUpperCase() +
                        formData.recurrenceFrequency!.slice(1)
                    )
                  }
                  options={Constants.reccurenceFrequencies.map(
                    (item) => new DropdownItemModel(item, t(item))
                  )}
                  onChange={(result) => {
                    setFormData({
                      ...formData,
                      recurrenceFrequency: result
                        ? ((result as SingleValue<DropdownItemModel>)!
                            .value as string)
                        : undefined,
                    });
                  }}
                />
                <TextInput
                  title={t("route_name")}
                  name="recurrentRouteName"
                  value={formData.recurrentRouteName}
                  onChange={handleInputChange}
                />
              </>
            )}
            <Spacing size={SpacingEnum.XXLarge} />
            <DefaultButton
              type="submit"
              color={ColorEnum.Orange}
              disabled={
                !Object.values(formValidations).every((isValid) => isValid)
              }
            >
              {t("save_changes")}
            </DefaultButton>
          </InputForm>
          <Spacing size={SpacingEnum.Giant} />
        </div>
      </Loader>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
