import { useTranslation } from "react-i18next";
import { DelivererModel } from "../../types";
import { TableComponent } from "../common";

export default function DelivererInfoTableComponent({
  model,
}: {
  model: DelivererModel;
}) {
  const { t } = useTranslation();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3"></th>
          <th scope="col" className="col-9"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("name")}</td>
          <td>{model.name}</td>
        </tr>
        <tr>
          <td>{t("rating")}</td>
          <td>{model.rating?.toFixed(2)}</td>
        </tr>
        <tr>
          <td>{t("number_of_reviews")}</td>
          <td>{model.numberOfReviews}</td>
        </tr>
        <tr>
          <td>{t("type")}</td>
          <td>{model.delivererType}</td>
        </tr>
      </tbody>
    </TableComponent>
  );
}
