import axios from "axios";
import { DOTNET_API_URL } from "../config";
import { HttpHeaders, ResponseModel } from "../types";

const URL_PREFIX = DOTNET_API_URL + "/shipment";

export class ShipmentService {
  async addAsync(model: {
    packageId: string;
    routeId: string;
  }): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.post(URL_PREFIX, model, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      return ResponseModel.success();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }
}
