import axios from "axios";
import { DOTNET_API_URL } from "../config";
import {
  AuthResponseModel,
  HttpHeaders,
  ChangePasswordModel,
  ResponseModel,
  TokenModel,
  UserRegisterModel,
  LoginModel,
} from "../types";
import { UserDataService } from "./UserDataService";
import { ResetPasswordModel } from "../types/models/ResetPasswordModel";

const URL_PREFIX = DOTNET_API_URL + "/auth";

export class AuthService {
  async loginAsync(model: LoginModel): Promise<ResponseModel<boolean>> {
    try {
      const data = `email=${encodeURIComponent(
        model.email || ""
      )}&password=${encodeURIComponent(
        model.password || ""
      )}&rememberMe=${encodeURIComponent(model.rememberMe || "")}`;

      const response = await axios.post(URL_PREFIX + `/login`, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.status === 200) {
        const result = AuthResponseModel.fromJson(response.data.result);

        UserDataService.setUserAuthData(result);

        return ResponseModel.success();
      }
      const result = ResponseModel.fromJson<boolean>(response.data);

      return result;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }

  async logoutAsync(): Promise<ResponseModel<boolean>> {
    const userToken = UserDataService.getUserToken();

    if (userToken == null) {
      return ResponseModel.failure();
    }

    try {
      const response = await axios.post(
        URL_PREFIX + `/logout`,
        {
          accessToken: userToken.token,
          refreshToken: userToken.refreshToken,
        },
        {
          headers: {
            ...HttpHeaders.get(),
          },
        }
      );

      if (response.status === 200) {
        UserDataService.removeUserData();
        return ResponseModel.success();
      }
    } catch {}
    return ResponseModel.failure();
  }

  async registerAsync(model: UserRegisterModel): Promise<ResponseModel<any>> {
    try {
      const response = await axios.post(URL_PREFIX + `/register`, model, {
        headers: HttpHeaders.get(),
      });

      const result = ResponseModel.fromJson(response.data);

      return result;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
      return ResponseModel.failure();
    }
  }

  async refreshTokenAsync(): Promise<ResponseModel<TokenModel>> {
    const userToken = UserDataService.getUserToken();

    if (userToken == null) {
      return ResponseModel.failure();
    }

    try {
      const response = await axios.post(
        URL_PREFIX + `/refresh-token`,
        {
          accessToken: userToken.token,
          refreshToken: userToken.refreshToken,
        },
        { headers: HttpHeaders.get() }
      );

      if (response.status === 200) {
        const result = AuthResponseModel.fromJson(response.data.result);

        UserDataService.setUserAuthData(result);

        return ResponseModel.success();
      }
    } catch {}
    return ResponseModel.failure();
  }

  async isAuthenticatedAsync(): Promise<boolean> {
    const userToken = UserDataService.getUserToken();

    if (userToken == null) {
      return false;
    }
    const now = new Date();
    const tokenExpiry = new Date(userToken.tokenExpiration);

    if (tokenExpiry < now) {
      if (!userToken.refreshToken) {
        return false;
      }

      const result = await this.refreshTokenAsync();

      if (!result.isSuccess) {
        return false;
      }
    }

    return true;
  }

  async changePasswordAsync(
    model: ChangePasswordModel
  ): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.post(
        URL_PREFIX + `/change-password`,
        model,
        {
          headers: HttpHeaders.get(),
        }
      );

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }

  async resetPasswordAsync(
    model: ResetPasswordModel
  ): Promise<ResponseModel<any>> {
    try {
      const response = await axios.post(URL_PREFIX + `/reset-password`, model, {
        headers: HttpHeaders.get(),
      });

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<any>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }

  async sendResetPasswordCodeAsync(email: string): Promise<ResponseModel<any>> {
    try {
      const response = await axios.post(
        URL_PREFIX + `/send-reset-password-code`,
        { email },
        {
          headers: HttpHeaders.get(),
        }
      );

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<any>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }

  async verifyResetPasswordCodeAsync(
    email: string,
    code: string
  ): Promise<ResponseModel<any>> {
    try {
      const response = await axios.post(
        URL_PREFIX + `/verify-reset-password-code`,
        { email, code },
        {
          headers: HttpHeaders.get(),
        }
      );

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<any>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }

  async confirmEmailAsync(token: string): Promise<ResponseModel<any>> {
    try {
      const response = await axios.post(
        URL_PREFIX + `/confirm-email/${token}`,
        {},
        {
          headers: HttpHeaders.get(),
        }
      );

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<any>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }
}
