import { useEffect, useState } from "react";
import {
  DelivererOfferFilterComponent,
  DelivererPackageOffersTableComponent,
  Loader,
  NavBar,
  NoItemsComponent,
  PackageOfferSidePanelComponent,
  PaginationComponent,
  Spacing,
} from "../../components";
import {
  ColorEnum,
  DelivererPackageOfferModel,
  PackageOfferFilter,
  PagedList,
  SpacingEnum,
} from "../../types";
import { PackageOfferService, UserDataService } from "../../services";
import { useTranslation } from "react-i18next";

const packageOfferService = new PackageOfferService();

export default function DelivererOffersPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [showPanel, setShowPanel] = useState(false);
  const [packageId, setPackageId] = useState<string>();
  const [packageOfferFilter, setPackageOfferFilter] =
    useState<PackageOfferFilter>({
      pageSize: 10,
      pageNumber: 1,
      companyId: UserDataService.getCompanyId() || "",
    });
  const [pagedPackageOffers, setPagedPackageOffers] = useState<
    PagedList<DelivererPackageOfferModel>
  >({});

  useEffect(() => {
    setIsLoading(true);

    const fetchPackageOffers = async () => {
      const response = await packageOfferService.getPagedForDelivererAsync(
        packageOfferFilter
      );

      if (response.isSuccess && response.result) {
        setPagedPackageOffers(response.result!);
      }

      setIsLoading(false);
    };

    fetchPackageOffers();
  }, [packageOfferFilter]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <h3 style={{ color: ColorEnum.Orange }}>{t("offers_page")}</h3>
          <Spacing size={SpacingEnum.Large} />
          <DelivererOfferFilterComponent
            filter={packageOfferFilter}
            onChange={(filter: PackageOfferFilter) => {
              setPackageOfferFilter(filter);
            }}
          />
          <DelivererPackageOffersTableComponent
            items={pagedPackageOffers?.items || []}
            onSelect={(packageOffer: DelivererPackageOfferModel) => {
              setPackageId(packageOffer.packageId);
              setShowPanel(true);
            }}
          />
          {(pagedPackageOffers?.items || []).length === 0 && (
            <NoItemsComponent />
          )}
          <PaginationComponent
            onPageChange={(page) => {
              setPackageOfferFilter({
                ...packageOfferFilter,
                pageNumber: page,
              });
            }}
            currentPage={pagedPackageOffers?.pageNumber || 1}
            pageCount={pagedPackageOffers?.lastPage || 1}
          />
        </div>
      </Loader>
      {showPanel && (
        <PackageOfferSidePanelComponent
          packageId={packageId || ""}
          showPanel={showPanel}
          onShowChange={setShowPanel}
        />
      )}
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
