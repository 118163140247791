import { useCallback, useState } from "react";
import { DefaultButton, InputForm, TextInput } from "../common";
import { ValidationUtil } from "../../utils";
import { ColorEnum, Constants } from "../../types";
import { useTranslation } from "react-i18next";

export default function SendResetPasswordComponent({
  sendCodeAsync,
}: {
  sendCodeAsync: (email: string) => Promise<void>;
}) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [formValidations, setFormValidations] = useState({
    email: false,
  });

  const handleValidationChange = useCallback(
    (name: string, isValid: boolean) => {
      setFormValidations((prevValidations) => ({
        ...prevValidations,
        [name]: isValid,
      }));
    },
    []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = Object.values(formValidations).every(
      (isValid) => isValid
    );

    if (!isFormValid) {
      return;
    }

    await sendCodeAsync(email);
  };

  return (
    <div className="container mt-3">
      <h2>{t("reset_password")}</h2>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ maxWidth: Constants.maxWidth }}
          >
            <div className="text-center">
              <img
                alt={t("reset_password")}
                src="/images/reset_password.png"
                className="mb-4 col-8"
              />
            </div>
            <InputForm onSubmit={handleSubmit}>
              <TextInput
                name="email"
                title={t("email")}
                type="email"
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                }}
                onValidate={(value: string) => {
                  if (!value) {
                    return t("please_enter_your_email_address");
                  } else if (!ValidationUtil.validateEmail(value)) {
                    return t("please_enter_a_valid_email");
                  }
                  return undefined;
                }}
                onValidationChange={handleValidationChange}
              />
              <DefaultButton
                type="submit"
                color={ColorEnum.Orange}
                disabled={
                  !Object.values(formValidations).every((isValid) => isValid)
                }
              >
                {t("send_code")}
              </DefaultButton>
            </InputForm>
          </div>
        </div>
      </div>
    </div>
  );
}
