import { useCallback, useState } from "react";
import {
  DefaultButton,
  DropdownInput,
  InputForm,
  NavBar,
  Spacing,
  TextInput,
} from "../../components";
import { useNavigate } from "react-router-dom";
import {
  ColorEnum,
  CompanyModel,
  Constants,
  DropdownItemModel,
  PageRouteEnum,
  SpacingEnum,
} from "../../types";
import { CompanyService } from "../../services";
import { LanguageUtil, ToastUtil, ValidationUtil } from "../../utils";
import { MultiValue, SingleValue } from "react-select";
import { useTranslation } from "react-i18next";

const companyService = new CompanyService();

export default function CompanyCreatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<CompanyModel>(new CompanyModel());
  const [formValidations, setFormValidations] = useState({
    type: false,
    name: false,
    email: false,
    address: false,
    city: false,
    postalCode: false,
    personalIdentificationNumber: false,
    phoneNumber: false,
    adminName: false,
    adminEmail: false,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(
      new CompanyModel({ ...formData, [event.target.name]: event.target.value })
    );
  };

  const handleValidationChange = useCallback(
    (name: string, isValid: boolean) => {
      setFormValidations((prevValidations) => ({
        ...prevValidations,
        [name]: isValid,
      }));
    },
    []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = Object.values(formValidations).every(
      (isValid) => isValid
    );

    if (!isFormValid) {
      return;
    }

    const result = await companyService.createAsync(formData);

    if (result.isSuccess) {
      ToastUtil.showSuccessMessage(t("company_created"));
      navigate(PageRouteEnum.Home);
    } else {
      ToastUtil.showErrorMessage(
        LanguageUtil.translateErrorMessage(t, result.errorMessage)
      );
    }
  };

  return (
    <div>
      <NavBar />
      <div className="container mt-3" style={{ maxWidth: Constants.maxWidth }}>
        <h2>{t("new_company")}</h2>
        <InputForm onSubmit={handleSubmit}>
          <DropdownInput
            name="type"
            title={t("type")}
            options={Constants.companyTypes.map(
              (type) => new DropdownItemModel(type.value, t(type.label))
            )}
            onChange={(
              result:
                | MultiValue<DropdownItemModel>
                | SingleValue<DropdownItemModel>
            ) => {
              setFormData({
                ...formData,
                type: result
                  ? ((result as SingleValue<DropdownItemModel>)
                      ?.value as number)
                  : undefined,
              });
            }}
            defaultErrorMessage={t("please_select_the_company_type")}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="name"
            title={t("name")}
            value={formData.name}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_company_name");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="email"
            title={t("email")}
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_company_email");
              } else if (!ValidationUtil.validateEmail(value)) {
                return t("please_enter_a_valid_email");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="address"
            title={t("address")}
            value={formData.address}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_company_address");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="city"
            title={t("city")}
            value={formData.city}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_company_city");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="postalCode"
            title={t("postal_code")}
            value={formData.postalCode}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_company_postal_code");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="personalIdentificationNumber"
            title={t("pid")}
            value={formData.personalIdentificationNumber}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_company_pid");
              } else if (value.length !== 11) {
                return t("pid_11_characters_long");
              } else if (!ValidationUtil.onlyNumbers(value)) {
                return t("pid_only_numbers");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="phoneNumber"
            title={t("phone_number")}
            value={formData.phoneNumber}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_company_contact_number");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="adminName"
            title={t("admin_name")}
            value={formData.adminName}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_admin_name");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <TextInput
            name="adminEmail"
            title={t("admin_email")}
            type="email"
            value={formData.adminEmail}
            onChange={handleInputChange}
            onValidate={(value: string) => {
              if (!value) {
                return t("please_enter_the_admin_email");
              }
              return undefined;
            }}
            onValidationChange={handleValidationChange}
          />
          <Spacing size={SpacingEnum.XXLarge} />
          <DefaultButton
            type="submit"
            color={ColorEnum.Orange}
            disabled={
              !Object.values(formValidations).every((isValid) => isValid)
            }
          >
            {t("create")}
          </DefaultButton>
        </InputForm>
      </div>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
