import React from "react";
import { useTranslation } from "react-i18next";
import { BootstrapIconsEnum, ColorEnum } from "../../types";

export default function StatisticsCardComponent({
  title,
  value,
  icon,
}: {
  title: string;
  value: any;
  icon: BootstrapIconsEnum;
}) {
  return (
    <div className="col-12 col-sm-6">
      <div className="card widget-card border-light shadow-sm">
        <div className="card-body p-4">
          <div className="row">
            <div className="col-8">
              <h5 className="card-title widget-card-title mb-3">{title}</h5>
              <h4 className="card-subtitle text-body-secondary m-0">{value}</h4>
            </div>
            <div className="col-4">
              <div className="d-flex justify-content-end">
                <div
                  className="lh-1 text-white rounded-circle p-3 d-flex align-items-center justify-content-center"
                  style={{ backgroundColor: ColorEnum.Orange }}
                >
                  <i className={icon + " fs-4"}></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
