import { useCallback, useState } from "react";
import { ColorEnum, Constants } from "../../types";
import { DefaultButton, InputForm, TextInput } from "../common";
import { useTranslation } from "react-i18next";

export default function ResetPasswordComponent({
  resetPasswordAsync,
}: {
  resetPasswordAsync: (
    password: string,
    confirmPassword: string
  ) => Promise<void>;
}) {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formValidations, setFormValidations] = useState({
    password: false,
    confirmPassword: false,
  });

  const handleValidationChange = useCallback(
    (name: string, isValid: boolean) => {
      setFormValidations((prevValidations) => ({
        ...prevValidations,
        [name]: isValid,
      }));
    },
    []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid = Object.values(formValidations).every(
      (isValid) => isValid
    );

    if (!isFormValid) {
      return;
    }

    await resetPasswordAsync(password, confirmPassword);
  };

  return (
    <div className="container mt-3">
      <h2>{t("reset_password")}</h2>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ maxWidth: Constants.maxWidth }}
          >
            <div className="text-center">
              <img
                alt={t("reset_password")}
                src="/images/reset_password.png"
                className="mb-4 col-8"
              />
            </div>
            <InputForm onSubmit={handleSubmit}>
              <TextInput
                name="password"
                title={t("password")}
                type="password"
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value);
                }}
                onValidate={(value: string) => {
                  if (!value) {
                    return t("please_enter_your_new_password");
                  }
                  return undefined;
                }}
                onValidationChange={handleValidationChange}
              />
              <TextInput
                name="confirmPassword"
                title={t("confirm_password")}
                type="password"
                value={confirmPassword}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setConfirmPassword(event.target.value);
                }}
                onValidate={(value: string) => {
                  if (!value) {
                    return t("please_confirm_your_password");
                  }
                  return undefined;
                }}
                onValidationChange={handleValidationChange}
              />
              <DefaultButton
                type="submit"
                color={ColorEnum.Orange}
                disabled={
                  !Object.values(formValidations).every((isValid) => isValid)
                }
              >
                {t("reset_password")}
              </DefaultButton>
            </InputForm>
          </div>
        </div>
      </div>
    </div>
  );
}
