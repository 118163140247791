import { MultiValue, SingleValue } from "react-select";
import {
  ColorEnum,
  DropdownItemModel,
  PackageFilter,
  PackageStatusEnum,
  RoleEnum,
} from "../../types";
import {
  AddressInput,
  DefaultButton,
  DropdownInput,
  InputForm,
} from "../common";
import { UserDataService } from "../../services";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function PackagesFilterComponent({
  filter,
  onChange,
}: {
  filter: PackageFilter;
  onChange: (filter: PackageFilter) => void;
}) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<PackageFilter>(filter);
  const isStatusVisible =
    UserDataService.isInRole(RoleEnum.BusinessSender) ||
    UserDataService.isInRole(RoleEnum.SenderBusinessAdmin) ||
    UserDataService.isInRole(RoleEnum.SuperAdmin);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (onChange) {
      onChange(formData);
    }
  };

  const statusOptions = Object.values(PackageStatusEnum)
    .filter((value) => typeof value === "number")
    .map(
      (value) =>
        new DropdownItemModel(
          value as number,
          t(PackageStatusEnum.getStatusText(value as PackageStatusEnum))
        )
    );

  return (
    <InputForm onSubmit={handleSubmit}>
      <div className="d-flex flex-wrap gap-3">
        {isStatusVisible && (
          <DropdownInput
            name="status"
            title={t("status")}
            width={170}
            value={
              filter.status &&
              filter.status.map(
                (s: PackageStatusEnum) =>
                  new DropdownItemModel(
                    s as number,
                    t(PackageStatusEnum.getStatusText(s))
                  )
              )
            }
            options={statusOptions}
            multiple={true}
            onChange={(
              result:
                | MultiValue<DropdownItemModel>
                | SingleValue<DropdownItemModel>
            ) => {
              const value = result
                ? (result as MultiValue<DropdownItemModel>).map(
                    ({ value }) => value as PackageStatusEnum
                  )
                : [];
              setFormData({ ...formData, status: value });
            }}
          />
        )}
        <AddressInput
          name="origin"
          title={t("origin")}
          initialValue={formData.origin}
          width={170}
          onChange={(value: string) => {
            setFormData({ ...formData, origin: value });
          }}
        />
        <AddressInput
          name="destination"
          title={t("destination")}
          initialValue={formData.destination}
          width={170}
          onChange={(value: string) => {
            setFormData({ ...formData, destination: value });
          }}
        />
        <div
          className="d-flex btn-group gap-3 align-items-end"
          style={{ marginBottom: "18px" }}
        >
          <DefaultButton type="submit" width={80} color={ColorEnum.Blue}>
            {t("filter")}
          </DefaultButton>
          {(filter.origin ||
            filter.destination ||
            (isStatusVisible && filter.status)) && (
            <DefaultButton
              type="button"
              width={120}
              color={ColorEnum.Grey}
              onClick={() => {
                if (onChange) {
                  onChange({
                    ...formData,
                    origin: undefined,
                    destination: undefined,
                    status: isStatusVisible ? undefined : filter.status,
                  });
                }
              }}
            >
              {t("clear_filter")}
            </DefaultButton>
          )}
        </div>
      </div>
    </InputForm>
  );
}
