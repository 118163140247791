import { CompanyTypeEnum } from "../enums";
import { DropdownItemModel } from "./DropdownItemModel";

export class Constants {
  static maxWidth: number = 600;
  static packageSizes: string[] = ["S", "M", "L", "X", "H"];
  static companyTypes: DropdownItemModel[] = [
    new DropdownItemModel(CompanyTypeEnum.Deliverer, "deliverer"),
    new DropdownItemModel(CompanyTypeEnum.Sender, "sender"),
  ];
  static reccurenceFrequencies: string[] = [
    "daily",
    "weekly",
    "monthly",
    "workdays",
  ];
}
