import {
  ColorEnum,
  CompanyInviteCodeModel,
  Constants,
  InvitePageStatusEnum,
  SpacingEnum,
} from "../../types";
import { DefaultButton, Spacing, TableComponent } from "../common";
import { DateTimeUtil, ToastUtil } from "../../utils";
import { useTranslation } from "react-i18next";

export default function EmployeesInviteCodeGeneratedComponent({
  model,
  onStatusChange,
}: {
  model: CompanyInviteCodeModel;
  onStatusChange: (status: InvitePageStatusEnum) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="container mt-3">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6">
          <div
            className="d-flex flex-column justify-content-center text-center"
            style={{ maxWidth: Constants.maxWidth }}
          >
            <div className="text-center">
              <img
                alt="Invite"
                src="/images/invite_sent.png"
                className="mb-4 col-8"
              />
            </div>
            <Spacing size={SpacingEnum.Large} />
            <h4>{t("invite_code_successfully_generated")}</h4>
            <Spacing size={SpacingEnum.Medium} />
            <TableComponent>
              <thead>
                <tr>
                  <th scope="col" className="col-3"></th>
                  <th scope="col" className="col-9"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t("code")}:</td>
                  <td
                    onClick={() => {
                      if (model.code) {
                        navigator.clipboard.writeText(model.code);
                        ToastUtil.showInfoMessage(t("copied_to_clipboard"));
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {model.code}
                    <span
                      className="bi bi-clipboard"
                      style={{ marginLeft: "10px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{t("invite_url")}:</td>
                  <td
                    onClick={() => {
                      if (model.inviteUrl) {
                        navigator.clipboard.writeText(model.inviteUrl);
                        ToastUtil.showInfoMessage(t("copied_to_clipboard"));
                      }
                    }}
                    style={{ cursor: model.inviteUrl ? "pointer" : undefined }}
                  >
                    {model.inviteUrl}
                    {model.inviteUrl && (
                      <span
                        className="bi bi-clipboard"
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{t("expiration")}:</td>
                  <td>{DateTimeUtil.dateTimeFormat(model.expirationTime)}</td>
                </tr>
              </tbody>
            </TableComponent>
            <Spacing size={SpacingEnum.Giant} />
            <DefaultButton
              type="button"
              onClick={() => onStatusChange(InvitePageStatusEnum.CreateInvite)}
              color={ColorEnum.Orange}
            >
              {t("send_more_invites")}
            </DefaultButton>
          </div>
        </div>
      </div>
    </div>
  );
}
