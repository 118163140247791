import { Link, useLocation } from "react-router-dom";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export default function DropdownNavigationLink({
  children,
  title,
  path,
}: {
  children: ReactNode;
  title: string;
  path: string;
}) {
  const { t } = useTranslation();
  const location = useLocation();

  const isActive = () => {
    return location.pathname === path || location.pathname === path + "/";
  };

  const linkClass = isActive() ? "nav-link active" : "nav-link";

  return (
    <li className="nav-item dropdown d-flex align-items-center">
      <Link to={path} className={linkClass}>
        {title}
      </Link>
      <a
        className="nav-link dropdown-toggle"
        href={path}
        id="navbarDropdownMenuLink"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ paddingLeft: 0, marginLeft: 0 }}
      >
        <span className="visually-hidden">{t("toggle_dropdown")}</span>
      </a>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
        {children}
      </ul>
    </li>
  );
}
