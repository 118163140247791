import { PackageStatusEnum } from "../enums";
import { BaseFilter } from "./BaseFilter";

export class PackageOfferFilter extends BaseFilter {
  packageId?: string;
  driverId?: string;
  companyId?: string;
  packageCompanyId?: string;
  packageUserId?: string;
  minimumPrice?: number;
  maximumPrice?: number;
  minimumRating?: number;
  minimumNumberOfReviews?: number;
  deliveryDate?: Date;
  origin?: string;
  destination?: string;
  packageStatus?: PackageStatusEnum[];

  constructor({
    packageId,
    driverId,
    companyId,
    packageCompanyId,
    packageUserId,
    minimumPrice,
    maximumPrice,
    minimumRating,
    minimumNumberOfReviews,
    deliveryDate,
    origin,
    destination,
    packageStatus,
    ...other
  }: PackageOfferFilter = {}) {
    super(other);
    this.packageId = packageId;
    this.driverId = driverId;
    this.companyId = companyId;
    this.packageCompanyId = packageCompanyId;
    this.packageUserId = packageUserId;
    this.minimumPrice = minimumPrice;
    this.maximumPrice = maximumPrice;
    this.minimumRating = minimumRating;
    this.minimumNumberOfReviews = minimumNumberOfReviews;
    this.deliveryDate = deliveryDate;
    this.origin = origin;
    this.destination = destination;
    this.packageStatus = packageStatus;
  }
}
