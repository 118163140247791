import { DefaultButton, TableComponent } from "../common";
import { ColorEnum, CompanyModel, PageRouteEnum } from "../../types";
import { useNavigate } from "react-router-dom";
import { UserDataService } from "../../services";
import { useTranslation } from "react-i18next";

export default function CompaniesTableComponent({
  items,
}: {
  items: CompanyModel[];
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3">
            {t("name")}
          </th>
          <th scope="col" className="col-3">
            {t("address")}
          </th>
          <th scope="col" className="col-2">
            {t("phone_number")}
          </th>
          <th scope="col" className="col-2">
            {t("type")}
          </th>
          <th scope="col" className="col-2">
            {t("actions")}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.id}>
            <td>{item.name}</td>
            <td>{CompanyModel.getFullAddress(item)}</td>
            <td>{item.phoneNumber}</td>
            <td>{item.type === 1 ? t("deliverer") : t("sender")}</td>
            <td>
              <div className="btn-group gap-1">
                <DefaultButton
                  width={80}
                  color={ColorEnum.Grey}
                  onClick={() => {
                    navigate(
                      PageRouteEnum.CompanyEdit.replace(":id", item.id || "")
                    );
                  }}
                >
                  {t("edit")}
                </DefaultButton>
                <DefaultButton
                  width={80}
                  onClick={() => {
                    navigate(
                      PageRouteEnum.CompanyDetails.replace(":id", item.id || "")
                    );
                  }}
                >
                  {t("details")}
                </DefaultButton>
                <DefaultButton
                  width={80}
                  onClick={() => {
                    UserDataService.setCompanyInfo({
                      id: item.id!,
                      name: item.name!,
                    });
                    navigate(PageRouteEnum.Home);
                  }}
                >
                  {t("select")}
                </DefaultButton>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
}
