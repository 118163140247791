import { useEffect, useState } from "react";
import {
  Loader,
  NavBar,
  Spacing,
  DeliveryPackagesTableComponent,
  PaginationComponent,
  PackageOfferSidePanelComponent,
  PackagesFilterComponent,
  NoItemsComponent,
} from "../../components";
import {
  PackageFilter,
  PackageModel,
  PackageStatusEnum,
  PagedList,
  SpacingEnum,
} from "../../types";
import { PackageService } from "../../services";
import { useTranslation } from "react-i18next";

const packageService = new PackageService();

export default function DeliveryPackagesPage() {
  const { t } = useTranslation();
  const [packageId, setPackageId] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [showPanel, setShowPanel] = useState(false);
  const [pagedPackages, setPagedPackages] = useState<PagedList<PackageModel>>();
  const [packageFilter, setPackageFilter] = useState<PackageFilter>({
    pageNumber: 1,
    pageSize: 10,
    status: [PackageStatusEnum.New, PackageStatusEnum.SelectingDeliverer],
  });

  useEffect(() => {
    setIsLoading(true);
    const getPackagesAsync = async () => {
      const response = await packageService.getPagedAsync(packageFilter);

      if (response.isSuccess) {
        setPagedPackages(response.result!);
      }
    };

    const fetchData = async () => {
      const promises = [getPackagesAsync()];

      await Promise.all(promises);

      setIsLoading(false);
    };

    fetchData();
  }, [packageFilter]);

  return (
    <div>
      <NavBar />
      <Loader isLoading={isLoading}>
        <div className="container mt-3">
          <h2>{t("packages")}</h2>
          <PackagesFilterComponent
            filter={packageFilter}
            onChange={(filter) => {
              setPackageFilter(filter);
            }}
          />
          <DeliveryPackagesTableComponent
            onItemClick={(item) => {
              setPackageId(item.id);
              setShowPanel(true);
            }}
            items={pagedPackages?.items || []}
          />
          {(pagedPackages?.items || []).length === 0 && <NoItemsComponent />}
          <PaginationComponent
            onPageChange={(page) => {
              setPackageFilter({ ...packageFilter, pageNumber: page });
            }}
            currentPage={pagedPackages?.pageNumber || 1}
            pageCount={pagedPackages?.lastPage || 1}
          />
        </div>
      </Loader>
      {showPanel && (
        <PackageOfferSidePanelComponent
          packageId={packageId || ""}
          showPanel={showPanel}
          onShowChange={setShowPanel}
        />
      )}
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
