import { AuthResponseModel, CompanyInfoModel, RoleEnum } from "../types";
import { LanguageUtil } from "../utils";

const userTokenKey = "gepek_user_token";
const companyInfoKey = "gepek_company";
const languageKey = "gepek_language";

export class UserDataService {
  static setUserAuthData(model: AuthResponseModel): void {
    this.setLanguage(model.language);
    localStorage.setItem(userTokenKey, JSON.stringify(model));
  }

  static removeUserData(): void {
    localStorage.removeItem(userTokenKey);
    localStorage.removeItem(companyInfoKey);
  }

  static getUserToken(): AuthResponseModel | null {
    const jsonData = localStorage.getItem(userTokenKey);

    if (jsonData) {
      const data = JSON.parse(jsonData) as AuthResponseModel;
      return data;
    }

    return null;
  }

  static isInRole(role: RoleEnum): boolean {
    const userToken = this.getUserToken();

    return (
      userToken != null &&
      userToken.roles != null &&
      userToken.roles.includes(role)
    );
  }

  static getUserId(): string | null {
    const userToken = this.getUserToken();

    if (userToken == null || userToken.userId == null) {
      return null;
    }

    return userToken!.userId;
  }

  static setCompanyInfo(model: CompanyInfoModel): void {
    localStorage.setItem(companyInfoKey, JSON.stringify(model));
  }

  static getCompanyInfo(): CompanyInfoModel | null {
    const jsonData = localStorage.getItem(companyInfoKey);

    if (!jsonData) return null;

    const data = JSON.parse(jsonData) as CompanyInfoModel;

    return data;
  }

  static removeCompanyInfo(): void {
    localStorage.removeItem(companyInfoKey);
  }

  static getCompanyId(): string | null {
    const userToken = this.getUserToken();

    if (this.isInRole(RoleEnum.SuperAdmin)) {
      const companyInfo = this.getCompanyInfo();

      if (!companyInfo) {
        return null;
      }
      return companyInfo.id;
    }

    if (userToken == null || userToken.companyId == null) {
      return null;
    }

    return userToken!.companyId!;
  }

  static setLanguage(language: string): void {
    const lang = language || "en";
    localStorage.setItem(languageKey, lang);
    LanguageUtil.changeLanguage(lang);
  }

  static getLanguage(): string {
    return localStorage.getItem(languageKey) || "en";
  }
}
