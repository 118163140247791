export class SenderStatisticsModel {
  averagePrice?: number;
  createdPackagesCount?: number;
  deliveredPackagesCount?: number;
  packagesInDeliveryCount?: number;
  employeesCount?: number;

  constructor({
    averagePrice,
    createdPackagesCount,
    deliveredPackagesCount,
    packagesInDeliveryCount,
    employeesCount,
  }: SenderStatisticsModel) {
    this.averagePrice = averagePrice;
    this.createdPackagesCount = createdPackagesCount;
    this.deliveredPackagesCount = deliveredPackagesCount;
    this.packagesInDeliveryCount = packagesInDeliveryCount;
    this.employeesCount = employeesCount;
  }

  static fromJson(data: any): SenderStatisticsModel {
    return new SenderStatisticsModel({
      averagePrice: data["averagePrice"],
      createdPackagesCount: data["createdPackagesCount"],
      deliveredPackagesCount: data["deliveredPackagesCount"],
      packagesInDeliveryCount: data["packagesInDeliveryCount"],
      employeesCount: data["employeesCount"],
    });
  }
}
