import React from "react";
import { ColorEnum, MatchmakingModel, PageRouteEnum } from "../../types";
import { DefaultButton, TableComponent } from "../common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DateTimeUtil } from "../../utils";

export default function RequestsTableComponent({
  items,
  onAccept,
}: {
  items: MatchmakingModel[];
  onAccept: (model: MatchmakingModel) => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TableComponent>
      <thead>
        <tr>
          <th scope="col" className="col-3">
            {t("origin")}
          </th>
          <th scope="col" className="col-3">
            {t("destination")}
          </th>
          <th scope="col" className="col-1">
            {t("size")}
          </th>
          <th scope="col" className="col-1">
            {t("weight")}
          </th>
          <th scope="col" className="col-1">
            {t("budget")}
          </th>
          <th scope="col" className="col-1">
            {t("sender")}
          </th>
          <th scope="col" className="col-1">
            {t("drive_starting")}
          </th>
          <th scope="col" className="col-3">
            {t("actions")}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.matchmakingId}>
            <td>{item.packageInfo?.origin}</td>
            <td>{item.packageInfo?.destination}</td>
            <td>{item.packageInfo?.size}</td>
            <td>{(item.packageInfo?.weight || 0).toFixed(2)}kg</td>
            <td>{(item.packageInfo?.targetPrice || 0).toFixed(2)}€</td>
            <td>{item.packageInfo?.owner?.name}</td>
            <td>{DateTimeUtil.dateTimeFormat(item.driveStarting)}</td>
            <td>
              <div className="d-flex gap-1">
                <DefaultButton
                  width={120}
                  onClick={() => {
                    if (onAccept) {
                      onAccept(item);
                    }
                  }}
                  color={ColorEnum.Green}
                >
                  {t("accept")}
                </DefaultButton>
                <DefaultButton
                  width={80}
                  onClick={() => {
                    navigate(
                      PageRouteEnum.PackageDetails.replace(
                        ":id",
                        item.packageInfo?.id || ""
                      )
                    );
                  }}
                >
                  {t("details")}
                </DefaultButton>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
}
