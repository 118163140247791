import axios from "axios";
import {
  CompanyFilter,
  CompanyInviteCodeModel,
  HttpHeaders,
  PagedList,
  ResponseModel,
} from "../types";
import { CompanyModel } from "../types/models/CompanyModel";
import { DOTNET_API_URL } from "../config";

const URL_PREFIX = DOTNET_API_URL + "/company";

export class CompanyService {
  async getPagedAsync(
    filter: CompanyFilter
  ): Promise<ResponseModel<PagedList<CompanyModel>>> {
    try {
      const params = new URLSearchParams();

      Object.entries(filter).forEach(([key, value]) => {
        if (value) {
          params.append(key, value.toString());
        }
      });

      const response = await axios.get(URL_PREFIX + `?${params}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PagedList.fromJson<CompanyModel>(
        response.data,
        CompanyModel
      );

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async findByIdAsync(id: string): Promise<ResponseModel<CompanyModel>> {
    try {
      const response = await axios.get(URL_PREFIX + `/${id}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = CompanyModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async createAsync(model: CompanyModel): Promise<ResponseModel<CompanyModel>> {
    try {
      const response = await axios.post(URL_PREFIX, model, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = CompanyModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async updateAsync(model: CompanyModel): Promise<ResponseModel<CompanyModel>> {
    try {
      const response = await axios.put(URL_PREFIX + `/${model.id}`, model, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = CompanyModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async deleteAsync(id: string): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.delete(URL_PREFIX + `/${id}`, {
        headers: HttpHeaders.get(),
      });

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch {
      return ResponseModel.failure();
    }
  }

  async generateInviteCodeAsync(
    companyId: string
  ): Promise<ResponseModel<CompanyInviteCodeModel>> {
    try {
      const response = await axios.post(
        URL_PREFIX + "/invite-code",
        { companyId },
        { headers: HttpHeaders.get() }
      );

      if (response.status !== 200) return ResponseModel.failure();

      const result = CompanyInviteCodeModel.fromJson(response.data["result"]);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async inviteEmployeesByEmailAsync(
    companyId: string,
    emails: string[]
  ): Promise<ResponseModel<any>> {
    try {
      const response = await axios.post(
        URL_PREFIX + "/invite-email",
        {
          companyId,
          emails,
        },
        { headers: HttpHeaders.get() }
      );

      return response.status === 200
        ? ResponseModel.success()
        : ResponseModel.failure();
    } catch {
      return ResponseModel.failure();
    }
  }
}
