import axios from "axios";
import { DOTNET_API_URL } from "../config";
import {
  DelivererStatisticsModel,
  HttpHeaders,
  ResponseModel,
  SenderStatisticsModel,
  StatisticsModel,
} from "../types";

const URL_PREFIX = DOTNET_API_URL + "/statistics";

export class StatisticsService {
  async getAdminStatisticsAsync(): Promise<ResponseModel<StatisticsModel>> {
    try {
      const response = await axios.get(URL_PREFIX + `/admin`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = StatisticsModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async getSenderStatisticsAsync(): Promise<
    ResponseModel<SenderStatisticsModel>
  > {
    try {
      const response = await axios.get(URL_PREFIX + `/sender`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = SenderStatisticsModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async getDelivererStatisticsAsync(): Promise<
    ResponseModel<DelivererStatisticsModel>
  > {
    try {
      const response = await axios.get(URL_PREFIX + `/deliverer`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = DelivererStatisticsModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }
}
