export class ValidationUtil {
  static validateEmail(value: string): boolean {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value);
  }

  static validatePassword(value: string): boolean {
    return value.length >= 8;
  }

  static onlyNumbers(value: string): boolean {
    for (let i = 0; i < value.length; i++) {
      if (value[i] < "0" || value[i] > "9") {
        return false;
      }
    }
    return true;
  }
}
