import axios from "axios";
import {
  HttpHeaders,
  PackageFilter,
  PackageMapModel,
  PackageModel,
  PackageShortInfoModel,
  PackageWithOfferModel,
  PagedList,
  ResponseModel,
  SenderHomePackageModel,
} from "../types";
import { DOTNET_API_URL } from "../config";

const URL_PREFIX = DOTNET_API_URL + "/package";

export class PackageService {
  async getPagedAsync(
    filter: PackageFilter
  ): Promise<ResponseModel<PagedList<PackageModel>>> {
    try {
      const params = new URLSearchParams();

      Object.entries(filter).forEach(([key, value]) => {
        if (value) {
          params.append(key, value.toString());
        }
      });

      const response = await axios.get(URL_PREFIX + `?${params}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PagedList.fromJson<PackageModel>(
        response.data,
        PackageModel
      );

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async getShortInfoAsync(
    filter: PackageFilter
  ): Promise<ResponseModel<PagedList<PackageShortInfoModel>>> {
    try {
      const params = new URLSearchParams();

      Object.entries(filter).forEach(([key, value]) => {
        if (value) {
          params.append(key, value.toString());
        }
      });

      const response = await axios.get(URL_PREFIX + `/short-info?${params}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PagedList.fromJson<PackageShortInfoModel>(
        response.data,
        PackageShortInfoModel
      );

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async getForMapAsync(): Promise<ResponseModel<PackageMapModel[]>> {
    try {
      const response = await axios.get(URL_PREFIX + `/map`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = response.data.map((item: any) =>
        PackageMapModel.fromJson(item)
      );

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async getPagedForSenderHomeAsync(
    filter: PackageFilter
  ): Promise<ResponseModel<PagedList<SenderHomePackageModel>>> {
    try {
      const params = new URLSearchParams();

      Object.entries(filter).forEach(([key, value]) => {
        if (value) {
          params.append(key, value.toString());
        }
      });

      const response = await axios.get(URL_PREFIX + `/sender-home?${params}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PagedList.fromJson<SenderHomePackageModel>(
        response.data,
        SenderHomePackageModel
      );

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async findByIdAsync(id: string): Promise<ResponseModel<PackageModel>> {
    try {
      const response = await axios.get(URL_PREFIX + `/${id}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PackageModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async getByIdWithOffersAsync(
    id: string
  ): Promise<ResponseModel<PackageModel>> {
    try {
      const response = await axios.get(URL_PREFIX + `/${id}/offers`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      const result = PackageModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async getPackageWithOfferAsync({
    packageId,
    companyId,
  }: {
    packageId: string;
    companyId: string;
  }): Promise<ResponseModel<PackageWithOfferModel>> {
    try {
      const response = await axios.get(
        URL_PREFIX + `/offer?packageId=${packageId}&companyId=${companyId}`,
        {
          headers: HttpHeaders.get(),
        }
      );

      if (response.status !== 200) return ResponseModel.failure();

      const result = PackageWithOfferModel.fromJson(response.data);

      return ResponseModel.success({ result });
    } catch {
      return ResponseModel.failure();
    }
  }

  async createAsync(model: PackageModel): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.post(URL_PREFIX, model, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      return ResponseModel.success();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }

  async updateAsync(model: PackageModel): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.put(URL_PREFIX + `/${model.id}`, model, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      return ResponseModel.success();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }

  async deleteAsync(id: string): Promise<ResponseModel<boolean>> {
    try {
      const response = await axios.delete(URL_PREFIX + `/${id}`, {
        headers: HttpHeaders.get(),
      });

      if (response.status !== 200) return ResponseModel.failure();

      return ResponseModel.success();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const result = ResponseModel.fromJson<boolean>(e.response?.data);
        return result;
      }
    }
    return ResponseModel.failure();
  }
}
