import { useEffect, useState } from "react";
import Spacing from "./Spacing";
import { SpacingEnum } from "../../types";
import { useTranslation } from "react-i18next";

export default function TextInput({
  title = undefined,
  type = "text",
  name,
  value,
  onChange,
  step,
  width,
  unit,
  minValue,
  maxValue,
  onValidate,
  onValidationChange,
}: {
  title: string;
  type: "password" | "test" | "email" | "number";
  name?: string;
  value: string;
  step?: string;
  unit?: string;
  minValue?: number;
  maxValue?: number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onValidate?: (value: string) => string;
  onValidationChange?: (name: string, isValid: boolean) => void;
  width: number;
} & any) {
  const { t } = useTranslation();
  const [isTouched, setIsTouched] = useState(false);
  const [inputValue, setInputValue] = useState(value || "");
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!onValidate) return;
    const error = onValidate(inputValue) || undefined;
    const isInputValid = !error;

    setIsValid(isInputValid);
    setErrorMessage(error || t("field_required"));
    if (onValidationChange) {
      onValidationChange(name, isInputValid);
    }
  }, [inputValue, t]);

  return (
    <div className="form-group mb-3 mt-3" style={{ maxWidth: width }}>
      {title && (
        <>
          <label htmlFor={name} className="form-label">
            {title}
          </label>
          <Spacing size={SpacingEnum.Small} />
        </>
      )}
      <div className="input-group">
        <input
          className={`form-control shadow-sm ${unit ? "rounded-right-0" : ""} ${
            isTouched && !isValid ? "is-invalid" : ""
          }`}
          type={type}
          id={name}
          name={name}
          value={value || ""}
          onFocus={() => {
            if (!isTouched) {
              setIsTouched(true);
            }
          }}
          onChange={(event) => {
            if (!isTouched) {
              setIsTouched(true);
            }
            setInputValue(event.target.value);
            onChange(event);
          }}
          step={step}
          min={minValue}
          max={maxValue}
        />
        {unit && (
          <div
            className="input-group-append rounded-left-0 border-left-0 d-flex align-items-center justify-content-center"
            style={{ width: "45px" }}
          >
            <span
              className="input-group-text d-flex align-items-center justify-content-center"
              style={{
                width: "100%",
              }}
            >
              {unit}
            </span>
          </div>
        )}
        {isTouched && <div className="invalid-feedback">{errorMessage}</div>}
      </div>
    </div>
  );
}
