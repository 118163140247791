import { MapComponent, NavBar, Spacing } from "../../components";
import { SpacingEnum } from "../../types";

export default function AdminHomePage() {
  return (
    <div>
      <NavBar />
      <div className="container mt-3">
        <MapComponent markers={[]} onClick={() => {}} />
      </div>
      <Spacing size={SpacingEnum.Large} />
    </div>
  );
}
